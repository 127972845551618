import api from '@/api/storeCollectionDetail'

const {
  getDealerList,
  getDealerAccount,
  editDealerFavorite,
  getSortGroup,
} = api;

export default {
  namespaced: true,

  state: {
    dealerData: null,
    accountData: null,
    editDealerRes: null,
    groupList:[],
    multipleData:[]
  },

  actions: {
    // 获取分组下门店
    getDealerList({ commit }, payload) {
      let {
        group_code,
        keyword,
        current,
        page_size
      } = payload

      return getDealerList({
        group_code,
        keyword,
        current,
        page_size
      })
        .then((res) => {
          console.log('getDealerList res', res)
          commit('changeState', { name: 'dealerData', data: res.data })
        })
    },
    // 获取门店下关联账号
    getDealerAccount({ commit }, payload) {
      let {
        dealer_id
      } = payload

      return getDealerAccount({
        dealer_id
      })
        .then((res) => {
          console.log('getDealerAccount res', res)
          commit('changeState', { name: 'accountData', data: res.data })
        })
    },
    // 收藏门店操作
    async editDealerFavorite({ commit }, payload) {
      let {
        favorite,
        dealer_id,
        group_code
      } = payload

      return editDealerFavorite({
        favorite,
        dealer_id,
        group_code
      })
        .then((res) => {
          console.log('editDealerFavorite res', res)
          commit('changeState', { name: 'editDealerRes', data: res.msg })
        })
    },

    getSortGroup({
        commit
    }) {
        return getSortGroup().then(res => {
            console.log('分组',res)
            commit('changeState',{name:'groupList',data:res.data.data})
        })
    },
    // 相关经销商分组
    getDealerGroup({
        commit
    },payload){
        let {params} = payload;
        return getSortGroup(params).then(res => {
            commit('changeState',{name:'multipleData',data:res.data.data})
        })
    },
    
  },

  mutations: {
    changeState(state, payload) {
      let {
        data,
        name
      } = payload;
      state[name] = data;
    }
  }
}
