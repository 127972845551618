import Nr from "@/utils/NetworldRequest";
const request = new Nr().getAxios();
function getBaseUrl(serviceName) {
  return new Nr().getBaseUrl(serviceName);
}
export default {
  // 选择分组
  getSortGroup(params) {
    return request({
      url: "/jjt/group/selectAllGroup",
      method: "get",
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      params,
    });
  },
  // 区域列表
  getAreaList() {
    return request({
      url: `/jjt/dealer/dealers/region-list`,
      method: "get",
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
    }).then((res) => {
      let list = res.data.data.region_list || [];
      if (res.data.data.type == 10) {
        list.unshift({
          id: "",
          name: "不限大区",
        });
      }
      list.map((item) => {
        item.value = item.id;
        item.label = item.name;
      });
      return list;
    });
  },

  // 获取账号分级
  getAccountData({ platform }) {
    return request({
      url: `/jjt/dealers/level`,
      method: "get",
      baseURL: getBaseUrl(platform),
    }).then((res) => {
      res = res.data.data || {};
      let newData = {
        xData: [],
        yData: [],
      };
      res.forEach((item) => {
        newData.xData.push(item.level);
        newData.yData.push(item.num);
      });
      return newData;
    });
  },
  // 矩阵活跃
  getMatrixActivation({ platform, params }) {
    return request({
      url: `/jjt/dealer/overview/matrix-activation`,
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    }).then((res) => {
      res = res.data.data || [];

      return res;
    });
  },

  // 数据看板
  getBoard({ platform, params }) {
    return request({
      url: `/jjt/dealer/overview/data-dashboard`,
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    }).then((res) => {
      res = res.data.data || {};
      return res;
    });
  },
  // 转化分析
  getAnalysis({ platform, params }) {
    return request({
      url: `/jjt/dealer/overview/analysis`,
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    }).then((res) => {
      res = res.data.data || {};
      return res;
    });
  },
  // 历史评级
  getHistoryLevel({ platform, params }) {
    return request({
      url: `/jjt/dealer/overview/history-level`,
      method: "get",
      baseURL: getBaseUrl(platform),
      params,
    }).then((res) => {
      res = res.data.data || {};
      return res;
    });
  },
};
