import Nr from "@/utils/NetworldRequest";
const request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
}).getAxios();
export default {
  // 获取全部违规标签
  getIllegalTag(params) {
    return request({
      url: `/jjt/principal/illegal/tag`,
      method: "get",
      params,
    });
  },
  // 刷量视频/挂播直播状态
  getIllegalTagDisplay(params) {
    return request({
      url: `/jjt/principal/illegal/tag/display`,
      method: "GET",
      params,
    });
  },
};
