import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios();
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}

export default {
    // 获取客户端连接mqtt的配置
    getMqttConfig(params) {
        return request({
            url: '/jjt/mqtt/live/client-setting',
            method: 'GET',
            baseURL: getBaseUrl('mqtt'),
            params: params
        });
    },
    // 添加直播监听
    getMqttLiveRoom(data) {
        return request({
            url: '/jjt/mqtt/live/add-live-room',
            method: 'POST',
            baseURL: getBaseUrl('mqtt'),
            // baseURL:process.env.VUE_APP_TEST_API,
            data,
        });
    },
    // 历史评论接口
    getHistoryComment(params) {
        return request({
            url: '/jjt/mqtt/live/mqtt-comment-history',
            method: 'get',
            baseURL: getBaseUrl('mqtt'),
            // baseURL:process.env.VUE_APP_TEST_API,
            params: params
        });
    },
    // 获取基本信息,实时直播视频链接
    getLiveVideo(params,type) {
        return request({
            url:`/jjt/live-map/live-detail?room_id=${params.room_id}&author_id=${params.author_id}`,
            baseURL: getBaseUrl(type),
        })
    },
    // 禁言列表
    getForbiddenList({
        current,
        page_size,
        live_room_id,
        platform,
        from_time,
      }){
        return request({
            url: '/jjt/mqtt/live/forbidden/record/query',
            method: 'GET',
            baseURL: getBaseUrl('mqtt'),
            params:  {
                current,
                page_size,
                live_room_id,
                platform,
                from_time
            }
        });
    },
    // 初始数据接口
    getInitDatas({
        params
    }){
        return request({
            url:'/jjt/mqtt/live/mqtt-statistic-history',
            method:'GET',
            baseURL: getBaseUrl('mqtt'),
            params
        })
    }
}