import api from '@/api/brandPerformance'

const {
    getReleasedAvg,
    getReleasedCalendar
} = api;

export default {
    namespaced: true,

    state: {
        brandInfo: [],
        communicationData: [],
        barData: {
            fans_count: [],
            live_watch_count: [],
            live_count: [],
            aweme_count: [],
            average_popular_aweme: []
        },
        salesData: [],
        calendarData: {
            xData: [],
            live_count_data: [],
            valid_live_data: [],
            popular_live_data: []
        }
    },

    actions: {
        // 获取品牌列表
        getReleasedAvg({ commit }, payload) {
            let {
                start_time,
                end_time,
                platform
            } = payload
            
            return getReleasedAvg({
                start_time,
                end_time,
                platform
            })
                .then((res) => {
                    console.log('getReleasedAvg res', res)
                    commit('changeState', { name: 'brandInfo', data: res.data })
                    commit('changeState', { name: 'communicationData', data: res.chartData.communication })
                    commit('changeState', { name: 'salesData', data: res.chartData.sales })
                    commit('changeState', { name: 'barData', data: res.chartData.barData })
                })
        },
        getReleasedCalendar({ commit }, payload) {
            let {
                platform
            } = payload
            
            return getReleasedCalendar({
                platform
            })
                .then((res) => {
                    console.log('getReleasedCalendar res', res)
                    commit('changeState', { name: 'calendarData', data: res.data })
                })
        },
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}
