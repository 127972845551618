import api from '@/api/storeManagement'

const {
    getDealerArea,
    getDealerList,
    getUnmatchDealerList,
    getDealerDetail,
    getDealerAuthorize
} = api;

export default {
    namespaced: true,

    state: {
        regionData: {},
        dealerList: [],
        listLoading: false,
        listTotal: 0,
        pagination: {},
        detailLoading: false,
        detailData: {},
        detailListLoading: false,
        detailList: [],
    },

    actions: {
        // 经销商区域
        getDealerArea({ commit }, payload) {
            // let {
            // } = payload

            return getDealerArea({
            })
                .then((res) => {
                    commit('changeState', { name: 'regionData', data: res })
                })
        },

        // 经销商列表
        getDealerList({ commit }, payload) {
            let {
                region_id,
                group_code,
                word,
                size,
                current
            } = payload

            commit('changeState', { name: 'listLoading', data: true })

            return getDealerList({
                region_id,
                group_code,
                word,
                size,
                current
            })
                .then((res) => {
                    commit('changeState', { name: 'dealerList', data: res.list })
                    commit('changeState', { name: 'listLoading', data: false })
                    commit('changeState', { name: 'listTotal', data: res.pagination.total })
                    commit('changeState', { name: 'pagination', data: res.pagination })
                })
        },
        getUnmatchDealerList({ commit }, payload) {
            let {
                region_id,
                word,
                group_code,
                size,
                current
            } = payload

            commit('changeState', { name: 'listLoading', data: true })

            return getUnmatchDealerList({
                region_id,
                word,
                group_code,
                size,
                current
            })
                .then((res) => {
                    commit('changeState', { name: 'dealerList', data: res.list })
                    commit('changeState', { name: 'listLoading', data: false })
                    commit('changeState', { name: 'listTotal', data: res.pagination.total })
                    commit('changeState', { name: 'pagination', data: res.pagination })
                })
        },

        //门店详情
        getDealerDetail({ commit }, payload) {
            let {
                dealer_id
            } = payload

            commit('changeState', { name: 'detailData', data: {} })
            commit('changeState', { name: 'detailLoading', data: true })

            return getDealerDetail({
                dealer_id
            })
                .then((res) => {
                    commit('changeState', { name: 'detailLoading', data: false })
                    commit('changeState', { name: 'detailData', data: res })
                })
        },

        //门店授权详情
        getDealerAuthorize({ commit }, payload) {
            let {
                dealer_id
            } = payload

            commit('changeState', { name: 'detailList', data: [] })
            commit('changeState', { name: 'detailListLoading', data: true })

            return getDealerAuthorize({
                dealer_id
            })
                .then((res) => {
                    console.log(res)
                    commit('changeState', { name: 'detailListLoading', data: false })
                    commit('changeState', { name: 'detailList', data: res })
                })
        },
        resetData({ commit }, payload) {
            commit('changeState', { name: 'detailData', data: {} })
            commit('changeState', { name: 'detailList', data: [] })
        },
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}
