<template>
  <a-drawer
    title="Basic Drawer"
    placement="right"
    :closable="false"
    :visible="tooltipModalShow ? true : false"
    :maskStyle="maskStyle"
    width="1114px"
    :headerStyle="headerStyle"
    :drawerStyle="drawerStyle"
    :bodyStyle="bodyStyle"
    :z-index="2000"
    @close="close"
  >
    <div class="total-container">
      <div class="total-container-top" @click="close"></div>
      <div class="total-container-main">
        <div class="total-container-main-header">
          <div>
            <span>全站指标说明</span>
            <span>Description of all station indicators</span>
          </div>
          <svg-icon :icon-class="'total-modal-tooltip'" class="icon" />
        </div>
        <div class="total-container-main-tools">
          <ModalInput ref="modalInput" :value="iptVal" @search="modalSearch" />
        </div>
        <div
          class="total-container-main-list"
          v-if="tooltipList && tooltipList.length > 0"
        >
          <div
            :class="['list-item', currentCode == item.code ? 'active' : '']"
            v-for="(item, index) in tooltipList"
            :key="index"
            :ref="`list_item_${item.code}`"
          >
            <div class="list-item-name">{{ item.name }}</div>
            <div
              class="list-item-text"
              v-html="item.explain"
              v-if="item.explain"
            ></div>
            <div
              class="list-item-text"
              v-html="item.formula"
              v-if="item.formula"
            ></div>
          </div>
        </div>
        <div class="empty-box" v-else>
          <Empty />
        </div>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import ModalInput from "@/components/ModalInput/ModalInput";
import tooltipList from "@/utils/tooltipList";
import deepClone from "@/utils/clone-utils";
import Empty from "@/components/Empty/Empty";
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } =
  vuex.createNamespacedHelpers("setting");
export default {
  name: "TotalToolip",
  components: { ModalInput, Empty },
  data() {
    return {
      drawerStyle: {
        width: "1114px",
      },
      maskStyle: {
        background: "rgba(0, 0, 0, 0.6)",
        "backdrop-filter": "blur(2px)",
      },
      headerStyle: {
        display: "none",
      },
      bodyStyle: {
        padding: "0",
      },
      iptVal: "",
      currentCode: "",
      tooltipList: deepClone(tooltipList),
    };
  },
  computed: {
    ...mapState({
      tooltipModalShow: (state) => state.tooltipModalShow,
    }),
  },
  watch: {
    tooltipModalShow(val) {
      this.$nextTick(() => {
        if (val) {
          this.tooltipList = deepClone(tooltipList);
          this.iptVal = "";
          this.$refs.modalInput.changeValue("");
          if (typeof val == "string") {
            this.currentCode = val;
            this.scrollDom(val);
          } else {
            this.currentCode = "";
          }
        }
      });
    },
  },
  async created() {
    this.setTooltipModal(false);
  },
  methods: {
    ...mapMutations(["setTooltipModal"]),
    close() {
      this.setTooltipModal(false);
    },
    modalSearch(val) {
      this.iptVal = val;
      if (val) {
        this.tooltipList = deepClone(tooltipList).filter((item) => {
          return item.name.indexOf(val) >= 0;
        });
      } else {
        this.tooltipList = deepClone(tooltipList);
      }
      // this.$message.success("搜索成功！");
    },
    scrollDom(val) {
      let dom = this.$refs[`list_item_${val}`][0];
      dom &&
        dom.scrollIntoView({
          //滚动到指定节点
          block: "start",
          behavior: "smooth",
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.ant-drawer {
  z-index: 2000 !important;
}

.total-container {
  height: 100vh;
  width: calc(100% - 14px);
  &-top {
    width: 100%;
    height: 64px;
  }
  &-main {
    width: 100%;
    height: calc(100vh - 78px);
    background: #282c37;
    box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.3);
    border-radius: 26px;
    padding: 0 30px;
    &-header {
      width: 100%;
      height: 103px;
      padding: 30px 0 22px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        > span:first-child {
          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
        }
        > span:last-child {
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          opacity: 0.4;
        }
      }
      .icon {
        font-size: 30px;
      }
    }
    &-tools {
      height: 44px;
      width: 100%;
      margin-bottom: 30px;
    }
    &-list {
      width: 100%;
      height: calc(100% - 177px);
      overflow-y: auto;
      .list-item {
        padding: 18px 20px;
        background: #1b1e26;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        border-radius: 19px;
        &-name {
          font-size: 16px;
          font-weight: bolder;
          color: #fff;
          line-height: 22px;
        }
        &-text {
          margin-top: 12px;
          font-size: 14px;
          font-weight: bold;
          color: rgba(255, 255, 255, 0.6);
          line-height: 20px;
        }
      }
      .list-item.active {
        background: linear-gradient(270deg, #ffca5c 0%, #ffe795 100%);
        .list-item-name {
          color: #000;
        }
        .list-item-text {
          color: #000;
        }
      }
    }
    .empty-box {
      width: 100%;
      height: calc(100% - 177px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
