import Nr from '@/utils/NetworldRequest'
import { addWeeks } from 'date-fns'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}

export default {
  getBrandList({
    statistics_begin_date,
    statistics_end_date,
    platform
  }) {
    return request({
      url: `/jjt/brand-matrix/dealerPrincipal`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        statistics_begin_date,
        statistics_end_date,
      }
    }).then(res => {
      res = res.data
      return {
        code: res.code,
        data: {
          ...res.data
        },
        msg: res.msg
      }
    })
  },
  getInfoByPid({
    statistics_begin_date,
    statistics_end_date,
    principal_id,
    platform
  }) {
    return request({
      url: `/jjt/brand-matrix/dealerStatistics`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        statistics_begin_date,
        statistics_end_date,
        principal_id,
      }
    }).then(res => {
      res = res.data
      return {
        code: res.code,
        data: {
          ...res.data,
          dealer_infos: res.data.dealer_infos.map(item => {
            // let newArr = [item.dealer_count, item.marketing_value, item.sale_value, item.live_count, item.live_watch_count, item.aweme_count, item.aweme_like_count]
            let newArr = [item.dealer_count, item.marketing_value, item.live_count, item.live_watch_count, item.aweme_count, item.aweme_like_count]
            return newArr
          }),
          aweme_count_distributeds: res.data.aweme_count_distributeds.map(item => {
            let newArr = []
            newArr.push(item[1])
            newArr.push(item[0])
            newArr.push(item[2])
            return newArr
          }),
          live_count_distributeds: res.data.live_count_distributeds.map(item => {
            let newArr = []
            newArr.push(item[1])
            newArr.push(item[0])
            newArr.push(item[2])
            return newArr
          }),
          xData: res.data.weeks.map(item => {
            return `第${item}周`
          })
        },
        msg: res.msg
      }
    })
  },

  // 矩阵价值信息
  getMatrixData({
    year,
    month,
    platform
  }) {
    return request({
      url: `/jjt/brand-matrix/matrixValue`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        year,
        month
      }
    }).then(res => {
      res = res.data
      return {
        code: res.code,
        data: {
          ...res.data,
          leftData: res.data.level_to_value[0].values.map((item) => {
            return item.value
          }),
          rightData1: res.data.level_to_value[1].values.map((item) => {
            return item.value
          }),
          rightData2: res.data.level_to_value[2].values.map((item) => {
            return item.value
          }),
          rightData3: res.data.level_to_value[3].values.map((item) => {
            return item.value
          }),
          rightData4: res.data.level_to_value[4].values.map((item) => {
            return item.value
          }),
          rightData5: res.data.level_to_value[5].values.map((item) => {
            return item.value
          }),
          rightData6: res.data.level_to_value[6].values.map((item) => {
            return item.value
          }),
        },
        msg: res.msg
      }
    })
  },
  // 营销&销售价值分布
  getDateChart({
    platform
  }) {
    return request({
      url: `/jjt/brand-matrix/distributedInfo`,
      method: 'get',
      baseURL: getBaseUrl(platform),
    }).then(res => {
      res = res.data
      return {
        code: res.code,
        data: {
          marketing_distributed: res.data.marketing_distributed.map(item => {
            return [item.date, item.value]
          }),
          sale_distributed: res.data.sale_distributed.map(item => {
            return [item.date, item.value]
          })
        },
        msg: res.msg
      }
    })
  },
}
