import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
    // 品牌官号运营列表
    getOfficialList({
        start_time,
        end_time,
        tag_ps,
        platform
    }) {
        return request({
            url: `/jjt/new/brand/list`,
            method: "get",
            baseURL: getBaseUrl(platform),
            params: {
                start_time,
                end_time,
                tag_ps,
            }
        }).then(res => {
            res = res.data
            return res
        })
    },
    // 品牌官号运营发布数据
    getReleaseData({
        year,
        author_id,
        platform
    }) {
        return request({
            url: `/jjt/new/brand/release`,
            method: "get",
            baseURL: getBaseUrl(platform),
            params: {
                year,
                author_id,
            }
        }).then(res => {
            res = res.data
            return {
                code: res.code,
                data: {
                    xData: res.data.map(item => {
                        return item.statistics_month + '月'
                    }),
                    fans_count_total_di: res.data.map(item => {
                        return item.fans_count_total_di
                    }),
                    live_hot: res.data.map(item => {
                        return item.live_hot
                    }),
                    aweme_hot: res.data.map(item => {
                        return item.aweme_hot
                    }),
                    live_total_count: res.data.map(item => {
                        return item.live_total_count
                    }),
                    aweme_count_di: res.data.map(item => {
                        return item.aweme_count_di
                    }),
                    live_avg_total_watch_user_time: res.data.map(item => {
                        return item.live_avg_total_watch_user_time
                    }),
                    live_avg_interactive_count: res.data.map(item => {
                        return item.live_avg_interactive_count
                    })
                },
                msg: res.msg
            }
        })
    },
    // 主播相关信息
    getAuthorMessage({
        id,
        type
    }) {
        return request({
            url: `/jjt/author/${id}`,
            method: "get",
            baseURL: getBaseUrl(type),
        })
    },
    // 月直播信息统计
    getLiveMessage({
        type,
        params
    }) {
        return request({
            url: '/jjt/dealers/aweme/live/details',
            method: "get",
            baseURL: getBaseUrl(type),
            params,
        })
    },
    // top30直播统计
    getTopThirtyLive({
        type,
        params,
    }) {
        return request({
            url: '/jjt/dealers/aweme/top/thirty',
            method: 'get',
            baseURL: getBaseUrl(type),
            params,
        })
    },
    // 月份直播日历
    getCalendarLive({

        type,
        params,
    }) {
        return request({
            url: `/jjt/live/show/calendar/${params.author_id}`,
            method: 'get',
            baseURL: getBaseUrl(type),
            params: {
                start_time: params.start_time,
                end_time: params.end_time
            },

        })
    },

    // 标签
    getPrincipalTags() {
        return request({
            url: `/jjt/principal/tags`,
            method: 'get',
            baseURL: process.env.VUE_APP_OPENDATA_AGG_API
        }).then(res => {
            res = res.data
            res.map(item => {
                item.text = item.tag_name
                item.value = item.tag_name
            })
            res.unshift({
                text: '全部',
                value: ''
            })
            return res
        })
    },
}