import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
  // 区域列表
  getAreaList(){
    return request({
      url:'/jjt/dealer/dealers/region-list',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      method:'get',
    })
  },
  // 大区透视-top5门店
  getDealerList({
    params,type
  }){
    let judgeUrl = ''
    switch (type) {
      case 1:
        judgeUrl =process.env.VUE_APP_OPENDATA_API
        break;
      case 2:
        judgeUrl =process.env.VUE_APP_OPENDATA_KS_API
        break;
      default:
        break;
    }
    return request({
      url:'/jjt/dealers/region/dealer-list',
      method:'get',
      baseURL:judgeUrl,
      params,
    })
  },
  getAccountList({
    dealer_id,
    platform
  }) {
    return request({
      url: `/jjt/dealers/${dealer_id}/account-list`,
      method: 'get',
      baseURL: getBaseUrl(platform),
    }).then(res => {
      res = res.data
      return res
    })
  },
  getAccountInfo({
    author_id,
    platform
  }) {
    return request({
      url: `/jjt/dealers/${author_id}/info`,
      method: 'get',
      baseURL: getBaseUrl(platform),
    }).then(res => {
      res = res.data
      return {
        code: res.code,
        data: {
          ...res.data
        },
        msg: res.msg
      }
    })
  },
  getLiveAndAweme({
    author_id,
    platform
  }) {
    return request({
      url: `/jjt/dealers/${author_id}/live-and-aweme`,
      method: 'get',
      baseURL: getBaseUrl(platform),
    }).then(res => {
      res = res.data
      return {
        code: res.code,
        data: {
          ...res.data
        },
        msg: res.msg
      }
    })
  },
}
