import api from '@/api/regionalStores'
const{
    getAreaList,
    getDealerList,
    getAccountList,
    getAccountInfo,
    getLiveAndAweme
} = api

export default {
    namespaced: true,

    state: {
        areaList:[],
        tableLoading: false,
        leftLoading: false,
        rightLoading: false,
        dealerTopList:[],
        accountList: [],
        leftInfoData: {},
        liveAndAwemeData: {
            best: {
                aweme: {},
                live: {}
            },
            recent: {
                aweme: {},
                live: {}
            }
        }
    },

    actions: {
        // 获取区域
        getAreaList({ commit }){
            commit('changeState', { name: 'accountList', data: [] })
            commit('changeState', { name: 'leftInfoData', data: {} })
            commit('changeState', { name: 'liveAndAwemeData', data: {
                best: {
                    aweme: {},
                    live: {}
                },
                recent: {
                    aweme: {},
                    live: {}
                }
            } })
            return getAreaList()
                .then((res) => {
                    if(res.data.code==0){
                      let arr = [];
                      if (res.data.data.type == 10) {
                        arr.push({
                            value: '',
                            label: '不限大区'
                        })
                      }
                      res.data.data.region_list.forEach(element => {
                            let value = {
                                value:element.id,
                                label:element.name
                            }
                            arr.push(value)
                      });
                      commit('changeState',{name:'areaList',data:{
                        region_list: arr,
                        type: res.data.data.type
                      }})
                    }
                    
                })
        },
        // top5门店
        getDealerList({commit},payload){
            let {
                params,
                type
            } = payload
            commit('changeState',{name:'tableLoading',data:true})
            commit('changeState', { name: 'accountList', data: [] })
            commit('changeState', { name: 'leftInfoData', data: {} })
            commit('changeState', { name: 'liveAndAwemeData', data: {
                best: {
                    aweme: {},
                    live: {}
                },
                recent: {
                    aweme: {},
                    live: {}
                }
            } })
            return getDealerList({type,params})
                .then((res) => {
                    if(res.data.code==0){
                        res.data.data.forEach(item=>{
                            if(item.live_total_time || item.live_total_time == 0){
                                var minutes = parseInt(item.live_total_time / 60);
                                // var seconds = (item.live_total_time % 60);
                                let value = '0分'
                                value = minutes + '分'
                                item.live_total_time = value
                            } else {
                                item.live_total_time = '-'
                            }
                        })
                        commit('changeState', { name: 'dealerTopList', data: res.data.data })
                    }
                        commit('changeState',{name:'tableLoading',data:false})
                })
                .catch(()=>{ 
                    commit('changeState',{name:'tableLoading',data:false})
                })
        },
        getAccountList({ commit }, payload) {
            let {
                dealer_id,
                platform
            } = payload
            commit('changeState', { name: 'accountList', data: [] })
            commit('changeState', { name: 'leftInfoData', data: {} })
            commit('changeState', { name: 'liveAndAwemeData', data: {
                best: {
                    aweme: {},
                    live: {}
                },
                recent: {
                    aweme: {},
                    live: {}
                }
            } })
            
            return getAccountList({
                dealer_id,
                platform
            })
                .then((res) => {
                    console.log('getAccountList res', res)
                    commit('changeState', { name: 'accountList', data: res.data || [] })
                })
        },
        getAccountInfo({ commit }, payload) {
            let {
                author_id,
                platform
            } = payload
            commit('changeState', { name: 'leftInfoData', data: {} })
            commit('changeState',{name:'leftLoading',data:true})
            
            return getAccountInfo({
                author_id,
                platform
            })
                .then((res) => {
                    console.log('getAccountInfo res', res)
                    commit('changeState', { name: 'leftInfoData', data: res.data || {} })
                    commit('changeState',{name:'leftLoading',data:false})
                })
        },
        getLiveAndAweme({ commit }, payload) {
            let {
                author_id,
                platform
            } = payload
            commit('changeState',{name:'rightLoading',data:true})
            commit('changeState', { name: 'liveAndAwemeData', data: {
                best: {
                    aweme: {},
                    live: {}
                },
                recent: {
                    aweme: {},
                    live: {}
                }
            } })
            
            return getLiveAndAweme({
                author_id,
                platform
            })
                .then((res) => {
                    console.log('getLiveAndAweme res', res)
                    commit('changeState', { name: 'liveAndAwemeData', data: res.data || {} })
                    commit('changeState',{name:'rightLoading',data:false})
                })
        },
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}