import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
  // 行业平均表现发布情况
  getReleasedAvg({
    start_time,
    end_time,
    platform
  }) {
    return request({
      url: `/jjt/industry/released/avg`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        start_time,
        end_time,
      }
    }).then(res => {
      res = res.data
      let newRes = {
        code: res.code,
        data: res.data,
        chartData: {
          barData: {
            xData: res.data.map(item => {
              return item.account_level
            }).reverse(),
            fans_count: res.data.map(item => {
              return item.fans_count
            }).reverse(),
            live_watch_count: res.data.map(item => {
              return item.live_watch_count
            }).reverse(),
            live_count: res.data.map(item => {
              return item.live_count
            }).reverse(),
            aweme_count: res.data.map(item => {
              return item.aweme_count
            }).reverse(),
            average_popular_aweme: res.data.map(item => {
              return item.average_popular_aweme
            }).reverse()
          },
          communication: res.data.map(item => {
            return {
              name: item.account_level,
              value: item.communication_value
            }
          }),
          sales: res.data.map(item => {
            return {
              name: item.account_level,
              value: item.sales_value
            }
          })
        },
        msg: res.msg
      }
      return newRes
    })
  },
  // 行业平均表现日历数据
  getReleasedCalendar({
    platform
  }) {
    return request({
      url: `/jjt/industry/released/calendar`,
      method: 'get',
      baseURL: getBaseUrl(platform),
    }).then(res => {
      res = res.data
      let newRes = {
        code: res.code,
        data: {
          xData: res.data.xData,
          live_count_data: res.data.data.map(item => {
            let newArr = []
            newArr.push(JSON.parse(item.live_count)[1])
            newArr.push(JSON.parse(item.live_count)[0])
            newArr.push(JSON.parse(item.live_count)[2])
            return newArr
          }),
          valid_live_data: res.data.data.map(item => {
            let newArr = []
            newArr.push(JSON.parse(item.valid_live)[1])
            newArr.push(JSON.parse(item.valid_live)[0])
            newArr.push(JSON.parse(item.valid_live)[2])
            return newArr
          }),
          popular_live_data: res.data.data.map(item => {
            let newArr = []
            newArr.push(JSON.parse(item.popular_live)[1])
            newArr.push(JSON.parse(item.popular_live)[0])
            newArr.push(JSON.parse(item.popular_live)[2])
            return newArr
          })
        },
        msg: res.msg
      }
      console.log(newRes)
      return newRes
    })
  },
}
