import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
    return (new Nr).getBaseUrl(serviceName)
}
export default {
    // 主播相关信息
    getAuthorMessage({
        id,
        type
    }) {
        return request({
            url: `/jjt/author/${id}`,
            method: "get",
            baseURL: getBaseUrl(type),
        })
    },
    // 月直播信息统计
    getLiveMessage({
        type,
        params
    }) {
        return request({
            url: '/jjt/dealers/aweme/live/details',
            method: "get",
            baseURL: getBaseUrl(type),
            params,
        })
    },
    // 月直播信息统计-直播 新
    getNewLiveMessage({
        type,
        params
    }) {
        return request({
            url: `/jjt/live/${params.author_id}/detail`,
            method: "get",
            baseURL: getBaseUrl(type),
            params,
        })
    },
    // top30直播统计
    getTopThirtyLive({
        type,
        params,
    }) {
        return request({
            url: '/jjt/dealers/aweme/top/thirty',
            method: 'get',
            baseURL: getBaseUrl(type),
            params,
        })
    },
    // 顶部视频详情数据
    getAwemeDetail({
        type,
        params,
    }) {
        return request({
            url: '/jjt/dealers/aweme/detail',
            method: 'get',
            baseURL: getBaseUrl(type),
            params,
        })
    },
    // 月份直播日历
    getCalendarLive({
        type,
        params,
    }) {
        return request({
            url: `/jjt/live/show/calendar/${params.author_id}`,
            method: 'get',
            baseURL: getBaseUrl(type),
            params: {
                start_time: params.start_time,
                end_time: params.end_time
            },

        })
    },
    // 获取视频列表
    getVideoList({
        type,
        params,
    }) {
        return request({
            url: `/jjt/author/aweme/top/thirty/${params.author_id}`,
            method: 'get',
            baseURL: getBaseUrl(type),
            params: {
                start_time: params.start_time,
                end_time: params.end_time
            },

        })
    },
    // 获取顶部账号详情
    getAuthorDetail({
        type,
        params,
    }) {
        return request({
            url: `/jjt/author/details/${params.author_id}`,
            method: 'get',
            baseURL: getBaseUrl(type),

        })
    },
    // 获取顶部账号详情
    getAuthorDetailN({
        type,
        params,
    }) {
        return request({
            url: `/jjt/author/detail/${params.author_id}`,
            method: 'get',
            baseURL: getBaseUrl(type),

        })
    },
    // 获取矩阵账号列表
    getMatrixList({
        type,
        params,
    }) {
        return request({
            url: `/jjt/matrix/monitor/notice/author/list`,
            method: 'get',
            baseURL: getBaseUrl(type),
            params
        })
    },
    // 获取粉丝画像
    getFansPortrait({
        type,
        params,
    }) {
        return request({
            url: `/jjt/author/fans/portrait`,
            method: 'get',
            baseURL: getBaseUrl(type),
            params
        })
    },

    // 新版 - 账号详情接口 -------------------

    // 视频 start ----
    // - 视频数据概览
    getVideoDataChart({
        type,
        params
    }) {
        return request({
            url: '/jjt/author/detail/aweme/stat',
            method: "get",
            baseURL: getBaseUrl(type),
            params,
        })
    },
    // - 视频列表
    getVideoDataList({
        type,
        data
    }) {
        return request({
            url: '/jjt/author/detail/aweme/list',
            method: "post",
            baseURL: getBaseUrl(type),
            data,
        })
    },
    // 视频 end ----

    // 直播 start ----
    // - 直播数据概览
    getLiveDataChart({
        type,
        params
    }) {
        return request({
            url: '/jjt/author/detail/live/stat',
            method: "get",
            baseURL: getBaseUrl(type),
            params,
        })
    },
    // - 直播列表
    getLiveDataList({
        type,
        data
    }) {
        return request({
            url: '/jjt/author/detail/live/list',
            method: "post",
            baseURL: getBaseUrl(type),
            data,
        })
    },
    // 直播 end ----

    // 粉丝 start ----
    // - 粉丝数据概览
    getFansDataChart({
        type,
        params
    }) {
        return request({
            url: '/jjt/author/detail/fans/stat',
            method: "get",
            baseURL: getBaseUrl(type),
            params,
        })
    },
    // - 获取粉丝画像
    getFansPortraitByTime({
        type,
        params,
    }) {
        return request({
            url: `/jjt/author/detail/fans/chart`,
            method: 'get',
            baseURL: getBaseUrl(type),
            params
        })
    },
    // 粉丝 end ----

    // 线索 start ----
    // - 线索数据概览
    getClueDataChart({
        type,
        params
    }) {
        return request({
            url: '/jjt/author/detail/clue/stat',
            method: "get",
            baseURL: getBaseUrl(type),
            params,
        })
    },
    // 线索 end ----
}