<template>
  <a-drawer
    title="Basic Drawer"
    placement="right"
    :closable="false"
    :visible="areaRouteShow"
    :maskStyle="maskStyle"
    width="420px"
    :headerStyle="headerStyle"
    :drawerStyle="drawerStyle"
    :bodyStyle="bodyStyle"
    @close="close"
  >
    <div class="total-container">
      <div class="total-container-top" @click="close"></div>
      <div class="total-container-main">
        <div class="total-container-main-header">
          <div>
            <span>全站地图</span>
            <span>Total station map</span>
          </div>
          <svg-icon :icon-class="'total-modal-area'" class="icon" />
        </div>
        <div class="total-container-main-tools">
          <ModalInput ref="modalInput" :value="searchIpt" @search="sureClick" />
        </div>
        <div class="total-container-main-list">
          <!-- <div class="button-group">
            <span
              v-for="(item, index) in buttonList"
              :key="index"
              @click="routerTo(item.path)"
              >{{ item.name }}</span
            >
          </div> -->
          <div class="container-main">
            <div class="container-main-col" ref="col1">
              <div class="item" v-for="(item, index) in mapList1" :key="index">
                <div class="item-title">
                  <svg-icon :icon-class="item.icon" class="icon" />{{
                    item.name
                  }}
                </div>
                <div
                  :class="
                    currentIpt == ic.name
                      ? 'item-children active'
                      : 'item-children'
                  "
                  v-for="(ic, i) in item.children"
                  :key="i"
                  @click="routerTo(ic.path)"
                >
                  {{ ic.name
                  }}<span
                    class="tag"
                    v-if="stringToObject(ic.extra).menu_status == 1"
                    >NEW</span
                  >
                </div>
              </div>
            </div>
            <div class="container-main-col" ref="col2">
              <div class="item" v-for="(item, index) in mapList2" :key="index">
                <div class="item-title">
                  <svg-icon :icon-class="item.icon" class="icon" />{{
                    item.name
                  }}
                </div>
                <div
                  :class="
                    currentIpt == ic.name
                      ? 'item-children active'
                      : 'item-children'
                  "
                  v-for="(ic, i) in item.children"
                  :key="i"
                  @click="routerTo(ic.path)"
                >
                  {{ ic.name
                  }}<span
                    class="tag"
                    v-if="stringToObject(ic.extra).menu_status == 1"
                    >NEW</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="empty-box" v-else>
          <Empty />
        </div> -->
      </div>
    </div>
    <!-- <div class="header">
      <a-icon type="right" class="font-color2 icon1" @click="close" />
      <svg-icon icon-class="area-icon" class="icon2" />
      <span>全站地图</span>
    </div> -->

    <!-- <div class="container">
      <div class="tools">
        <a-input placeholder="搜索您想要的功能，如经销商管理" v-model="searchIpt" class="area-input">
          <a-icon slot="prefix" type="search" class="font-color1" style="opacity: 0.2;"/>
        </a-input>
        <a-button class="button" @click="currentIpt = searchIpt">确定</a-button>
      </div>
      <div class="button-group">
        <span v-for="(item, index) in buttonList" :key="index" @click="routerTo(item.path)">{{item.name}}</span>
      </div>
      <div class="container-main">
        <div class="container-main-col">
          <div class="item" v-for="(item,index) in mapList1" :key="index">
            <div class="item-title">
               <svg-icon :icon-class="item.icon" class="icon"/>{{item.name}}
            </div>
            <div :class="currentIpt == ic.name ? 'item-children active' : 'item-children'" v-for="(ic,i) in item.children" :key="i" @click="routerTo(ic.path)">{{ic.name}}<span class="tag" v-if="stringToObject(ic.extra).menu_status == 1">NEW</span></div>
          </div>
        </div>
        <div class="container-main-col">
          <div class="item" v-for="(item,index) in mapList2" :key="index">
            <div class="item-title">
               <svg-icon :icon-class="item.icon" class="icon"/>{{item.name}}
            </div>
            <div :class="currentIpt == ic.name ? 'item-children active' : 'item-children'" v-for="(ic,i) in item.children" :key="i" @click="routerTo(ic.path)">{{ic.name}}<span class="tag" v-if="stringToObject(ic.extra).menu_status == 1">NEW</span></div>
          </div>
        </div>
        <div class="container-main-col">
          <div class="item" v-for="(item,index) in mapList3" :key="index">
            <div class="item-title">
               <svg-icon :icon-class="item.icon" class="icon"/>{{item.name}}
            </div>
            <div :class="currentIpt == ic.name ? 'item-children active' : 'item-children'" v-for="(ic,i) in item.children" :key="i" @click="routerTo(ic.path)">{{ic.name}}<span class="tag" v-if="stringToObject(ic.extra).menu_status == 1">NEW</span></div>
          </div>
        </div>
      </div>
    </div> -->
  </a-drawer>
</template>

<script>
import * as vuex from "vuex";
import ModalInput from "@/components/ModalInput/ModalInput";
import Empty from "@/components/Empty/Empty";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "auth"
);
export default {
  name: "HeaderRoute",
  components: { ModalInput, Empty },
  props: ["areaRouteShow"],
  data() {
    return {
      drawerStyle: {
        width: "420px",
      },
      maskStyle: {
        background: "rgba(0, 0, 0, 0.6)",
        "backdrop-filter": "blur(2px)",
      },
      headerStyle: {
        display: "none",
      },
      bodyStyle: {
        padding: "0",
      },
      searchIpt: "",
      currentIpt: "",
      buttonList: [
        {
          name: "门店查询",
          path: "/dealer/storeSearch",
        },
        {
          name: "数据下载",
          path: "/dealer/storeCompetition",
        },
        {
          name: "竞品对标",
          path: "/dealer/regionContrast",
        },
        {
          name: "门店运营管理",
          path: "/dealer/storeManagement",
        },
        {
          name: "直播竞赛",
          path: "/dealer/storeRanking",
        },
        {
          name: "刷量监测",
          path: "/depthInspection/videoMonitoring",
        },
      ],
      mapList1: [],
      mapList2: [],
    };
  },
  computed: {
    ...mapState({
      areaList: (state) => state.areaList,
    }),
  },
  watch: {
    areaList(val) {
      let h1 = 0;
      let h2 = 0;
      val.forEach((item, index) => {
        if (h1 <= h2) {
          h1 += item.children.length;
          this.mapList1.push(item);
        } else {
          h2 += item.children.length;
          this.mapList2.push(item);
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getAreaAuthority();
    });
  },
  methods: {
    ...mapActions(["getAreaAuthority"]),
    close() {
      this.$emit("close");
    },
    routerTo(path) {
      const plusPaths = [
        "/compliance/violation-stat", // 违规统计
        "/compliance/violation-list", // 违规明细
      ];

      if (path) {
        if (plusPaths.includes(path)) {
          if (process.env.NODE_ENV === "development") {
            const PLUS_HOST = process.env.VUE_APP_PLUS_HOST;
            window.open(`${PLUS_HOST}${path}`);
          } else {
            window.location.href = `/jjt-new${path}`;
          }
        } else {
          this.$router.push({ path });
        }

        this.$emit("close");
      } else {
        this.$message.warning("当前页面还未公测");
      }
    },
    stringToObject(str) {
      return JSON.parse(str);
    },
    sureClick(val) {
      this.searchIpt = val;
      this.currentIpt = this.searchIpt;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.total-container {
  height: 100vh;
  width: calc(100% - 14px);
  &-top {
    width: 100%;
    height: 64px;
  }
  &-main {
    width: 100%;
    height: calc(100vh - 78px);
    background: #282c37;
    box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.3);
    border-radius: 26px;
    padding: 0 30px;
    &-header {
      width: 100%;
      height: 103px;
      padding: 30px 0 22px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        > span:first-child {
          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
        }
        > span:last-child {
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          opacity: 0.4;
        }
      }
      .icon {
        font-size: 42px;
      }
    }
    &-tools {
      height: 44px;
      width: 100%;
      margin-bottom: 30px;
    }
    &-list {
      width: 100%;
      height: calc(100% - 177px);
      overflow-y: auto;
      .button-group {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 32px;
        > span {
          width: 108px;
          height: 38px;
          background: #1b1e26;
          border-radius: 19px;
          text-align: center;
          line-height: 38px;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
          margin-bottom: 8px;
          margin-right: 8px;
          cursor: pointer;
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:hover {
            background: linear-gradient(270deg, #ffc840 0%, #ffe674 100%);
            font-weight: bold;
            color: #1c1e27;
          }
        }
      }
      .container-main {
        display: flex;
        &-col {
          width: calc(50% - 6px);
          display: inline-block;
          &:first-child {
            margin-right: 12px;
          }
          .item {
            margin-bottom: 12px;
            background: #1d2229;
            padding: 0 0 5px 0;
            border-radius: 16px;
            overflow: hidden;
            &-title {
              width: 100%;
              height: 49px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.1);
              display: flex;
              align-items: center;
              font-size: 22px;
              color: #fff;
              .icon {
                font-size: 28px;
                margin: 0 10px;
              }
            }
            &-children {
              height: 40px;
              width: 100%;
              background: #1d2229;
              text-align: left;
              padding-left: 48px;
              line-height: 40px;
              color: rgba(255, 255, 255, 0.6);
              font-size: 12px;
              cursor: pointer;
              &:hover,
              &.active {
                background: #181c22;
                color: #ffca5c;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
    .empty-box {
      width: 100%;
      height: calc(100% - 177px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.font-color1 {
  @include font_color("font_color1");
}
.font-color2 {
  @include font_color("font_color2");
}
.font-color3 {
  @include font_color("font_color3");
}
</style>
