import _ from "lodash";

export default {
  data() {
    return {};
  },
  mounted() {
    window.addEventListener("resize", this.resizeEmit);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeEmit);
  },
  methods: {
    resizeEmit: _.throttle(function() {
      this.$root.$emit("textellipsisResize");
    }, 500),
  },
};
