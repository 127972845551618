<template>
  <div>
    <a-dropdown class="aft-dropdown">
      <div class="header-avatar" style="cursor: pointer">
        <a-avatar
          class="avatar"
          size="small"
          shape="circle"
          :src="principalLogo"
        />
        <span class="name">{{ principalName }}</span>
      </div>
      <a-menu :class="['avatar-menu', 'aft-menu']" slot="overlay">
        <a-menu-item
          v-for="(item, index) in principalList"
          :key="index"
          @click="principalsChange(item)"
        >
          <a-space>
            <a-avatar
              size="small"
              shape="circle"
              :src="`//aft-live-cdn.afanticar.com/${item.logo}`"
            />
            <span>{{ item.principal_name }}</span>
          </a-space>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <a-dropdown class="aft-dropdown">
      <div class="header-user" style="cursor: pointer">
        <div class="user">
          <svg-icon icon-class="hi" /> 欢迎使用
        </div>
        <span class="account">
          ID: {{ userName }}
          <a-icon class="icon" type="down" />
        </span>
      </div>
      <a-menu class="logout" slot="overlay">
        <a-menu-item @click="logout">
          <a-icon style="margin-right: 8px" type="poweroff" />
          <span>退出登录</span>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapActions } = createNamespacedHelpers("auth");
import {
  setPrincipalId,
  setPrincipalName,
  setPrincipalState,
  setPrincipalLogo,
  removeToken,
  removeUserId,
  removePrincipalId,
  removeRoutes,
  removeName,
  removeUserName,
  getPrincipalLogo,
  getPrincipalList,
  getPrincipalName,
  removePrincipalLogo,
  removePrincipalList,
  removePrincipalName,
  setRedirectPath,
  setRegionTrackData,
  getUserName,
  getName,
} from "@/utils/auth";

export default {
  name: "HeaderAvatar",
  data() {
    return {
      principalLogo: "",
      principalName: "",
      principalList: [],
      currentName: "",
      userName: "",
    };
  },
  computed: {
    ...mapState({
      name: (state) => state.name,
      avatar: (state) => state.avatar,
      list: (state) => state.list,
    }),
  },
  created() {
    this.principalLogo = getPrincipalLogo();
    // this.principalList = JSON.parse(getPrincipalList())
    this.principalList = JSON.parse(localStorage.getItem("principal_list"));
    this.principalName = getPrincipalName();
    this.currentName = getName();
    this.userName = getUserName();
  },
  methods: {
    ...mapMutations(["SET_NAME", "SET_AVATAR"]),
    ...mapActions(["logOut", "getRegionUserInfo", "getPlatformSetting"]),
    // 退出登录
    logout() {
      this.logOut()
        .then(() => {
          removeToken();
          removeUserId();
          removePrincipalId();
          removePrincipalLogo();
          // removePrincipalList()
          localStorage.removeItem("principal_list");
          removePrincipalName();
          removeRoutes();
          removeName();
          removeUserName();
          setRedirectPath(window.location.href);
          this.$router.push(`/login`);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 主体切换
    async principalsChange(item) {
      setPrincipalId(item.principal_id);
      setPrincipalLogo(item.logo);
      setPrincipalName(item.principal_name);
      setPrincipalState(item.state);
      this.SET_NAME(item.principal_name);
      this.SET_AVATAR(`${process.env.VUE_APP_LIVE_OSS_HOST}${item.logo}`);
      // 更新region_track_data
      const regionUserInfo = await this.getRegionUserInfo(item.principal_id);
      await this.getPlatformSetting(item.principal_id);
      setRegionTrackData({
        account_type: regionUserInfo.accountType,
        username: regionUserInfo.username,
        mobile: regionUserInfo.mobile,
        region_village:
          regionUserInfo.accountType === 10 ? [] : regionUserInfo.region,
      });
      // 刷新
      this.$router.go(0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.header-avatar {
  display: inline-flex;
  .avatar,
  .name {
    align-self: center;
    @include font_color("font_color1");
  }
  .avatar {
    margin-right: 8px;
  }
  .name {
    font-weight: 500;
  }
}
.header-user {
  transform: translateY(-12px);
  margin-left: 24px;
  display: inline-flex;
  @include font_color("font_color1");
  line-height: 1;
  flex-direction: column;
  justify-content: center;
  .account {
    margin-top: 4px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.55);
    .icon {
      transform: translateY(-10px);
    }
  }
}
.avatar-menu {
  width: 150px;
}
.logout {
  width: auto;
  padding: 5px;
}
</style>
