import api from '@/api/fullScreenLive'
const {
    getMqttConfig,
    getInfo,
    getForbiddenList
} = api

export default {
    namespaced: true,

    state: {
        mqttConfigData: null,
        isHaveForbidden: false,
        forbiddenData:[],
        current_no: 1,
        page_size_f: 10,
        isFirstForbidden: true,
        myName:'是复赛地方',
    },

    actions: {
        // 获取mqtt配置
        getMqttConfig(ctx, payload) {
            return getMqttConfig(payload).then(res => {
                ctx.commit('changeState', {
                    data: res.data.data,
                    name: 'mqttConfigData'
                })
            });
        },
        getForbiddenList(ctx, payload) {
            let {
              current,
              page_size,
              live_room_id,
              platform,
              from_time
            } = payload;
            const {commit} = ctx
            commit('changeState',{data:false,name:'isHaveForbidden'})
      
            return getForbiddenList({
              current,
              page_size,
              live_room_id,
              platform,
              from_time,
            }).then((res)=>{
      
              let newArr = []
              console.log('ctx.state.isFirstForbidden', ctx.state.isFirstForbidden)
              if (ctx.state.isFirstForbidden) {          
                newArr = ctx.state.forbiddenData.concat(res.data.data.list)
              console.log('hahaha1', newArr)
            } else {
                newArr = res.data.data.list.concat(ctx.state.forbiddenData)
              console.log('hahaha2', newArr)
            }
              console.log('hahaha3', newArr)
              commit('changeState',{data:newArr,name:'forbiddenData'})
              if (payload.current < res.data.data.pagination.pages) {
                commit('changeState',{data:payload.current + 1,name:'current_no'})
                commit('changeState',{data:true,name:'isHaveForbidden'})
              } else {
                commit('changeState',{data:false,name:'isFirstForbidden'})
                commit('changeState',{data:1,name:'current_no'})
              }
            })
          },
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}