import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
  // 获取品牌列表
  getBrandList({
    platform
  }) {
    return request({
      url: `/jjt/brand-matrix/enemyPrincipal`,
      method: 'get',
      baseURL: getBaseUrl(platform)
    }).then(res => {
      res = res.data
      return ({
        code: res.code,
        data: res.data,
        msg: res.msg
      })
    })
  },
  // 获取矩阵数据
  getMatrixData({
    principal_ids,
    platform
  }) {
    return request({
      url: `/jjt/brand-matrix/info`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        principal_ids
      }
    }).then(res => {
      res = res.data
      console.log(111, res.data.dealer_percent_list.map(i => {return {name: i.principal_name, value: i.total}}))
      return ({
        code: res.code,
        data: {
          overview_all_list: res.data.overview_all_list,
          platform_list: {
            xData: res.data.platform_list.map(i => {return i.principal_name}),
            yData: res.data.platform_list.map(i => {return i.value})
          },
          aweme_interaction_list: {
            xData: res.data.aweme_interaction_list.map(i => {return i.principal_name}),
            yData: res.data.aweme_interaction_list.map(i => {return i.value})
          },
          live_interaction_list: {
            xData: res.data.live_interaction_list.map(i => {return i.principal_name}),
            yData: res.data.live_interaction_list.map(i => {return i.value})
          },
          interaction_total_list: {
            xData: res.data.interaction_total_list.map(i => {return i.principal_name}),
            yData: [res.data.interaction_total_list.map(i => {return i.share_total}), res.data.interaction_total_list.map(i => {return i.comment_total}), res.data.interaction_total_list.map(i => {return i.like_total})]
          },
          dealer_percent_list: res.data.dealer_percent_list.map(i => {return {name: i.principal_name, value: (i.percentage_value * 100).toFixed(2)}}),
          aweme_percent_list: res.data.aweme_percent_list.map(i => {return {name: i.principal_name, value: (i.percentage_value * 100).toFixed(2)}}),
          live_percent_list: res.data.live_percent_list.map(i => {return {name: i.principal_name, value: (i.percentage_value * 100).toFixed(2)}}),
          fans_percent_list: res.data.fans_percent_list.map(i => {return {name: i.principal_name, value: (i.percentage_value * 100).toFixed(2)}}),
        },
        msg: res.msg
      })
    })
  },
}
