import Nr from "@/utils/NetworldRequest";
const request = new Nr().getAxios();
const loginRequest = new Nr({ needTocken: false }).getAxios();
const casRequest = new Nr({ serviceName: "cas" }).getAxios();
const ossUploadRequest = new Nr({ serviceName: "agg" }).getAxios();
const centerRequest = new Nr({
  serviceName: "contentcenter",
  timeout: 1000 * 300,
}).getAxios();
const contentRequest = new Nr({ serviceName: "afanticontent" }).getAxios();

const aggRequest = new Nr({
  needTocken: false,
  serviceName: "agg",
}).getAxios();

const bffRequest = new Nr({ serviceName: "bff" }).getAxios();

export default {
  // 登录
  login(data) {
    return loginRequest({
      url: `/oauth/wxapt/token`,
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      method: "post",
      data,
    });
  },
  // 退出登录
  logOut() {
    return casRequest({
      url: `/ums/user/logout`,
      method: "get",
    });
  },
  // 获取路由
  getAuthority() {
    return casRequest({
      url: `/ums/user/authority`,
      method: "get",
      params: {
        client_module_id: 6,
      },
    });
  },
  // 获取新聚金塔路由
  getAuthorityNew() {
    return casRequest({
      url: `/ums/user/authority`,
      method: "get",
      params: {
        client_module_id: 18,
      },
    });
  },
  // 获取全站地图路由
  getAreaAuthority() {
    return casRequest({
      url: `/ums/user/authority`,
      method: "get",
      params: {
        client_module_id: 13,
      },
    });
  },
  // 获取主体
  getPrincipals() {
    return request({
      url: `/jjt/principal/principals`,
      method: "get",
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
    });
  },
  // 获取二维码
  getSweepCode() {
    return loginRequest({
      url: "/wx/qr/code/ticket",
      method: "get",
      baseURL: process.env.VUE_APP_WEIAPI,
      params: {
        appid: process.env.VUE_APP_WX_APPID,
      },
    });
  },
  // 获取oss上传权限
  getOssToken() {
    return ossUploadRequest({
      url: "/jjt/get-oss-token",
      method: "get",
    });
  },
  //上传文件
  uploadFile(params) {
    return centerRequest({
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      url: "/v1/file/upload-by-file",
      method: "post",
      data: params,
    });
  },
  // 获取上传点播视频凭证
  getUploadVideoConfig(data) {
    return contentRequest({
      url: "/v1/cms/vod/upload/config",
      method: "post",
      data,
    });
  },
  // 获取上传点播视频凭证
  getAuthUrl(params) {
    return contentRequest({
      url: "/v1/cms/vod/getAuthUrl",
      method: "get",
      params,
    });
  },
  // 获取飞书appid
  getLarkAppId(params) {
    return aggRequest({
      url: `/oauth/feishu/config`,
      method: "get",
      params,
    });
  },
  // 获取微信授权链接
  getWxAuthUrl(params) {
    return aggRequest({
      url: `/oauth/qywx/auth/getAuthUrl`,
      method: "get",
      params,
    });
  },
  // 获取区域用户信息
  getRegionUserInfo(params) {
    return bffRequest({
      url: `/regionVillageRole/regionUserInfo`,
      method: "get",
      params,
    });
  },
  // 获取主体平台配置
  getPlatformSetting(params) {
    return bffRequest({
      url: `/dict/platformSetting`,
      method: "get",
      params,
    });
  },
};
