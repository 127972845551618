import api from '@/api/activityReview'

const {
    getAreaList,
    getBasicInfo,
    getAwemeOverview,
    getAwemeRankList,
    getHotAweme,
    getLiveOverview,
    getLiveRankList,
    getHotLive,
    getDealerContribution,
    getDealerBest,
    getDealerTop,
} = api;

export default {
    namespaced: true,

    state: {
        regionData: [],
        basicInfoData: null,
        awemeOverviewData: null,
        awemeRankListData: [],
        hotAwemeData: null,
        liveOverviewData: null,
        liveRankListData: [],
        hotLiveData: null,
        dealerContributionData: null,
        dealerBestListData: [],
        dealerTopListData: null
    },

    actions: {
        // 经销商区域
        getAreaList({ commit }, payload) {
            return getAreaList({
            })
                .then((res) => {
                    commit('changeState', { name: 'regionData', data: res.data })
                })
        },
        //基础信息
        getBasicInfo({ commit }, payload) {
            let {
                params,
                platform
            } = payload
            return getBasicInfo({
                params,
                platform
            })
                .then((res) => {
                    commit('changeState', { name: 'basicInfoData', data: res.data || {} })
                })
        },
        // 视频概览
        getAwemeOverview({ commit }, payload) {
            let {
                params,
                platform
            } = payload
            return getAwemeOverview({
                params,
                platform
            })
                .then((res) => {
                    commit('changeState', { name: 'awemeOverviewData', data: res.data || {} })
                })
        },
        // 视频排行
        getAwemeRankList({ commit }, payload) {
            let {
                params,
                platform
            } = payload
            return getAwemeRankList({
                params,
                platform
            })
                .then((res) => {
                    commit('changeState', { name: 'awemeRankListData', data: res.data || [] })
                })
        },
        // 爆款视频
        getHotAweme({ commit }, payload) {
            let {
                params,
                platform
            } = payload
            return getHotAweme({
                params,
                platform
            })
                .then((res) => {
                    commit('changeState', { name: 'hotAwemeData', data: res.data || {} })
                })
        },

        // 直播概览
        getLiveOverview({ commit }, payload) {
            let {
                params,
                platform
            } = payload
            return getLiveOverview({
                params,
                platform
            })
                .then((res) => {
                    commit('changeState', { name: 'liveOverviewData', data: res.data || {} })
                })
        },
        // 直播排行
        getLiveRankList({ commit }, payload) {
            let {
                params,
                platform
            } = payload
            return getLiveRankList({
                params,
                platform
            })
                .then((res) => {
                    commit('changeState', { name: 'liveRankListData', data: res.data || [] })
                })
        },
        // 直播视频
        getHotLive({ commit }, payload) {
            let {
                params,
                platform
            } = payload
            return getHotLive({
                params,
                platform
            })
                .then((res) => {
                    commit('changeState', { name: 'hotLiveData', data: res.data || {} })
                })
        },

        // 经销商表现 - 贡献
        getDealerContribution({ commit }, payload) {
            let {
                params,
                platform
            } = payload
            return getDealerContribution({
                params,
                platform
            })
                .then((res) => {
                    commit('changeState', { name: 'dealerContributionData', data: res.data || {
                        aweme_play: [],
                        fans_increase: [],
                        live_watch: []
                    } })
                })
        },
        // 经销商表现 - 最佳门店
        getDealerBest({ commit }, payload) {
            let {
                params,
                platform
            } = payload
            return getDealerBest({
                params,
                platform
            })
                .then((res) => {
                    commit('changeState', { name: 'dealerBestListData', data: res.data || [] })
                })
        },
        //经销商表现 - 最佳
        getDealerTop({ commit }, payload) {
            let {
                params,
                platform
            } = payload
            return getDealerTop({
                params,
                platform
            })
                .then((res) => {
                    let data = {
                        best_aweme: res.data.best_aweme || {},
                        best_live: res.data.best_live || {},
                        increase_most_fans_live: res.data.increase_most_fans_live || {},
                        spread_fastest_aweme: res.data.spread_fastest_aweme || {},
                    }
                    commit('changeState', { name: 'dealerTopListData', data })
                })
        },
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}
