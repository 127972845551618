import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
export default {
  // 获取分组下门店
  getDealerList({
    group_code,
    keyword,
    current,
    page_size
  }) {
    return request({
      url: `/jjt/group-dealer/page`,
      method: 'get',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      params: {
        group_code,
        keyword,
        current,
        page_size
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 获取门店下关联账号
  getDealerAccount({
    dealer_id
  }) {
    return request({
      url: `/jjt/group-dealer/selectDealerAuthor`,
      method: 'get',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      params: {
        dealer_id
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 收藏门店操作
  editDealerFavorite({
    favorite,
    dealer_id,
    group_code
  }) {
    return request({
      url: `/jjt/group-dealer/save`,
      method: 'post',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      data: {
        favorite,
        dealer_id,
        group_code
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 选择分组
  getSortGroup(params){
      return request({
          url:'/jjt/group/selectAllGroup',
          method:'get',
          baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
          params,
      })
  },
  // 新建分组
  addShopGroup(data){
      return request({
          url:'/jjt/group',
          method:'post',
          baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
          data,
      })
  },
  // 收藏分组
  addCollectGrounp(data){
      return request({
          url:'/jjt/group/facoriteDealer',
          method:'post',
          baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
          data,
      })
  }
}
