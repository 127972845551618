import api from '@/api/storeSearch'

const {
  getStoreList,
  getAccountList,
  getAreaList,
  getSortGroup,
} = api;

export default {
  namespaced: true,

  state: {
    storeData: null,
    accountData: null,
    groupList: [],
    areaList: [],
    multipleData: []
  },

  actions: {
    // 门店查询
    getStoreList({ commit }, payload) {
      let {
        area_id,
        group_code,
        word,
        current,
        page_size,
        platform
      } = payload

      return getStoreList({
        area_id,
        group_code,
        word,
        current,
        page_size,
        platform
      })
        .then((res) => {
          console.log('getStoreList res', res)
          commit('changeState', { name: 'storeData', data: res.data })
        })
    },
    // 新建分组/复制分组
    getAccountList({ commit }, payload) {
      let {
        area_id,
        group_code,
        word,
        account_type,
        verification_type,
        current,
        page_size,
        platform
      } = payload

      return getAccountList({
        area_id,
        group_code,
        word,
        account_type,
        verification_type,
        current,
        page_size,
        platform
      })
        .then((res) => {
          console.log('getAccountList res', res)
          commit('changeState', { name: 'accountData', data: res.data })
        })
    },
    getAreaList({ commit }, payload) {
      commit('changeState', { name: 'areaList', data: [] })
      return getAreaList()
        .then((res) => {
          console.log('getAreaList res', res)
          commit('changeState', { name: 'areaList', data: res })
        })
    },

    getSortGroup({
      commit
    }) {
      return getSortGroup().then(res => {
        console.log('分组', res)
        commit('changeState', { name: 'groupList', data: res.data.data })
      })
    },
    // 相关经销商分组
    getDealerGroup({
      commit
    }, payload) {
      let { params } = payload;
      return getSortGroup(params).then(res => {
        commit('changeState', { name: 'multipleData', data: res.data.data })
      })
    },

  },

  mutations: {
    changeState(state, payload) {
      let {
        data,
        name
      } = payload;
      state[name] = data;
    }
  }
}
