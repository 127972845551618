import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
    return (new Nr).getBaseUrl(serviceName)
}
export default {
    // 获取区域
    getAreaList({
    }) {
        return request({
            url: `/jjt/dealer/region-list`,
            method: 'get',
            baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
        }).then(res => {
            res = res.data
            return res
        })
    },

    // 获取本品的竞品列表(品牌矩阵)
    getEnemyPrincipal({
        platform
    }) {
        return request({
            url: `/jjt/brand-matrix/enemyPrincipal`,
            method: 'get',
            baseURL: getBaseUrl(platform),
        }).then(res => {
            res = res.data
            return res
        })
    },


    // 竞品区域对比-本品
    getInfo({
        area_id,
        year,
        month,
        platform
    }) {
        return request({
            url: `/jjt/brand/info`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params: {
                area_id,
                year,
                month
            }
        }).then(res => {
            res = res.data
            return {
                code: res.code,
                data: {
                    ...res.data,
                    chartData1: res.data.account_list.map(item => {
                        return item.value
                    }),
                    chartData2: res.data.market_list.map(item => {
                        return item.value
                    }),
                    chartData3: res.data.sale_list.map(item => {
                        return item.value
                    })
                },
                msg: res.msg
            }
        })
    },
    // 竞品区域对比-竞品
    getInfoById({
        area_id,
        principal_id,
        year,
        month,
        platform
    }) {
        return request({
            url: `/jjt/brand/competitor/${principal_id}/info`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params: {
                area_id,
                year,
                month
            }
        }).then(res => {
            res = res.data
            return {
                code: res.code,
                data: {
                    ...res.data,
                    chartData1: res.data.account_list.map(item => {
                        return item.value
                    }),
                    chartData2: res.data.market_list.map(item => {
                        return item.value
                    }),
                    chartData3: res.data.sale_list.map(item => {
                        return item.value
                    })
                },
                msg: res.msg
            }
        })
    },
    // top10门店列表 - 本品
    getTopStoreList({
        platform,
        params
    }) {
        return request({
            url: `/jjt/brand/info/dealer/list`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params
        }).then(res => {
            return res
        })
    },
    // top10门店列表 - 竞品
    getTopStoreListById({
        platform,
        params
    }) {
        return request({
            url: `/jjt/brand/competitor/${params.principal_id}/info/list`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params
        }).then(res => {
            return res
        })
    },
    // 获取门店下账号列表
    getAccountList({
        dealer_id,
        platform
    }) {
        return request({
            url: `/jjt/dealers/${dealer_id}/account-list`,
            method: 'get',
            baseURL: getBaseUrl(platform),
        }).then(res => {
            return res
        })
    },
    // 获取账号详情
    getAccountInfo({
        author_id,
        platform
    }) {
        return request({
            url: `/jjt/dealers/${author_id}/info`,
            method: 'get',
            baseURL: getBaseUrl(platform),
        }).then(res => {
            return res
        })
    },
    // 获取视频、直播详情
    getLiveAndAweme({
        author_id,
        platform
    }) {
        return request({
            url: `/jjt/dealers/${author_id}/live-and-aweme`,
            method: 'get',
            baseURL: getBaseUrl(platform),
        }).then(res => {
            return res
        })
    },

    // 底部line chart - 粉丝增量
    getLineByFans({
        platform,
        params
    }) {
        return request({
            url: `/jjt/brand/fans/self/competitor/${params.principal_id}/info`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params
        }).then(res => {
            return res
        })
    },
    // 底部line chart - 短视频
    getLineByAweme({
        platform,
        params
    }) {
        return request({
            url: `/jjt/brand/aweme/self/competitor/${params.principal_id}/info`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params
        }).then(res => {
            return res
        })
    },
    // 底部line chart - 直播
    getLineByLive({
        platform,
        params
    }) {
        return request({
            url: `/jjt/brand/live/self/competitor/${params.principal_id}/info`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params
        }).then(res => {
            return res
        })
    },

}