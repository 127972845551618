import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
  // getDealerDetail({
  //   dealer_id
  // }) {
  //   return request({
  //     url: `/jjt/dealer/dealers/dealer-detail/${dealer_id}`,
  //     method: 'get',
  //     baseURL: process.env.VUE_APP_OPENDATA_AGG_API
  //   }).then(res => {
  //     res = res.data
  //     return ({
  //       code: res.code,
  //       data: res.data,
  //       msg: res.msg
  //     })
  //   })
  // },
  getAccounts({
    dealer_id
  }) {
    return request({
      url: `/jjt/dealer/dealers/dealer-accounts/${dealer_id}`,
      method: 'get',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API
    }).then(res => {
      res = res.data
      return ({
        code: res.code,
        data: res.data,
        msg: res.msg
      })
    })
  },
  getPlatformInfo({
    dealer_id
  }) {
    return request({
      url: `/jjt/dealer/dealers/dealer-platform-info/${dealer_id}`,
      method: 'get',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API
    }).then(res => {
      res = res.data
      return ({
        code: res.code,
        data: res.data,
        msg: res.msg
      })
    })
  },
  getDealerInfo({
    platform,
    dealerId
  }) {
    return request({
      url: `/jjt/dealers/region/dealer-info/${dealerId}`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        dealerId
      }
    }).then(res => {
      res = res.data
      return ({
        code: res.code,
        data: {
          ...res.data,
          xData: [res.data.aweme_count, res.data.live_count, res.data.fans_count]
        },
        msg: res.msg
      })
    })
  },
  getDealerStatistics({
    platform,
    dealerId
  }) {
    return request({
      url: `/jjt/dealers/region/dealer-statistics/${dealerId}`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        dealerId
      }
    }).then(res => {
      res = res.data
      return ({
        code: res.code,
        data: {
          day: res.data.day,
          content: {
            total: {
              aweme_count: res.data.aweme_count_total,
              aweme_effective: res.data.aweme_effective_total,
              live_count: res.data.live_count_total,
              live_effective: res.data.live_effective_total
            },
            increase: {
              aweme_count: res.data.aweme_count_increase,
              aweme_effective: res.data.aweme_effective_increase,
              live_count: res.data.live_count_increase,
              live_effective: res.data.live_effective_increase
            }
          },
          aweme: {
            total: {
              aweme_polling_count: res.data.aweme_polling_count_total,
              aweme_like_count: res.data.aweme_like_count_total,
              aweme_comment_count: res.data.aweme_comment_count_total,
              aweme_share_count: res.data.aweme_share_count_total
            },
            increase: {
              aweme_polling_count: res.data.aweme_polling_count_increase,
              aweme_like_count: res.data.aweme_like_count_increase,
              aweme_comment_count: res.data.aweme_comment_count_increase,
              aweme_share_count: res.data.aweme_share_count_increase
            }
          },
          live: {
            total: {
              live_polling: res.data.live_polling_count_total,
              live_like_count: res.data.live_like_count_total,
              live_comment_count: res.data.live_comment_count_total,
              live_fans: res.data.live_fans_total
            },
            increase: {
              live_polling: res.data.live_polling_count_increase,
              live_like_count: res.data.live_like_count_increase,
              live_comment_count: res.data.live_comment_count_increase,
              live_fans: res.data.live_fans_increase
            },
          },
          fans: {
            total: {
              fans_count: res.data.fans_count_total,
            },
            increase: {
              fans_count: res.data.fans_count_increase,
            }
          }
        },
        msg: res.msg
      })
    })
  },

  // ---------------------- 新版 经销商详情 接口 ----------------------
  // 作品表现
  getDealerWorks({
    params,
    platform
  }) {
    return request({
      url: `/jjt/dealers/production`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params
    })
  },
  // 作品表现
  getDealerDetail({
    params,
    platform
  }) {
    return request({
      url: `/jjt/dealers/detail`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params
    })
  },
  // 经销商门店下账号
  getDealerAuthors({
    params,
    platform
  }) {
    return request({
      url: `/jjt/dealers/detail/authors`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params
    })
  },

  // ---------------------- 最新版 2023-03-16 经销商详情 接口 ----------------------

  // 门店账号列表
  getDealerAuthorsList(params) {
    return request({
      url: `/jjt/dealer/detail/list`,
      method: 'get',
      baseURL: getBaseUrl('agg'),
      params
    })
  },
  // 门店详情
  getDealerDetailN(params) {
    return request({
      url: `/jjt/dealer/detail/${params.dealer_id}`,
      method: 'get',
      baseURL: getBaseUrl('agg')
    })
  },
}
