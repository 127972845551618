import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
  // 门店查询
  getStoreList({
    area_id,
    group_code,
    word,
    current,
    page_size,
    platform
  }) {
    return request({
      url: `/jjt/dealers/dealer/search/new`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        area_id,
        group_code,
        word,
        current,
        page_size
      }
    }).then(res => {
      res = res.data
      console.log(11, res)
      let newList = []
      if (res.data.list && res.data.list.length > 0) {
        res.data.list.forEach(item => {
          let newItem = item
          newItem.children = item.author_list || []
          newList.push(newItem)
        })
      }
      return {
        data: {
          list: newList,
          pagination: res.data.pagination
        },
        code: res.code,
        msg: res.msg
      }
    })
  },
  // 账号查询
  getAccountList({
    area_id,
    group_code,
    word,
    account_type,
    verification_type,
    current,
    page_size,
    platform
  }) {
    return request({
      url: `/jjt/dealers/dealer/search-account/new`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        area_id,
        group_code,
        word,
        account_type,
        verification_type,
        current,
        page_size
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 区域列表
  getAreaList() {
    return request({
      url: `/jjt/dealer/dealers/region-list`,
      method: 'get',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
    }).then(res => {
      let list = res.data.data.region_list || []
      if (res.data.data.type == 10) {
        list.unshift({
          id: '',
          name: '不限大区'
        })
      }
      list.map(item => {
        item.value = item.id
        item.label = item.name
      })
      return list
    })
  },
  // 选择分组
  getSortGroup(params) {
    return request({
      url: '/jjt/group/selectAllGroup',
      method: 'get',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      params,
    })
  },
  // 新建分组
  addShopGroup(data) {
    return request({
      url: '/jjt/group',
      method: 'post',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      data,
    })
  },
  // 收藏分组
  addCollectGrounp(data) {
    return request({
      url: '/jjt/group/facoriteDealer',
      method: 'post',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      data,
    })
  }
}
