import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
  // 获取列表
  getList({
    start_time,
    end_time,
    region_id,
    platform
  }) {

    return request({
      url: `/jjt/dealers/busi/data`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        start_time,
        end_time,
        region_id
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 获取活动列表
  getActivityData({
    start_time,
    end_time,
    group_code,
    platform
  }) {

    return request({
      url: `/jjt/dealers/busi/activity/data`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        start_time,
        end_time,
        group_code
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 短视频
  getAssessmentlist1({
    start_time,
    end_time,
    region_id,
    group_code,
    sort_field,
    sort,
    current,
    size,
    platform
  }) {
    return request({
      url: `/jjt/business/policy/assessment/aweme`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        start_time,
        end_time,
        region_id,
        group_code,
        sort_field,
        sort,
        current,
        size
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 直播
  getAssessmentlist2({
    start_time,
    end_time,
    region_id,
    group_code,
    sort_field,
    sort,
    current,
    size,
    platform
  }) {
    return request({
      url: `/jjt/business/policy/assessment/live`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        start_time,
        end_time,
        region_id,
        group_code,
        sort_field,
        sort,
        current,
        size
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 事件活动激励-视频
  getAssessmentlist3({
    start_time,
    end_time,
    region_id,
    activity_id,
    group_code,
    sort_field,
    sort,
    keyword,
    current,
    size,
    platform
  }) {
    return request({
      url: `/jjt/business/policy/assessment/event/activity/motivate/aweme`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        start_time,
        end_time,
        region_id,
        activity_id,
        group_code,
        sort_field,
        sort,
        keyword,
        current,
        size
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 事件活动激励-直播
  getAssessmentlist4({
    start_time,
    end_time,
    region_id,
    activity_id,
    group_code,
    sort_field,
    sort,
    keyword,
    current,
    size,
    platform
  }) {
    return request({
      url: `/jjt/business/policy/assessment/event/activity/motivate/live`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        start_time,
        end_time,
        region_id,
        activity_id,
        group_code,
        sort_field,
        sort,
        keyword,
        current,
        size
      }
    }).then(res => {
      res = res.data
      return res
    })
  },

  // 获取全部分组
  getAllGroup() {
    return request({
    url: `/jjt/group/selectAllGroup`,
    method: 'get',
    baseURL: process.env.VUE_APP_OPENDATA_AGG_API
    }).then(res => {
    res = res.data
    return res
    })
  },
  // 区域列表
  getAreaList(){
    return request({
      url:'/jjt/dealer/dealers/region-list',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      method:'get',
    })
  },
  // 活动列表
  getActivityList({
    platform
  }){
    return request({
      url:'/jjt/business/policy/assessment/activity-list',
      baseURL: getBaseUrl(platform),
      method:'get',
    })
  },
  // 考核结果
  getResultList({
    start_time,
    end_time,
    region_id,
    activity_id,
    group_code,
    sort_field,
    sort,
    current,
    size,
    platform,
    deadline
  }) {
    return request({
      url: `/jjt/business/policy/assessment/event/activity/motivate/aweme/result`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        start_time,
        end_time,
        region_id,
        activity_id,
        group_code,
        sort_field,
        sort,
        current,
        size,
        deadline
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
}
