import api from "@/api/liveRanking";
import tagApi from "@/api/illegalTag";

const {
  getAreaInfo,
  getAreaList,
  getAllGroup,
  getMonitorTag,
  getLiveList,
  getVideoList,
  getActivityList,
  getThawingRecord,
} = api;

export default {
  namespaced: true,

  state: {
    areaData: [],
    regionData: [],
    groupData: [],
    liveData: null,
    videoData: null,
    activityData: [],
    recordList: [],
    monitorTagList: [],
  },

  actions: {
    // 获取大区 省市
    getAreaInfo({ commit }, payload) {
      return getAreaInfo().then((res) => {
        console.log("getAreaInfo res", res);
        commit("changeState", { name: "areaData", data: res.data });
      });
    },
    // 经销商区域
    getAreaList({ commit }, payload) {
      return getAreaList({}).then((res) => {
        console.log("regionData", res);
        commit("changeState", { name: "regionData", data: res.data || {} });
      });
    },
    // 获取全部分组
    getAllGroup({ commit }, payload) {
      return getAllGroup().then((res) => {
        console.log("getAllGroup res", res);
        commit("changeState", { name: "groupData", data: res.data });
      });
    },
    getMonitorTag({ commit }, payload) {
      commit("changeState", { name: "monitorTagList", data: [] });
      return tagApi.getIllegalTag(payload).then((res) => {
        commit("changeState", { name: "monitorTagList", data: res.data.data });
      });
    },
    // 获取活动列表
    getActivityList({ commit }, payload) {
      let { platform } = payload;
      return getActivityList({
        platform,
      }).then((res) => {
        console.log("getActivityList res", res);
        commit("changeState", { name: "activityData", data: res.data });
      });
    },
    // 获取直播排行列表
    getLiveList({ commit }, payload) {
      let { params, platform } = payload;
      return getLiveList({
        params,
        platform,
      }).then((res) => {
        console.log("getLiveList res", res);
        commit("changeState", { name: "liveData", data: res.data });
      });
    },
    // 获取视频排行列表
    getVideoList({ commit }, payload) {
      let { params, platform } = payload;
      return getVideoList({
        params,
        platform,
      }).then((res) => {
        console.log("getVideoList res", res);
        commit("changeState", { name: "videoData", data: res.data });
      });
    },
    // 获取解锁记录列表
    getThawingRecord({ commit }, payload) {
      let {
        region_id,
        group_code,
        start_time,
        end_time,
        page_no,
        page_size,
        dealer_name_or_nickname_or_dealer_store_code,
        platform,
      } = payload;
      return getThawingRecord({
        region_id,
        group_code,
        start_time,
        end_time,
        page_no,
        page_size,
        dealer_name_or_nickname_or_dealer_store_code,
        platform,
      }).then((res) => {
        console.log("getThawingRecord res", res);
        commit("changeState", { name: "recordList", data: res.data });
      });
    },
  },

  mutations: {
    changeState(state, payload) {
      let { data, name } = payload;
      state[name] = data;
    },
  },
};
