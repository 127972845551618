import api from '@/api/production';

const {
    getAuthorMessage,
    getLiveMessage,
    getTopThirtyLive,
    getAwemeDetail,
    getCalendarLive
} = api;

export default {
    namespaced: true,

    state: {
        authorMessage: {},
        liveMessage: {},
        topThirtyList: [],
        awemeDetail: {},
        calendarData: {}
    },
    actions: {
        getAuthorMessage({
            commit
        }, paylod) {
            let {
                type,
                id
            } = paylod;

            return getAuthorMessage({
                id,
                type
            }).then(res => {
                    commit('changeState', {
                        name: 'authorMessage',
                        data: res.data
                    })
            }).catch(res => {
                console.log(res)
            })
        },
        getLiveMessage({
            commit
        }, payload) {
            let {
                type,
                params
            } = payload;


            return getLiveMessage({
                type,
                params
            }).then(res => {
                    commit('changeState', {
                        name: 'liveMessage',
                        data: res.data.data
                    })

            }).catch(res => {
                console.log(res)
            })
        },
        getTopThirtyLive({
            commit
        }, payload) {
            let {
                type,
                params
            } = payload;


            return getTopThirtyLive({
                type,
                params
            }).then(res => {
                    commit('changeState', {
                        name: 'topThirtyList',
                        data: res.data.data
                    })

            }).catch(res => {
                console.log(res)
            })
        },
        getAwemeDetail({
            commit
        }, payload) {
            let {
                type,
                params
            } = payload;


            return getAwemeDetail({
                type,
                params
            }).then(res => {
                    commit('changeState', {
                        name: 'awemeDetail',
                        data: res.data.data
                    })

            }).catch(res => {
                console.log(res)
            })
        },
        getCalendarLive({
            commit
        }, payload) {
            let {
                type,
                params
            } = payload;


            commit('changeState', {
                name: 'calenderLoading',
                data: true
            })
            return getCalendarLive({
                type,
                params
            }).then(res => {
                    commit('changeState', {
                        name: 'calendarData',
                        data: res.data.data
                    })
                    commit('changeState', {
                        name: 'calenderLoading',
                        data: false
                    })

            }).catch(res => {
                commit('changeState', {
                    name: 'calenderLoading',
                    data: false
                })
            })
        },
    },
    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}