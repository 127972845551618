import api from '@/api/matrixBenchmarking'

const {
    getBrandList,
    getInfoByPid,
    getMatrixData,
    getDateChart
} = api;

export default {
    namespaced: true,

    state: {
        brandList: [],
        infoData: {
            aweme_count_distributeds: [],
            dealer_infos: [],
            live_count_distributeds: [],
            weeks: [],
            xData: []
        },
        matrixData: {},
        dateData: []
    },

    actions: {
        getBrandList({ commit }, payload) {
            let {
                statistics_begin_date,
                statistics_end_date,
                platform
            } = payload
            commit('changeState', { name: 'brandList', data: [] })
            
            return getBrandList({
                statistics_begin_date,
                statistics_end_date,
                platform
            })
                .then((res) => {
                    console.log('getBrandList res', res)
                    commit('changeState', { name: 'brandList', data: res.data || [] })
                })
        },
        getInfoByPid({ commit }, payload) {
            let {
                statistics_begin_date,
                statistics_end_date,
                principal_id,
                platform
            } = payload
            
            return getInfoByPid({
                statistics_begin_date,
                statistics_end_date,
                principal_id,
                platform
            })
                .then((res) => {
                    console.log('getInfoByPid res', res)
                    commit('changeState', { name: 'infoData', data: res.data })
                })
        },
        getMatrixData({ commit }, payload) {
            let {
                year,
                month,
                platform
            } = payload
            
            return getMatrixData({
                year,
                month,
                platform
            })
                .then((res) => {
                    console.log('getMatrixData res', res)
                    commit('changeState', { name: 'matrixData', data: res.data })
                })
        },
        getDateChart({ commit }, payload) {
            let {
                platform
            } = payload
            
            return getDateChart({
                platform
            })
                .then((res) => {
                    console.log('getDateChart res', res)
                    commit('changeState', { name: 'dateData', data: res.data })
                })
        },
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}
