import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default{
    // 1:直播排行 ,2:视频排行,
    getLiveSort({
        params,type
    }){
        return request({
            url: type.liveOrVideo=='1'?'/jjt/dealers/live/top':'/jjt/dealers/aweme/top',
            method: 'get',
            baseURL: getBaseUrl(type.terrace),
            params,
        })
    },
    // 选择分组
    getSortGroup(params){
        return request({
            url:'/jjt/group/selectAllGroup',
            method:'get',
            baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
            params,
        })
    },
    // 新建分组
    addShopGroup(data){
        return request({
            url:'/jjt/group',
            method:'post',
            baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
            data,
        })
    },
    // 收藏分组
    addCollectGrounp(data){
        return request({
            url:'/jjt/group/facoriteDealer',
            method:'post',
            baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
            data,
        })
    },
}