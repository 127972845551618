import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
export default {
    // 新建分组/复制分组
    addGroup({
      group_code,
      name,
      new_or_copy
    }) {
      return request({
        url: `/jjt/group`,
        method: 'post',
        baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
        data: {
          group_code,
          name,
          new_or_copy
        }
      }).then(res => {
        res = res.data
        return res
      })
    },
    // 查询分组
    getGroup({
        current,
        page_size
    }) {
      return request({
        url: `/jjt/group`,
        method: 'get',
        baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
        params: {
            current,
            page_size
        }
      }).then(res => {
        res = res.data
        return res
      })
    },
    // 修改分组名字
    editGroupName({
      id,
      name
    }) {
      return request({
        url: `/jjt/group/editName`,
        method: 'post',
        baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
        data: {
            id,
            name
        }
      }).then(res => {
        res = res.data
        return res
      })
    },
    // 删除分组
    deleteGroup({
      group_id
    }) {
      return request({
        url: `/jjt/group/${group_id}`,
        method: 'delete',
        baseURL: process.env.VUE_APP_OPENDATA_AGG_API
      }).then(res => {
        res = res.data
        return res
      })
    },
    // 批量收藏
    addBatchDealer({
      group_code,
      dealers
    }) {
      return request({
        url: `/jjt/group/batch-dealer`,
        method: 'post',
        baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
        data: {
          group_code,
          dealers
        }
      }).then(res => {
        res = res.data
        return res
      })
    },
}
