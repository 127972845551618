import api from '@/api/brandMatrix'

const {
    getBrandList,
    getMatrixData
} = api;

export default {
    namespaced: true,

    state: {
        brandList: [],
        matrixData: {}
    },

    actions: {
        // 获取品牌列表
        getBrandList({ commit }, payload) {
            let {
                platform
            } = payload
            
            return getBrandList({
                platform
            })
                .then((res) => {
                    console.log('getbrandList res', res)
                    commit('changeState', { name: 'brandList', data: res.data })
                })
        },
        getMatrixData({ commit }, payload) {
            let {
                principal_ids,
                platform
            } = payload
            
            return getMatrixData({
                principal_ids,
                platform
            })
                .then((res) => {
                    console.log('getMatrixData res', res)
                    commit('changeState', { name: 'matrixData', data: res.data })
                })
        },
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}
