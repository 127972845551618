import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
  // 车系表现列表
  getSeriesList({
    start_time,
    end_time,
    platform
  }) {
    return request({
      url: `/jjt/car/series/manufactor/series-interaction-detail`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        start_time,
        end_time
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 查询竞品信息
  getCompetitorById({
    start_time,
    end_time,
    series_id,
    platform
  }) {
    return request({
      url: `/jjt/car/series/series-ranking`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        start_time,
        end_time,
        series_id
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
}
