import api from "@/api/areaPerspective";
const {
  getAreaList,
  getVideoTabTable,
  getVideoTabTableMulti,
  getLiveTabTable,
  getLiveTabTableMulti,
  getDealerList,
} = api;
import { message } from 'ant-design-vue'

export default {
  namespaced: true,

  state: {
    videoTabLoading: false,
    areaList: [],
    areaVideoData: {},
    areaLiveData: {},
    liveTabLoading: false,
    tableLoading: false,
    dealerTopList: [],
  },

  actions: {
    // 获取区域
    getAreaList({ commit }) {
      return getAreaList().then((res) => {
        if (res.data.code == 0) {
          let arr = [];
          res.data.data.region_list.forEach((element) => {
            let value = {
              value: element.id,
              label: element.name,
            };
            arr.push(value);
          });
          commit("changeState", {
            name: "areaList",
            data: {
              region_list: arr,
              type: res.data.data.type,
            },
          });
        }
      });
    },
    // 短视频图表
    getVideoTabTable({ commit }, payload) {
      let { type, params } = payload;
      commit("changeState", { name: "videoTabLoading", data: true });
      return getVideoTabTableMulti({ params, type })
        .then((res) => {
          if (res.data.code == 0) {
            commit("changeState", {
              name: "areaVideoData",
              data: res.data.data,
            });
          } else {
            message.warning(res.data.msg);
            commit("changeState", {
              name: "areaVideoData",
              data: [
                {
                  chart: {
                    day: [],
                    aweme_total: [],
                    aweme_dealer_avg: [],
                    aweme_play_avg: [],
                    aweme_like_play_rate: [],
                    aweme_like_comment_rate: [],
                    aweme_like_share_rate: [],
                    aweme_activation: [],
                  },
                },
              ],
            });
          }
          commit("changeState", { name: "videoTabLoading", data: false });
        })
        .catch(() => {
          commit("changeState", { name: "videoTabLoading", data: false });
        });
    },
    // 直播图表
    getLiveTabTable({ commit }, payload) {
      let { type, params } = payload;
      commit("changeState", { name: "liveTabLoading", data: true });
      return getLiveTabTableMulti({ params, type })
        .then((res) => {
          if (res.data.code == 0) {
            commit("changeState", {
              name: "areaLiveData",
              data: res.data.data,
            });
          } else {
            message.warning(res.data.msg);
            commit("changeState", {
              name: "areaLiveData",
              data: [
                {
                  chart: {
                    day: [],
                    aweme_total: [],
                    aweme_dealer_avg: [],
                    aweme_play_avg: [],
                    aweme_like_play_rate: [],
                    aweme_like_comment_rate: [],
                    aweme_like_share_rate: [],
                    aweme_activation: [],
                  },
                },
              ],
            });
          }
          commit("changeState", { name: "liveTabLoading", data: false });
        })
        .catch(() => {
          commit("changeState", { name: "liveTabLoading", data: false });
        });
    },
    // top5门店
    getDealerList({ commit }, payload) {
      let { params, type } = payload;
      commit("changeState", { name: "tableLoading", data: true });
      return getDealerList({ type, params })
        .then((res) => {
          if (res.data.code == 0) {
            res.data.data.forEach((item) => {
              if (item.live_total_time || item.live_total_time == 0) {
                var minutes = parseInt(item.live_total_time / 60);
                // var seconds = (item.live_total_time % 60);
                let value = "0分";
                value = minutes + "分";
                item.live_total_time = value;
              } else {
                item.live_total_time = "-";
              }
            });
            commit("changeState", {
              name: "dealerTopList",
              data: res.data.data,
            });
          }
          commit("changeState", { name: "tableLoading", data: false });
        })
        .catch(() => {
          commit("changeState", { name: "tableLoading", data: false });
        });
    },
  },

  mutations: {
    changeState(state, payload) {
      let { data, name } = payload;
      state[name] = data;
    },
  },
};
