import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
  // 获取大区 省市
  getAreaInfo() {
    return request({
      url: `/jjt/dealer/dealers/area-info`,
      method: 'get',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 获取区域
  getAreaList({
  }) {
      return request({
          url: `/jjt/dealer/region-list`,
          method: 'get',
          baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      }).then(res => {
          res = res.data
          return res
      })
  },
  // 获取全部分组
  getAllGroup() {
    return request({
      url: `/jjt/group/selectAllGroup`,
      method: 'get',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 获取活动列表
  getActivityList({
    platform
  }) {
    return request({
      url: `/jjt/business/policy/assessment/activity-list`,
      method: 'get',
      baseURL: getBaseUrl(platform)
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 获取直播排行列表
  getLiveList({
    params,
    platform
  }) {
    return request({
      url: `/jjt/live/top`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 获取视频排行列表
  getVideoList({
    params,
    platform
  }) {
    return request({
      url: `/jjt/aweme/top`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 获取解锁记录列表
  getThawingRecord({
    region_id,
    group_code,
    start_time,
    end_time,
    page_no,
    page_size,
    dealer_name_or_nickname_or_dealer_store_code,
    platform
  }) {
    return request({
      url: `/jjt/brand/live-video-restore/list`,
      method: 'get',
      baseURL: getBaseUrl('agg'),
      params: {
        region_id,
        group_code,
        start_time,
        end_time,
        page_no,
        page_size,
        dealer_name_or_nickname_or_dealer_store_code,
        platform
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 获取全部违规标签
  getMonitorTag({
    type
  }) {
    return request({
      url: `/jjt/monitor/tag`,
      method: 'get',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      params: {
        type
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
}
