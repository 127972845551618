import { hasAuthority } from "@/utils/authority-utils";
import { loginIgnore } from "@/router/index";
import { checkAuthorization } from "@/utils/request";
import NProgress from "nprogress";
import { trackRequest, trackEvent } from "@/utils/track";
import { setTrackPath, getTrackPath } from "@/utils/auth";
import { initRouteMap } from "@/utils/routerUtil.js";

NProgress.configure({ showSpinner: false });

/**
 * 进度条开始
 * @param to
 * @param form
 * @param next
 */
const progressStart = (to, from, next) => {
  // start progress bar
  if (!NProgress.isStarted()) {
    NProgress.start();
  }
  next();
};

/**
 * 登录守卫
 * @param to
 * @param form
 * @param next
 * @param options
 */
const loginGuard = (to, from, next, options) => {
  const { message } = options;
  if (!loginIgnore.includes(to) && !checkAuthorization()) {
    message.warning("登录已失效，请重新登录");
    next({ path: "/login" });
  } else {
    next();
  }
};

/**
 * 权限守卫
 * @param to
 * @param form
 * @param next
 * @param options
 */
const authorityGuard = (to, from, next, options) => {
  // 本地开发不触发路由守卫
  if (process.env.IS_LOCAL) {
    next();
    return;
  }
  const { store, message } = options;
  const permissions = store.getters["account/permissions"];
  const roles = store.getters["account/roles"];
  if (!hasAuthority(to, permissions, roles)) {
    message.warning(`对不起，您无权访问页面: ${to.fullPath}，请联系管理员`);
    next({ path: "/403" });
    // NProgress.done()
  } else {
    next();
  }
};

/**
 * 混合导航模式下一级菜单跳转重定向
 * @param to
 * @param from
 * @param next
 * @param options
 * @returns {*}
 */
const redirectGuard = (to, from, next, options) => {
  const { store } = options;
  const getFirstChild = (routes) => {
    const route = routes[0];
    if (!route.children || route.children.length === 0) {
      return route;
    }
    return getFirstChild(route.children);
  };
  // if (store.state.setting.layout === 'mix') {
  //   const firstMenu = store.getters['setting/firstMenu']
  //   if (firstMenu.find(item => item.fullPath === to.fullPath)) {
  //     store.commit('setting/setActivatedFirst', to.fullPath)
  //     const subMenu = store.getters['setting/subMenu']
  //     if (subMenu.length > 0) {
  //       const redirect = getFirstChild(subMenu)
  //       return next({path: redirect.fullPath})
  //     }
  //   }
  // }
  next();
};
// 页面埋点
const analyticsRequest = (to, from, next, options) => {
  if (to.path == "/login") {
    next();
    return false;
  }
  if (!window.routeMap) {
    window.routeMap = {};
    try {
      window.routeMap = initRouteMap(JSON.parse(localStorage["menuList"]), {});
    } catch (error) {
      console.log(error);
    }
  }
  // 只统计页面跳转数据，不统计当前页 query 不同的数据
  // 所以这里只使用了 path, 如果需要统计 query 的，可以使用 to.fullPath
  if (to.path !== from.path && !to.path.includes("/jjt-new/")) {
    const fromPath =
      getTrackPath()["page_path"] === to.path
        ? getTrackPath()["refer_page_path"] || from.path
        : from.path;
    const fromName = window.routeMap
      ? window.routeMap[fromPath] || from.name
      : from.name;
    trackRequest(to, trackEvent.PAGE_VIEW, {
      refer_page_path: fromPath,
      refer_page_name: fromName,
    });
  }
  if (to.path.includes("/jjt-new/")) {
    setTrackPath({
      page_path: to.path,
      refer_page_path: from.path,
    });
    return;
  }
  next();
};

/**
 * 进度条结束
 * @param to
 * @param form
 * @param options
 */
const progressDone = () => {
  // finish progress bar
  NProgress.done();
};

export default {
  beforeEach: [progressStart, authorityGuard, redirectGuard, analyticsRequest],
  afterEach: [progressDone],
};
