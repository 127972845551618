import api from '@/api/matrixSpread'

const {
    getMatrixData,
    getDateChart
} = api;

export default {
    namespaced: true,

    state: {
        matrixData: {},
        dateData: []
    },

    actions: {
        getMatrixData({ commit }, payload) {
            let {
                year,
                month,
                platform
            } = payload
            
            return getMatrixData({
                year,
                month,
                platform
            })
                .then((res) => {
                    console.log('getMatrixData res', res)
                    commit('changeState', { name: 'matrixData', data: res.data })
                })
        },
        getDateChart({ commit }, payload) {
            let {
                platform
            } = payload
            
            return getDateChart({
                platform
            })
                .then((res) => {
                    console.log('getDateChart res', res)
                    commit('changeState', { name: 'dateData', data: res.data })
                })
        },
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}
