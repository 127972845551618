// 视图组件
const view = {
  tabs: () => import("@/layouts/tabs"),
  blank: () => import("@/layouts/BlankView"),
  page: () => import("@/layouts/PageView"),
};

// 路由组件注册
const routerMap = {
  login: {
    authority: "*",
    name: "登录",
    path: "/login",
    component: () => import("@/pages/login"),
  },
  icon: {
    name: "icon",
    path: "icons",
    component: () => import("@/pages/SvgIcons/index"),
  },
  root: {
    path: "/",
    name: "登录",
    redirect: "/login",
    component: view.tabs,
  },
  exp403: {
    authority: "*",
    name: "exp403",
    path: "403",
    component: () => import("@/pages/exception/403"),
  },
  exp404: {
    name: "exp404",
    path: "404",
    component: () => import("@/pages/exception/404"),
  },
  exp500: {
    name: "exp500",
    path: "500",
    component: () => import("@/pages/exception/500"),
  },
  matrix: {
    name: "矩阵管理",
    icon: "form",
    component: view.page,
  },
  matrixNew: {
    name: "矩阵管理",
    icon: "form",
    component: view.page,
  },
  matrixInfo: {
    name: "矩阵信息收录",
    icon: "form",
    component: view.page,
  },
  clue: {
    name: "线索中心",
    icon: "form",
    component: view.page,
  },
  report: {
    name: "报告&行业咨询",
    icon: "form",
    component: view.page,
  },
  compliance: {
    name: "合规管理",
    icon: "form",
    component: view.page,
  },
  area: {
    name: "area",
    icon: "form",
    component: view.page,
  },
  brandSummary: {
    path: "brandSummary",
    name: "品牌概览",
    icon: "form",
    component: () => import("@/pages/brandSummary/brandSummary"),
  },
  brandMatrix: {
    path: "brandMatrix",
    name: "品牌矩阵",
    icon: "form",
    component: () => import("@/pages/brandMatrix/brandMatrix"),
  },
  matrixPerspective: {
    path: "matrixPerspective",
    name: "矩阵透视",
    icon: "form",
    component: () => import("@/pages/matrixPerspective/matrixPerspective"),
  },
  matrixDetail: {
    path: "matrixDetail",
    name: "矩阵详情",
    icon: "form",
    component: () => import("@/pages/matrixDetail/matrixDetail"),
  },
  matrixSpread: {
    path: "matrixSpread",
    name: "矩阵传播价值",
    icon: "form",
    component: () => import("@/pages/matrixSpread/matrixSpread"),
  },
  matrixBenchmarking: {
    path: "matrixBenchmarking",
    name: "经销商矩阵对标",
    icon: "form",
    component: () => import("@/pages/matrixBenchmarking/matrixBenchmarking"),
  },
  matrixChangeMonitoring: {
    path: "matrixChangeMonitoring",
    name: "矩阵异动",
    icon: "form",
    component: () =>
      import("@/pages/matrixChangeMonitoring/matrixChangeMonitoring"),
  },
  matrixChangeMonitoringN: {
    path: "matrixChangeMonitoringN",
    name: "矩阵异动监测",
    icon: "form",
    component: () =>
      import("@/pages/matrixChangeMonitoring/matrixChangeMonitoringN"),
  },
  model: {
    name: "车系/车型",
    icon: "form",
    component: view.page,
  },
  modelPerformance: {
    path: "modelPerformance",
    name: "车系表现",
    icon: "form",
    component: () => import("@/pages/modelPerformance/modelPerformance"),
  },
  modelDetail: {
    path: "modelDetail",
    name: "车系详情",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/modelDetail/modelDetail"),
  },
  modelPerformanceN: {
    path: "modelPerformanceN",
    name: "车系表现(灰度)",
    icon: "form",
    component: () => import("@/pages/modelPerformanceN/modelPerformanceN"),
  },
  modelDetailN: {
    path: "modelDetailN",
    name: "车系详情(灰度)",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/modelDetailN/modelDetailN"),
  },
  modelTopicDetail: {
    path: "modelTopicDetail",
    name: "车系话题详情",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/modelDetailN/modelTopicDetail"),
  },
  brand: {
    name: "行业影响力",
    icon: "form",
    component: view.page,
  },
  influence: {
    path: "/brand/influence",
    name: "品牌影响力",
    icon: "form",
    component: () => import("@/pages/brand/Influence"),
  },
  influenceDetail: {
    path: "/influenceDetail",
    name: "品牌影响力详情",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/brand/InfluenceDetail"),
  },
  liveList: {
    path: "/brand/liveList",
    name: "品牌直播榜",
    icon: "form",
    component: () => import("@/pages/brand/LiveList"),
  },
  videoList: {
    path: "/brand/videoList",
    name: "品牌视频榜",
    icon: "form",
    component: () => import("@/pages/brand/VideoList"),
  },
  performance: {
    path: "/brand/performance",
    name: "行业平均表现",
    icon: "form",
    component: () => import("@/pages/brand/Performance"),
  },
  benchmarking: {
    path: "/brand/benchmarking",
    name: "品牌官号运营对标",
    icon: "form",
    component: () => import("@/pages/brand/Benchmarking"),
  },
  focusOnStores: {
    path: "/brand/focusOnStores",
    name: "重点关注门店",
    icon: "form",
    component: () => import("@/pages/brand/focusOnStores"),
  },
  worksList: {
    path: "/brand/worksList",
    name: "品牌官号作品榜",
    icon: "form",
    component: () => import("@/pages/brand/WorksList"),
  },
  dealerWorksList: {
    path: "/brand/dealerWorksList",
    name: "品牌经销商作品榜",
    icon: "form",
    component: () => import("@/pages/brand/DealerWorksList"),
  },
  generalList: {
    path: "/brand/generalList",
    name: "品牌总榜",
    icon: "form",
    component: () => import("@/pages/brand/GeneralList"),
  },
  myRanking: {
    path: "myRanking",
    name: "本品排名",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/brand/myRanking"),
  },
  industryTop: {
    path: "/brand/industryTop",
    name: "行业百强榜",
    icon: "form",
    component: () => import("@/pages/brand/industryTop"),
  },
  topic: {
    name: "直播地图",
    icon: "form",
    component: view.page,
  },
  liveArea: {
    path: "/topic/liveArea",
    name: "经销商直播(旧版)",
    icon: "form",
    component: () => import("@/pages/liveArea/liveArea"),
  },
  liveAreaN: {
    path: "/topic/liveAreaN",
    name: "经销商直播",
    icon: "form",
    component: () => import("@/pages/liveArea/liveAreaN"),
  },
  calendar: {
    path: "calendar",
    name: "营销日历",
    icon: "form",
    component: () => import("@/pages/topics/calendar"),
  },
  brandCalendar: {
    path: "brandCalendar",
    name: "品牌官号直播",
    icon: "form",
    component: () => import("@/pages/topics/brandCalendar"),
  },
  observe: {
    path: "observe",
    name: "话题观察",
    icon: "form",
    component: () => import("@/pages/topics/observe"),
  },
  topicDetail: {
    path: "topicDetail",
    name: "话题观察",
    invisible: true,
    component: () => import("@/pages/topics/observe/detail"),
  },
  dealer: {
    name: "经销商管理",
    icon: "form",
    component: view.page,
  },
  dealerOfOneself: {
    path: "dealerOfOneself",
    name: "经销商概览",
    icon: "form",
    component: () => import("@/pages/dealerOfOneself/dealerOfOneself"),
  },
  storeTrend: {
    path: "storeTrend",
    name: "门店趋势统计",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/storeTrend/storeTrend"),
  },
  regionalPerspective: {
    path: "regionalPerspective",
    name: "大区透视",
    icon: "form",
    component: () => import("@/pages/regionalPerspective/regionalPerspective"),
  },
  dealerDetail: {
    path: "dealerDetail",
    name: "经销商详情",
    invisible: true,
    component: () => import("@/pages/dealerDetail/dealerDetail"),
  },
  dealerDetailN: {
    path: "dealerDetailN",
    name: "经销商详情(灰度)",
    invisible: true,
    component: () => import("@/pages/dealerDetail/dealerDetailN"),
  },
  storeRanking: {
    path: "storeRanking",
    name: "门店排行",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/storeRanking/storeRanking"),
  },
  storeSearch: {
    path: "storeSearch",
    name: "门店查询",
    icon: "form",
    component: () => import("@/pages/storeSearch/storeSearch"),
  },
  storeCompetition: {
    path: "storeCompetition",
    name: "门店统计",
    icon: "form",
    component: () => import("@/pages/storeCompetition/storeCompetition"),
  },
  storeCollection: {
    path: "storeCollection",
    name: "门店收藏",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/storeCollection/storeCollection"),
  },
  assessment: {
    path: "assessment",
    name: "商务政策考核",
    icon: "form",
    component: () => import("@/pages/assessment/assessment"),
  },
  assessmentResult: {
    path: "assessmentResult",
    name: "考核结果",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/assessment/assessmentResult"),
  },
  businessPolicyOverview: {
    path: "businessPolicyOverview",
    name: "商务政策概览",
    icon: "form",
    component: () =>
      import("@/pages/businessPolicyOverview/businessPolicyOverview"),
  },
  storePolicy: {
    path: "storePolicy",
    name: "门店商务政策",
    icon: "form",
    component: () => import("@/pages/storePolicy/storePolicy"),
  },
  dealerKPI: {
    path: "dealerKPI",
    name: "经销商KPI",
    icon: "form",
    component: () => import("@/pages/dealerKPI/dealerKPI"),
  },
  activityManagement: {
    path: "activityManagement",
    name: "活动管理",
    icon: "form",
    component: () => import("@/pages/activityManagement/activityManagement"),
  },
  activityReview: {
    path: "activityReview",
    name: "活动复盘",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/activityReview/activityReview"),
  },
  activityList: {
    path: "activityList",
    name: "活动榜单(旧)",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/activityList/activityList"),
  },
  closedLoop: {
    path: "closedLoop",
    name: "闭环管理",
    icon: "form",
    component: () => import("@/pages/closedLoop/closedLoop"),
  },
  knowledge: {
    path: "knowledge",
    name: "知识库",
    icon: "form",
    component: () => import("@/pages/knowledge/knowledge"),
  },
  leadManagement: {
    path: "leadManagement",
    name: "线索管理",
    icon: "form",
    component: () => import("@/pages/leadManagement/leadManagement"),
  },
  modelClue: {
    path: "/modelClue",
    name: "车型线索统计",
    icon: "form",
    component: () => import("@/pages/modelClue/modelClue"),
  },
  activityClosedLoop: {
    path: "activityClosedLoop",
    name: "活动闭环管理",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/activityClosedLoop/activityClosedLoop"),
  },
  storeCollectionDetail: {
    path: "storeCollectionDetail",
    name: "门店收藏详情",
    icon: "form",
    invisible: true,
    component: () =>
      import("@/pages/storeCollectionDetail/storeCollectionDetail"),
  },
  production: {
    path: "production",
    name: "账号详情",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/production/production"),
  },
  productionN: {
    path: "productionN",
    name: "账号详情(灰度)",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/production/productionN"),
  },
  storeManagement: {
    path: "storeManagement",
    name: "门店信息管理",
    icon: "form",
    component: () => import("@/pages/storeManagement/storeManagement"),
  },
  regionContrast: {
    path: "regionContrast",
    name: "竞品区域对比",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/regionContrast/regionContrast"),
  },
  regionalStores: {
    path: "regionalStores",
    name: "区域标杆门店",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/regionalStores/regionalStores"),
  },
  hotLiveList: {
    path: "hotLiveList",
    name: "每周热榜直播",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/hotList/hotLiveList"),
  },
  hotVideoList: {
    path: "hotVideoList",
    name: "每周热榜视频",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/hotList/hotVideoList"),
  },
  accountAuthorization: {
    path: "accountAuthorization",
    name: "账号授权管理",
    icon: "form",
    invisible: true,
    component: () =>
      import("@/pages/accountAuthorization/accountAuthorization"),
  },
  activityScreen: {
    path: "activityScreen",
    name: "活动大屏",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/activityScreen/activityScreen"),
  },
  activityScreenN: {
    path: "activityScreenN",
    name: "直播大屏",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/activityScreenN/activityScreenN"),
  },
  liveAndVideo: {
    name: "直播&短视频",
    icon: "form",
    component: view.page,
  },
  realTimeLive: {
    path: "/liveAndVideo/realTimeLive",
    name: "realTimeLive",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/fullScreen/live"),
  },
  liveRanking: {
    path: "/liveAndVideo/liveRanking",
    name: "直播排行",
    icon: "form",
    component: () => import("@/pages/liveRanking/liveRanking"),
  },
  videoRanking: {
    path: "/liveAndVideo/videoRanking",
    name: "视频排行",
    icon: "form",
    component: () => import("@/pages/videoRanking/videoRanking"),
  },
  thawingRecord: {
    path: "/liveAndVideo/thawingRecord",
    name: "解冻记录",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/thawingRecord/thawingRecord"),
  },
  depthInspection: {
    name: "深度质检",
    icon: "form",
    component: view.page,
  },
  videoMonitoring: {
    path: "videoMonitoring",
    name: "短视频违规监测",
    icon: "form",
    component: () => import("@/pages/videoMonitoring/videoMonitoring"),
  },
  hangUpMonitoring: {
    path: "hangUpMonitoring",
    name: "直播违规监测",
    icon: "form",
    component: () => import("@/pages/hangUpMonitoring/hangUpMonitoring"),
  },
  storeRenovation: {
    path: "storeRenovation",
    name: "门店装修质检",
    icon: "form",
    component: () => import("@/pages/storeRenovation/storeRenovation"),
  },
  bulletinBoard: {
    name: "总看板",
    icon: "form",
    component: view.page,
  },
  bulletinBoardIndex: {
    path: "/bulletinBoard/index",
    name: "总看板",
    icon: "form",
    component: () => import("@/pages/bulletinBoard/index"),
  },
  regionHomeIndex: {
    path: "/bulletinBoard/region",
    name: "大区首页",
    icon: "form",
    component: () => import("@/pages/regionHome/index"),
  },
  sales: {
    name: "销售管理",
    icon: "form",
    component: view.page,
  },
  componentsMarket: {
    path: "/sales/componentsMarket",
    name: "组件大盘",
    icon: "form",
    component: () => import("@/pages/sales/componentsMarket/componentsMarket"),
  },
  clueMarket: {
    path: "/clue/sales/clueMarket",
    name: "线索大盘",
    icon: "form",
    component: () => import("@/pages/sales/clueMarket/clueMarket"),
  },
  conversionMarket: {
    path: "/sales/conversionMarket",
    name: "转化大盘",
    icon: "form",
    component: () => import("@/pages/sales/conversionMarket/conversionMarket"),
  },
  marketing: {
    name: "营销中心",
    icon: "form",
    component: view.page,
  },
  activityOverview: {
    path: "/marketing/activityOverview",
    name: "活动概况",
    icon: "form",
    component: () =>
      import("@/pages/marketing/activityOverview/activityOverview"),
  },
  dailyAchievements: {
    path: "/marketing/dailyAchievements",
    name: "每日战报",
    icon: "form",
    component: () =>
      import("@/pages/marketing/dailyAchievements/dailyAchievements"),
  },
  taskCenter: {
    path: "/marketing/taskCenter",
    name: "任务中心",
    icon: "form",
    invisible: true,
    component: () => import("@/pages/marketing/taskCenter/taskCenter"),
  },
  activityRanking: {
    path: "/marketing/activityRanking",
    name: "活动榜单",
    icon: "form",
    component: () =>
      import("@/pages/marketing/activityRanking/activityRanking"),
  },
  nActivityList: {
    path: "/marketing/nActivityList",
    name: "活动列表",
    // invisible: true,
    icon: "form",
    component: () => import("@/pages/marketing/nActivityList/nActivityList"),
  },
  activityStatistics: {
    path: "/marketing/activityStatistics",
    name: "活动数据",
    icon: "form",
    component: () =>
      import("@/pages/marketing/activityStatistics/activityStatistics"),
  },
  activityRegionProgress: {
    path: "/marketing/activityRegionProgress",
    name: "活动区域进度",
    icon: "form",
    component: () =>
      import("@/pages/marketing/activityRegionProgress/activityRegionProgress"),
  },
  taskProgress: {
    path: "/marketing/taskProgress",
    name: "任务进度",
    icon: "form",
    component: () => import("@/pages/marketing/taskProgress/taskProgress"),
  },
  activityReport: {
    path: "/marketing/activityReport",
    name: "报表中心",
    icon: "form",
    component: () => import("@/pages/marketing/activityReport/activityReport"),
  },
  // 公用详情页
  videoDetail: {
    path: "/videoDetail",
    name: "视频详情",
    invisible: true,
    component: () => import("@/pages/videoDetail/videoDetail"),
  },
  liveDetail: {
    path: "/liveDetail",
    name: "直播详情",
    invisible: true,
    component: () => import("@/pages/liveDetail/liveDetail"),
  },
  intentionAnalysis: {
    path: "/intentionAnalysis",
    name: "智能意向分析",
    invisible: true,
    component: () => import("@/pages/liveDetail/intentionAnalysis"),
  },
  liveAssistant: {
    path: "/liveAssistant",
    name: "智能复盘助手",
    invisible: true,
    component: () => import("@/pages/liveDetail/liveAssistant"),
  },
  effectDetailVideo: {
    path: "/effectDetailVideo",
    name: "效果详情-视频",
    invisible: true,
    component: () => import("@/pages/effectDetail/effectDetailVideo"),
  },
  effectDetailLive: {
    path: "/effectDetailLive",
    name: "效果详情-直播",
    invisible: true,
    component: () => import("@/pages/effectDetail/effectDetailLive"),
  },
  effectDetailFans: {
    path: "/effectDetailFans",
    name: "效果详情-粉丝",
    invisible: true,
    component: () => import("@/pages/effectDetail/effectDetailFans"),
  },
  effectDetailStore: {
    path: "/effectDetailStore",
    name: "效果详情-活跃门店",
    invisible: true,
    component: () => import("@/pages/effectDetail/effectDetailStore"),
  },
  effectDetailClue: {
    path: "/effectDetailClue",
    name: "效果详情-留资线索",
    invisible: true,
    component: () => import("@/pages/effectDetail/effectDetailClue"),
  },
};
export default routerMap;
