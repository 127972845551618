import auth from './auth'
import account from './account'
import setting from './setting'
import modelPerformance from './modelPerformance'
import modelPerformanceN from './modelPerformanceN'
import dealerOfOneself from './dealerOfOneself'
import modelDetail from './modelDetail'
import topicMarketing from './topicMarketing'
import areaPerspective from './areaPerspective'
import globalDetail from './globalDetail'
import brandMatrix from './brandMatrix'
import matrixDetail from './matrixDetail'
import storeManagement from './storeManagement'
import dealerDetail from './dealerDetail'
import production from './production'
import storeCollection from './storeCollection'
import storeCollectionDetail from './storeCollectionDetail'
import storeSearch from './storeSearch'
import storeCompetition from './storeCompetition'
import storeSort from './storeSort'
import liveRanking from './liveRanking'
import brand_rectangle from './brand_rectangle'
import fullScreenLive from './fullScreenLive'
import assessment from './assessment'
import brandPerformance from './brandPerformance'
import matrixSpread from './matrixSpread'
import regionContrast from './regionContrast'
import benchmarking from './benchmarking'
import matrixBenchmarking from './matrixBenchmarking'
import regionalStores from './regionalStores'
import matrixChangeMonitoring from './matrixChangeMonitoring'
import hangUpMonitoring from './hangUpMonitoring'
import videoMonitoring from './videoMonitoring'
import brandSummary from './brandSummary'
import closedLoop from './closedLoop'
import activityReview from './activityReview'
import storeTrend from './storeTrend'


export default {
    auth,
    account,
    setting,
    modelPerformance,
    modelPerformanceN,
    dealerOfOneself,
    modelDetail,
    topicMarketing,
    areaPerspective,
    globalDetail,
    brandMatrix,
    matrixDetail,
    storeManagement,
    dealerDetail,
    production,
    storeCollection,
    storeCollectionDetail,
    storeSearch,
    storeCompetition,
    storeSort,
    liveRanking,
    brand_rectangle,
    fullScreenLive,
    assessment,
    brandPerformance,
    matrixSpread,
    regionContrast,
    benchmarking,
    matrixBenchmarking,
    regionalStores,
    matrixChangeMonitoring,
    hangUpMonitoring,
    videoMonitoring,
    brandSummary,
    closedLoop,
    activityReview,
    storeTrend
}