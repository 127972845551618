import api from '@/api/assessment'

const {
    getAreaList,
    getActivityData,
    getActivityList,
    getList,
    getAssessmentlist1,
    getAssessmentlist2,
    getAssessmentlist3,
    getAssessmentlist4,
    getAllGroup,
    getResultList
} = api;

export default {
    namespaced: true,

    state: {
        areaList:[],
        activityData: [],
        activityList: [],
        listData: {
            areas: [],
            items: []
        },
        assessmentlistData: {
            list: []
        },
        groupData: [],
        tableLoading: false,
        resultData: {
            list: []
        },
    },

    actions: {
        // 获取区域
        getAreaList({ commit }){
            return getAreaList()
                .then((res) => {
                    if(res.data.code==0){
                      let arr = [];
                      if (res.data.data.type == 10) {
                        arr.push({
                            value: '',
                            label: '不限大区'
                        })
                      }
                      res.data.data.region_list.forEach(element => {
                            let value = {
                                value:element.id,
                                label:element.name
                            }
                            arr.push(value)
                      });
                      commit('changeState',{name:'areaList',data:{
                        region_list: arr,
                        type: res.data.data.type
                      }})
                    }
                    
                })
        },
        getActivityList({ commit }, payload){
            let {
                platform
            } = payload
            return getActivityList({
                platform
            })
                .then((res) => {
                    if(res.data.code==0){
                      commit('changeState',{name:'activityList',data:res.data.data})
                    }
                    
                })
        },
        // 获取直播详情
        getList({ commit }, payload) {
            let {
                start_time,
                end_time,
                region_id,
                platform
            } = payload

            return getList({
                start_time,
                end_time,
                region_id,
                platform
            }).then((res) => {
                console.log(11, res)
                commit('changeState', { name: 'listData', data: res.data })
            })
        },
        // 获取活动列表
        getActivityData({ commit }, payload) {
            let {
                start_time,
                end_time,
                group_code,
                platform
            } = payload

            return getActivityData({
                start_time,
                end_time,
                group_code,
                platform
            }).then((res) => {
                console.log('activityData', res)
                commit('changeState', { name: 'activityData', data: res.data || [] })
            })
        },
        // 短视频
        getAssessmentlist1({ commit }, payload) {
            let {
                start_time,
                end_time,
                region_id,
                group_code,
                sort_field,
                sort,
                current,
                size,
                platform
            } = payload
            commit('changeState', { name: 'tableLoading', data: true })

            return getAssessmentlist1({
                start_time,
                end_time,
                region_id,
                group_code,
                sort_field,
                sort,
                current,
                size,
                platform
            }).then((res) => {
                console.log(11, res)
                commit('changeState', { name: 'tableLoading', data: false })
                commit('changeState', { name: 'assessmentlistData', data: res.data || { list: [] } })
            })
        },
        // 直播
        getAssessmentlist2({ commit }, payload) {
            let {
                start_time,
                end_time,
                region_id,
                group_code,
                sort_field,
                sort,
                current,
                size,
                platform
            } = payload
            commit('changeState', { name: 'tableLoading', data: true })

            return getAssessmentlist2({
                start_time,
                end_time,
                region_id,
                group_code,
                sort_field,
                sort,
                current,
                size,
                platform
            }).then((res) => {
                console.log(11, res)
                commit('changeState', { name: 'tableLoading', data: false })
                commit('changeState', { name: 'assessmentlistData', data: res.data || { list: [] } })
            })
        },
        // 事件活动激励
        getAssessmentlist3({ commit }, payload) {
            let {
                start_time,
                end_time,
                region_id,
                activity_id,
                group_code,
                sort_field,
                sort,
                keyword,
                current,
                size,
                platform
            } = payload
            commit('changeState', { name: 'tableLoading', data: true })

            return getAssessmentlist3({
                start_time,
                end_time,
                region_id,
                activity_id,
                group_code,
                sort_field,
                sort,
                keyword,
                current,
                size,
                platform
            }).then((res) => {
                console.log(11, res)
                commit('changeState', { name: 'tableLoading', data: false })
                commit('changeState', { name: 'assessmentlistData', data: res.data || { list: [] } })
            })
        },
        // 线索激励
        getAssessmentlist4({ commit }, payload) {
            let {
                start_time,
                end_time,
                region_id,
                activity_id,
                group_code,
                sort_field,
                sort,
                keyword,
                current,
                size,
                platform
            } = payload
            commit('changeState', { name: 'tableLoading', data: true })

            return getAssessmentlist4({
                start_time,
                end_time,
                region_id,
                activity_id,
                group_code,
                sort_field,
                sort,
                keyword,
                current,
                size,
                platform
            }).then((res) => {
                console.log(11, res)
                commit('changeState', { name: 'tableLoading', data: false })
                commit('changeState', { name: 'assessmentlistData', data: res.data || { list: [] } })
            })
        },
        getAllGroup({ commit }, payload) {
            return getAllGroup().then((res) => {
                console.log(11, res)
                commit('changeState', { name: 'groupData', data: res.data || [] })
            })
        },
        // 考核结果
        getResultList({ commit }, payload) {
            let {
                start_time,
                end_time,
                region_id,
                activity_id,
                group_code,
                sort_field,
                sort,
                current,
                size,
                platform,
                deadline
            } = payload
            commit('changeState', { name: 'tableLoading', data: true })

            return getResultList({
                start_time,
                end_time,
                region_id,
                activity_id,
                group_code,
                sort_field,
                sort,
                current,
                size,
                platform,
                deadline
            }).then((res) => {
                console.log(11, res)
                commit('changeState', { name: 'tableLoading', data: false })
                commit('changeState', { name: 'resultData', data: res.data || { list: [] } })
            })
        },
    },


    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        },

    },
}
