import api from '@/api/topicMarketing'
const {
    getBrandsData,
    getCalendar,
    getBrandLiveDetail,
    getTopics,
    getTopicObserve,
    getTopicMessage,
    getTopicDetailChart,
    getObserveTopic,
    getLiveCalendar
} = api

export default {
    namespaced: true,

    state: {
        brandsData: [],
        fetching: false,
        calendarList: [],
        brand_live: {},
        topicData: [],
        topicChartData: {
            xData: [],
            yData: {}
        },
        calenderLoading: false,
        topicMessage: {},
        topicDetailChart: [],
        loadingChart: false,
        ObserveTopicDetail: {},
        popoverLoading: false,
    },

    actions: {
        // 品牌数据
        getBrandsData({
            commit
        }) {
            commit('changeState', {
                name: 'fetching',
                data: true
            })
            return getBrandsData()
                .then((res) => {
                    commit('changeState', {
                        name: 'brandsData',
                        data: res.data.data
                    })
                    commit('changeState', {
                        name: 'fetching',
                        data: false
                    })
                }).catch(() => {
                    commit('changeState', {
                        name: 'fetching',
                        data: false
                    })
                })
        },
        // 营销直播日历
        getCalendar({
            commit
        }, payload) {
            let {
                params
            } = payload
            commit('changeState', {
                name: 'calenderLoading',
                data: true
            })
            return getCalendar({
                    params
                })
                .then((res) => {
                    commit('changeState', {
                        name: 'calenderLoading',
                        data: false
                    })
                    if (res.data.code == 0) {
                        commit('changeState', {
                            name: 'calendarList',
                            data: res.data.data
                        })
                    } else {
                        commit('changeState', {
                            name: 'calendarList',
                            data: []
                        })
                        this.$message.error(res.data.msg)
                    }

                }).catch(() => {
                    commit('changeState', {
                        name: 'calenderLoading',
                        data: false
                    })
                })
        },
        // 品牌直播日历数据
        getLiveCalendar({
            commit
        }, payload) {
            let {
                data,
                type
            } = payload
            commit('changeState', {
                name: 'calenderLoading',
                data: true
            })
            return getLiveCalendar({
                    data,
                    type
                })
                .then((res) => {
                    commit('changeState', {
                        name: 'calenderLoading',
                        data: false
                    })
                    if (res.data.code == 0) {
                        commit('changeState', {
                            name: 'calendarList',
                            data: res.data.data
                        })
                    } else {
                        commit('changeState', {
                            name: 'calendarList',
                            data: []
                        })
                        this.$message.error(res.data.msg)
                    }

                }).catch(() => {
                    commit('changeState', {
                        name: 'calenderLoading',
                        data: false
                    })
                })
        },
        // 营销日历详情
        getBrandLiveDetail({
            commit
        }, payload) {
            let {
                params
            } = payload
            commit('changeState', {
                name: 'popoverLoading',
                data: true
            })
            return getBrandLiveDetail({
                    params
                })
                .then((res) => {

                    if (res.data.code == 0) {
                        commit('changeState', {
                            name: 'brand_live',
                            data: res.data.data
                        })
                    }
                    commit('changeState', {
                        name: 'popoverLoading',
                        data: false
                    })
                }).catch(() => {
                    commit('changeState', {
                        name: 'popoverLoading',
                        data: false
                    })
                })
        },
        // 选择话题
        getTopics({
            commit
        }, payload) {
            let {
                params
            } = payload
            return getTopics({
                params
            }).then((res) => {
                if (res.data.code == 0) {
                    commit('changeState', {
                        name: 'topicData',
                        data: res.data.data
                    })
                }

            })
        },
        getTopicObserve({
            commit
        }, payload) {
            let {
                data
            } = payload
            commit('changeState', {
                name: 'loadingChart',
                data: true
            })
            return getTopicObserve({
                    data
                })
                .then((res) => {
                    if (res.data.code == 0) {
                        commit('changeState', {
                            name: 'topicChartData',
                            data: res.data.data
                        })
                    }
                    commit('changeState', {
                        name: 'loadingChart',
                        data: false
                    })
                }).catch(() => {
                    commit('changeState', {
                        name: 'loadingChart',
                        data: false
                    })
                })
        },
        getTopicMessage({
            commit
        }, payload) {
            let {
                id
            } = payload
            return getTopicMessage({
                id
            }).then((res) => {
                if (res.data.code == 0) {
                    commit('changeState', {
                        name: 'topicMessage',
                        data: res.data.data
                    })
                }

            })
        },
        getTopicDetailChart({
            commit
        }, payload) {
            let {
                id
            } = payload
            commit('changeState', {
                name: 'loadingChart',
                data: true
            })
            return getTopicDetailChart({
                id
            }).then((res) => {
                if (res.data.code == 0) {
                    commit('changeState', {
                        name: 'topicDetailChart',
                        data: res.data.data
                    })
                }
                commit('changeState', {
                    name: 'loadingChart',
                    data: false
                })
            }).catch(() => {
                commit('changeState', {
                    name: 'loadingChart',
                    data: false
                })
            })
        },
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}