import api from '@/api/matrixChangeMonitoring'

const {
    getMonitList,
    getNewMonitList
} = api;

export default {
    namespaced: true,

    state: {
        monitData: {
            data: [],
            pagination: {}
        },
        matrixData: {
            list: [],
            total: 0,
            type: true
        }
    },

    actions: {
        getMonitList({ commit }, payload) {

            let {
                current,
                size
            } = payload
            commit('changeState', {
                name: 'monitData', data: {
                    data: [],
                    pagination: {}
                }
            })
            return getMonitList({
                current,
                size
            })
                .then((res) => {
                    console.log('getMonitList res', res)
                    commit('changeState', { name: 'monitData', data: res.data })
                })
        },
        getNewMonitList({ commit }, payload) {

            let {
                start_time,
                end_time,
                current,
                size,
                platform
            } = payload
            commit('changeState', {
                name: 'matrixData', data: {
                    list: [],
                    total: 0,
                    type: true
                }
            })
            return getNewMonitList({
                start_time,
                end_time,
                current,
                size,
                platform
            })
                .then((res) => {
                    console.log('getNewMonitList res', res)
                    commit('changeState', { name: 'matrixData', data: res.data })
                })
        }
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}
