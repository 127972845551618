import api from '@/api/benchmarking';

const {
    getOfficialList,
    getReleaseData,
    getAuthorMessage,
    getLiveMessage,
    getTopThirtyLive,
    getCalendarLive,
    getPrincipalTags
} = api;

export default {
    namespaced: true,

    state: {
        officialList: {
            self: {},
            list: []
        },
        cardLoading: false,
        releaseData: [],
        authorMessage: {},
        liveMessage: {},
        topThirtyList: [],
        calendarData: {},
        tagList: []
    },
    actions: {
        getOfficialList({
            commit
        }, paylod) {
            let {
                start_time,
                end_time,
                tag_ps,
                platform
            } = paylod;

            commit('changeState', {
                name: 'officialList',
                data: {
                    self: {},
                    list: []
                }
            })
            commit('changeState', {
                name: 'cardLoading',
                data: true
            })

            return getOfficialList({
                start_time,
                end_time,
                tag_ps,
                platform
            }).then(res => {
                commit('changeState', {
                    name: 'officialList',
                    data: res.data
                })
                commit('changeState', {
                    name: 'cardLoading',
                    data: false
                })
            }).catch(res => {
                console.log(res)
            })
        },
        getReleaseData({
            commit
        }, paylod) {
            let {
                year,
                author_id,
                platform
            } = paylod;

            return getReleaseData({
                year,
                author_id,
                platform
            }).then(res => {
                commit('changeState', {
                    name: 'releaseData',
                    data: res.data
                })
            }).catch(res => {
                console.log(res)
            })
        },
        getAuthorMessage({
            commit
        }, paylod) {
            let {
                type,
                id
            } = paylod;

            return getAuthorMessage({
                id,
                type
            }).then(res => {
                commit('changeState', {
                    name: 'authorMessage',
                    data: res.data
                })
            }).catch(res => {
                console.log(res)
            })
        },
        getLiveMessage({
            commit
        }, payload) {
            let {
                type,
                params
            } = payload;


            return getLiveMessage({
                type,
                params
            }).then(res => {
                commit('changeState', {
                    name: 'liveMessage',
                    data: res.data.data
                })

            }).catch(res => {
                console.log(res)
            })
        },
        getTopThirtyLive({
            commit
        }, payload) {
            let {
                type,
                params
            } = payload;


            return getTopThirtyLive({
                type,
                params
            }).then(res => {
                commit('changeState', {
                    name: 'topThirtyList',
                    data: res.data.data
                })

            }).catch(res => {
                console.log(res)
            })
        },
        getCalendarLive({
            commit
        }, payload) {
            let {
                type,
                params
            } = payload;


            commit('changeState', {
                name: 'calenderLoading',
                data: true
            })
            return getCalendarLive({
                type,
                params
            }).then(res => {
                commit('changeState', {
                    name: 'calendarData',
                    data: res.data.data
                })
                commit('changeState', {
                    name: 'calenderLoading',
                    data: false
                })

            }).catch(res => {
                commit('changeState', {
                    name: 'calenderLoading',
                    data: false
                })
            })
        },
        getPrincipalTags({
            commit
        }, payload) {
            return getPrincipalTags().then(res => {
                console.log('getPrincipalTags', res)
                commit('changeState', {
                    name: 'tagList',
                    data: res
                })
            })
        },
    },
    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}