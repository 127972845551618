import TabsView from "@/layouts/tabs/TabsView";
import BlankView from "@/layouts/BlankView";
import PageView from "@/layouts/PageView";

// 路由配置
const options = {
  routes: [
    {
      path: "/login",
      name: "登录",
      component: () => import("@/pages/login"),
    },
    {
      path: "/icons",
      name: "icon",
      component: () => import("@/pages/SvgIcons"),
    },
    {
      path: "/403",
      name: "403",
      component: () => import("@/pages/exception/403"),
    },
    {
      path: "/",
      name: "首页",
      component: TabsView,
      redirect: "/login",
      children: [
        // 品牌影响力
        {
          path: "/brand",
          name: "行业影响力",
          meta: {
            title: "行业影响力",
            icon: "table",
          },
          component: PageView,
          children: [
            {
              path: "/brand/influence",
              name: "品牌影响力",
              meta: {
                title: "品牌影响力",
                icon: "table",
              },
              component: () => import("@/pages/brand/Influence.vue"),
            },
            {
              path: "/influenceDetail",
              name: "品牌影响力详情",
              meta: {
                title: "品牌影响力详情",
                invisible: true,
              },
              component: () => import("@/pages/brand/InfluenceDetail.vue"),
            },
            // 视频详情
            {
              path: "/videoDetail",
              name: "视频详情",
              meta: {
                title: "视频详情",
                invisible: true,
              },
              component: () => import("@/pages/videoDetail/videoDetail.vue"),
            },
            // 直播详情
            {
              path: "/liveDetail",
              name: "直播详情",
              meta: {
                title: "直播详情",
                invisible: true,
              },
              component: () => import("@/pages/liveDetail/liveDetail.vue"),
            },
            {
              path: "/intentionAnalysis",
              name: "智能意向分析",
              meta: {
                title: "智能意向分析",
                invisible: true,
              },
              component: () =>
                import("@/pages/liveDetail/intentionAnalysis.vue"),
            },
            {
              path: "/liveAssistant",
              name: "智能复盘助手",
              meta: {
                title: "智能复盘助手",
                invisible: true,
              },
              component: () => import("@/pages/liveDetail/liveAssistant.vue"),
            },
            // 效果详情
            {
              path: "/effectDetailVideo",
              name: "效果详情-视频",
              meta: {
                title: "效果详情-视频",
                invisible: true,
              },
              component: () =>
                import("@/pages/effectDetail/effectDetailVideo.vue"),
            },
            {
              path: "/effectDetailLive",
              name: "效果详情-直播",
              meta: {
                title: "效果详情-直播",
                invisible: true,
              },
              component: () =>
                import("@/pages/effectDetail/effectDetailLive.vue"),
            },
            {
              path: "/effectDetailFans",
              name: "效果详情-粉丝",
              meta: {
                title: "效果详情-粉丝",
                invisible: true,
              },
              component: () =>
                import("@/pages/effectDetail/effectDetailFans.vue"),
            },
            {
              path: "/effectDetailStore",
              name: "效果详情-活跃门店",
              meta: {
                title: "效果详情-活跃门店",
                invisible: true,
              },
              component: () =>
                import("@/pages/effectDetail/effectDetailStore.vue"),
            },
            {
              path: "/effectDetailClue",
              name: "效果详情-留资线索",
              meta: {
                title: "效果详情-留资线索",
                invisible: true,
              },
              component: () =>
                import("@/pages/effectDetail/effectDetailClue.vue"),
            },
            {
              path: "/brand/liveList",
              name: "品牌直播榜",
              meta: {
                title: "品牌直播榜",
                icon: "table",
              },
              component: () => import("@/pages/brand/LiveList.vue"),
            },
            {
              path: "/brand/videoList",
              name: "品牌视频榜",
              meta: {
                title: "品牌视频榜",
                icon: "table",
              },
              component: () => import("@/pages/brand/VideoList.vue"),
            },
            {
              path: "/brand/performance",
              name: "行业平均表现",
              meta: {
                title: "行业平均表现",
                icon: "table",
              },
              component: () => import("@/pages/brand/Performance.vue"),
            },
            {
              path: "/brand/benchmarking",
              name: "品牌官号运营对标",
              meta: {
                title: "品牌官号运营对标",
                icon: "table",
              },
              component: () => import("@/pages/brand/Benchmarking.vue"),
            },
            {
              path: "/brand/focusOnStores",
              name: "重点关注门店",
              meta: {
                title: "重点关注门店",
                icon: "table",
              },
              component: () => import("@/pages/brand/focusOnStores.vue"),
            },
            {
              path: "/brand/worksList",
              name: "品牌官号作品榜",
              meta: {
                title: "品牌官号作品榜",
                icon: "table",
              },
              component: () => import("@/pages/brand/WorksList.vue"),
            },
            {
              path: "/brand/dealerWorksList",
              name: "品牌经销商作品榜",
              meta: {
                title: "品牌经销商作品榜",
                icon: "table",
              },
              component: () => import("@/pages/brand/DealerWorksList.vue"),
            },
            {
              path: "/brand/generalList",
              name: "品牌总榜",
              meta: {
                title: "品牌总榜",
                icon: "table",
              },
              component: () => import("@/pages/brand/GeneralList.vue"),
            },
            {
              path: "/brand/myRanking",
              name: "本品排名",
              meta: {
                title: "本品排名",
                invisible: true,
              },
              component: () => import("@/pages/brand/myRanking.vue"),
            },
            {
              path: "/brand/industryTop",
              name: "行业百强榜",
              meta: {
                title: "行业百强榜",
                icon: "table",
              },
              component: () => import("@/pages/brand/industryTop.vue"),
            },
          ],
        },
        {
          path: "matrix",
          name: "品牌矩阵",
          meta: {
            title: "品牌矩阵",
            icon: "table",
          },
          component: PageView,
          children: [
            {
              path: "brandSummary",
              name: "品牌概览",
              meta: {
                title: "品牌概览",
                icon: "table",
              },
              component: () => import("@/pages/brandSummary/brandSummary"),
            },
            {
              path: "brandMatrix",
              name: "品牌矩阵",
              meta: {
                title: "品牌矩阵",
                icon: "table",
              },
              component: () => import("@/pages/brandMatrix/brandMatrix"),
            },
            {
              path: "matrixPerspective",
              name: "矩阵透视",
              meta: {
                title: "矩阵透视",
                icon: "table",
              },
              component: () =>
                import("@/pages/matrixPerspective/matrixPerspective"),
            },
            {
              path: "matrixDetail",
              name: "矩阵详情",
              meta: {
                title: "矩阵详情",
                icon: "table",
              },
              component: () => import("@/pages/matrixDetail/matrixDetail"),
            },
            {
              path: "matrixSpread",
              name: "矩阵传播价值",
              meta: {
                title: "矩阵传播价值",
                icon: "table",
              },
              component: () => import("@/pages/matrixSpread/matrixSpread"),
            },
            {
              path: "matrixBenchmarking",
              name: "经销商矩阵对标",
              meta: {
                title: "经销商矩阵对标",
                icon: "table",
              },
              component: () =>
                import("@/pages/matrixBenchmarking/matrixBenchmarking"),
            },
            {
              path: "matrixChangeMonitoring",
              name: "矩阵异动",
              meta: {
                title: "矩阵异动",
                icon: "table",
              },
              component: () =>
                import("@/pages/matrixChangeMonitoring/matrixChangeMonitoring"),
            },
            {
              path: "matrixChangeMonitoringN",
              name: "矩阵异动监测",
              meta: {
                title: "矩阵异动监测",
                icon: "table",
              },
              component: () =>
                import(
                  "@/pages/matrixChangeMonitoring/matrixChangeMonitoringN"
                ),
            },
          ],
        },
        {
          path: "/model",
          name: "车系/车型",
          meta: {
            title: "车系/车型",
            icon: "table",
          },
          component: PageView,
          children: [
            {
              path: "modelPerformance",
              name: "车系表现",
              meta: {
                title: "车系表现",
                icon: "table",
              },
              component: () =>
                import("@/pages/modelPerformance/modelPerformance"),
            },
            {
              path: "modelDetail",
              name: "车系详情",
              meta: {
                title: "车系详情",
                invisible: true,
              },
              component: () => import("@/pages/modelDetail/modelDetail"),
            },
            {
              path: "modelPerformanceN",
              name: "车系表现(灰度)",
              meta: {
                title: "车系表现(灰度)",
                icon: "table",
                invisible: true,
              },
              component: () =>
                import("@/pages/modelPerformanceN/modelPerformanceN"),
            },
            {
              path: "modelDetailN",
              name: "车系详情(灰度)",
              meta: {
                title: "车系详情(灰度)",
                invisible: true,
              },
              component: () => import("@/pages/modelDetailN/modelDetailN"),
            },
            {
              path: "modelTopicDetail",
              name: "车系话题详情",
              meta: {
                title: "车系话题详情",
                invisible: true,
              },
              component: () => import("@/pages/modelDetailN/modelTopicDetail"),
            },
          ],
        },
        // 直播地图
        {
          path: "/topic",
          name: "直播地图",
          meta: {
            title: "直播地图",
            icon: "table",
          },
          component: PageView,
          children: [
            {
              path: "/topic/liveArea",
              name: "经销商直播(旧版)",
              meta: {
                title: "经销商直播(旧版)",
              },
              component: () => import("@/pages/liveArea/liveArea.vue"),
            },
            {
              path: "/topic/liveAreaN",
              name: "经销商直播",
              meta: {
                title: "经销商直播",
              },
              component: () => import("@/pages/liveArea/liveAreaN.vue"),
            },
            {
              path: "/topic/calendar",
              name: "营销日历",
              meta: {
                title: "营销日历",
                icon: "table",
              },
              component: () => import("@/pages/topics/calendar/index.vue"),
            },
            {
              path: "/topic/brandCalendar",
              name: "品牌官号直播",
              meta: {
                title: "品牌官号直播",
                icon: "table",
              },
              component: () => import("@/pages/topics/brandCalendar/index.vue"),
            },
            {
              path: "/topic/observe",
              name: "话题观察",
              meta: {
                title: "话题观察",
                icon: "table",
              },
              component: () => import("@/pages/topics/observe/index.vue"),
            },
          ],
        },
        {
          path: "/dealer",
          name: "经销商管理",
          meta: {
            title: "经销商管理",
            icon: "table",
          },
          component: PageView,
          children: [
            {
              path: "dealerOfOneself",
              name: "经销商概览",
              meta: {
                title: "经销商概览",
              },
              component: () =>
                import("@/pages/dealerOfOneself/dealerOfOneself"),
            },
            {
              path: "storeTrend",
              name: "门店趋势统计",
              meta: {
                title: "门店趋势统计",
                invisible: true,
              },
              component: () => import("@/pages/storeTrend/storeTrend"),
            },
            {
              path: "regionalPerspective",
              name: "大区透视",
              meta: {
                title: "大区透视",
              },
              component: () =>
                import("@/pages/regionalPerspective/regionalPerspective"),
            },
            {
              path: "dealerDetail",
              name: "经销商详情",
              meta: {
                title: "经销商详情",
                invisible: true,
              },
              component: () => import("@/pages/dealerDetail/dealerDetail"),
            },
            {
              path: "dealerDetailN",
              name: "经销商详情(灰度)",
              meta: {
                title: "经销商详情(灰度)",
                invisible: true,
              },
              component: () => import("@/pages/dealerDetail/dealerDetailN"),
            },
            {
              path: "storeRanking",
              name: "门店排行",
              meta: {
                title: "门店排行",
                invisible: true,
              },
              component: () => import("@/pages/storeRanking/storeRanking"),
            },
            {
              path: "storeSearch",
              name: "门店查询",
              meta: {
                title: "门店查询",
              },
              component: () => import("@/pages/storeSearch/storeSearch"),
            },
            {
              path: "storeCompetition",
              name: "门店统计",
              meta: {
                title: "门店统计",
              },
              component: () =>
                import("@/pages/storeCompetition/storeCompetition"),
            },
            {
              path: "storeCollection",
              name: "门店收藏",
              meta: {
                title: "门店收藏",
                invisible: true,
              },
              component: () =>
                import("@/pages/storeCollection/storeCollection"),
            },
            {
              path: "assessment",
              name: "商务政策考核",
              meta: {
                title: "商务政策考核",
              },
              component: () => import("@/pages/assessment/assessment"),
            },
            {
              path: "assessmentResult",
              name: "考核结果",
              meta: {
                title: "考核结果",
                invisible: true,
              },
              component: () => import("@/pages/assessment/assessmentResult"),
            },
            {
              path: "activityManagement",
              name: "活动管理",
              meta: {
                title: "活动管理",
              },
              component: () =>
                import("@/pages/activityManagement/activityManagement"),
            },
            {
              path: "activityReview",
              name: "活动复盘",
              meta: {
                title: "活动复盘",
                invisible: true,
              },
              component: () => import("@/pages/activityReview/activityReview"),
            },
            {
              path: "activityList",
              name: "活动榜单",
              meta: {
                title: "活动榜单",
                invisible: true,
              },
              component: () => import("@/pages/activityList/activityList"),
            },
            {
              path: "closedLoop",
              name: "闭环管理",
              meta: {
                title: "闭环管理",
              },
              component: () => import("@/pages/closedLoop/closedLoop"),
            },
            {
              path: "knowledge",
              name: "知识库",
              meta: {
                title: "知识库",
              },
              component: () => import("@/pages/knowledge/knowledge"),
            },
            {
              path: "leadManagement",
              name: "线索管理",
              meta: {
                title: "线索管理",
              },
              component: () => import("@/pages/leadManagement/leadManagement"),
            },
            {
              path: "modelClue",
              name: "车型线索统计",
              meta: {
                title: "车型线索统计",
              },
              component: () => import("@/pages/modelClue/modelClue"),
            },
            {
              path: "activityClosedLoop",
              name: "活动闭环管理",
              meta: {
                title: "活动闭环管理",
                invisible: true,
              },
              component: () =>
                import("@/pages/activityClosedLoop/activityClosedLoop"),
            },
            {
              path: "storeCollectionDetail",
              name: "门店收藏详情",
              meta: {
                title: "门店收藏详情",
                invisible: true,
              },
              component: () =>
                import("@/pages/storeCollectionDetail/storeCollectionDetail"),
            },
            {
              path: "production",
              name: "账号详情",
              meta: {
                title: "账号详情",
                invisible: true,
              },
              component: () => import("@/pages/production/production"),
            },
            {
              path: "productionN",
              name: "账号详情(灰度)",
              meta: {
                title: "账号详情(灰度)",
                invisible: true,
              },
              component: () => import("@/pages/production/productionN"),
            },
            {
              path: "storeManagement",
              name: "门店信息管理",
              meta: {
                title: "门店信息管理",
              },
              component: () =>
                import("@/pages/storeManagement/storeManagement"),
            },
            {
              path: "regionContrast",
              name: "竞品区域对比",
              meta: {
                title: "竞品区域对比",
                invisible: true,
              },
              component: () => import("@/pages/regionContrast/regionContrast"),
            },
            {
              path: "regionalStores",
              name: "区域标杆门店",
              meta: {
                title: "区域标杆门店",
                invisible: true,
              },
              component: () => import("@/pages/regionalStores/regionalStores"),
            },
            {
              path: "hotLiveList",
              name: "每周热榜直播",
              meta: {
                title: "每周热榜直播",
                invisible: true,
              },
              component: () => import("@/pages/hotList/hotLiveList"),
            },
            {
              path: "hotVideoList",
              name: "每周热榜视频",
              meta: {
                title: "每周热榜视频",
                invisible: true,
              },
              component: () => import("@/pages/hotList/hotVideoList"),
            },
            {
              path: "accountAuthorization",
              name: "账号授权管理",
              meta: {
                title: "账号授权管理",
                invisible: true,
              },
              component: () =>
                import("@/pages/accountAuthorization/accountAuthorization"),
            },
            // 商务政策概览
            {
              path: "businessPolicyOverview",
              name: "商务政策概览",
              meta: {
                title: "商务政策概览",
              },
              component: () =>
                import("@/pages/businessPolicyOverview/businessPolicyOverview"),
            },
            // 门店商务政策
            {
              path: "storePolicy",
              name: "门店商务政策",
              meta: {
                title: "门店商务政策",
              },
              component: () => import("@/pages/storePolicy/storePolicy"),
            },
            // 经销商KPI
            {
              path: "dealerKPI",
              name: "经销商KPI",
              meta: {
                title: "经销商KPI",
              },
              component: () => import("@/pages/dealerKPI/dealerKPI"),
            },
            // 活动大屏
            {
              path: "activityScreen",
              name: "活动大屏",
              meta: {
                title: "活动大屏",
                invisible: true,
              },
              component: () => import("@/pages/activityScreen/activityScreen"),
            },
            // 活动大屏-新版
            {
              path: "activityScreenN",
              name: "直播大屏",
              meta: {
                title: "直播大屏",
                invisible: true,
              },
              component: () =>
                import("@/pages/activityScreenN/activityScreenN"),
            },
          ],
        },
        // 直播和短视频
        {
          path: "/liveAndVideo",
          name: "直播&短视频",
          meta: {
            title: "直播&短视频",
            icon: "table",
          },
          component: PageView,
          children: [
            {
              path: "/liveAndVideo/realTimeLive",
              name: "实况直播",
              meta: {
                title: "实况直播",
                invisible: true,
              },
              component: () => import("@/pages/fullScreen/live"),
            },
            {
              path: "/liveAndVideo/liveRanking",
              name: "直播排行",
              meta: {
                title: "直播排行",
              },
              component: () => import("@/pages/liveRanking/liveRanking.vue"),
            },
            {
              path: "/liveAndVideo/videoRanking",
              name: "视频排行",
              meta: {
                title: "视频排行",
              },
              component: () => import("@/pages/videoRanking/videoRanking.vue"),
            },
            {
              path: "/liveAndVideo/thawingRecord",
              name: "解冻记录",
              meta: {
                title: "解冻记录",
                invisible: true,
              },
              component: () =>
                import("@/pages/thawingRecord/thawingRecord.vue"),
            },
          ],
        },
        // 深度质检
        {
          path: "/depthInspection",
          name: "深度质检",
          meta: {
            title: "深度质检",
            icon: "depthInspection",
          },
          component: PageView,
          children: [
            {
              path: "/depthInspection/videoMonitoring",
              name: "短视频违规监测",
              meta: {
                title: "短视频违规监测",
              },
              component: () =>
                import("@/pages/videoMonitoring/videoMonitoring.vue"),
            },
            {
              path: "/depthInspection/hangUpMonitoring",
              name: "直播违规监测",
              meta: {
                title: "直播违规监测",
              },
              component: () =>
                import("@/pages/hangUpMonitoring/hangUpMonitoring.vue"),
            },
            {
              path: "/depthInspection/storeRenovation",
              name: "门店装修质检",
              meta: {
                title: "门店装修质检",
              },
              component: () =>
                import("@/pages/storeRenovation/storeRenovation"),
            },
          ],
        },
        // 总看板 （极简版）
        {
          path: "/bulletinBoard",
          name: "总看板",
          meta: {
            title: "总看板",
            icon: "bulletinBoard",
          },
          component: PageView,
          children: [
            {
              path: "/bulletinBoard/index",
              name: "总看板",
              meta: {
                title: "总看板",
              },
              component: () => import("@/pages/bulletinBoard/index.vue"),
            },
            {
              path: "/bulletinBoard/region",
              name: "大区首页",
              meta: {
                title: "大区首页",
              },
              component: () => import("@/pages/regionHome/index.vue"),
            },
          ],
        },
        // 销售管理
        {
          path: "/sales",
          name: "销售管理",
          meta: {
            title: "销售管理",
            icon: "sales",
          },
          component: PageView,
          children: [
            {
              path: "/sales/componentsMarket",
              name: "组件大盘",
              meta: {
                title: "组件大盘",
              },
              component: () =>
                import("@/pages/sales/componentsMarket/componentsMarket.vue"),
            },
            {
              path: "/sales/clueMarket",
              name: "线索大盘",
              meta: {
                title: "线索大盘",
              },
              component: () =>
                import("@/pages/sales/clueMarket/clueMarket.vue"),
            },
            {
              path: "/sales/conversionMarket",
              name: "转化大盘",
              meta: {
                title: "转化大盘",
              },
              component: () =>
                import("@/pages/sales/conversionMarket/conversionMarket.vue"),
            },
          ],
        },
        // 营销中心
        {
          path: "/marketing",
          name: "营销中心",
          meta: {
            title: "营销中心",
            icon: "marketing",
          },
          component: PageView,
          children: [
            {
              path: "/marketing/activityOverview",
              name: "活动概况",
              meta: {
                title: "活动概况",
              },
              component: () =>
                import(
                  "@/pages/marketing/activityOverview/activityOverview.vue"
                ),
            },
            {
              path: "/marketing/dailyAchievements",
              name: "每日战报",
              meta: {
                title: "每日战报",
              },
              component: () =>
                import(
                  "@/pages/marketing/dailyAchievements/dailyAchievements.vue"
                ),
            },
            // 直播大屏-新版
            {
              path: "activityScreenN",
              name: "直播大屏",
              meta: {
                title: "直播大屏",
                invisible: true,
              },
              component: () =>
                import("@/pages/activityScreenN/activityScreenN"),
            },
            {
              path: "/marketing/taskCenter",
              name: "任务中心",
              meta: {
                title: "任务中心",
                invisible: true,
              },
              component: () =>
                import("@/pages/marketing/taskCenter/taskCenter.vue"),
            },
            {
              path: "/marketing/activityRanking",
              name: "活动榜单",
              meta: {
                title: "活动榜单",
              },
              component: () =>
                import("@/pages/marketing/activityRanking/activityRanking.vue"),
            },
            {
              path: "/marketing/nActivityList",
              name: "活动列表",
              meta: {
                title: "活动列表",
              },
              component: () =>
                import("@/pages/marketing/nActivityList/nActivityList.vue"),
            },
            {
              path: "/marketing/activityStatistics",
              name: "活动数据",
              meta: {
                title: "活动数据",
              },
              component: () =>
                import(
                  "@/pages/marketing/activityStatistics/activityStatistics.vue"
                ),
            },
            {
              path: "/marketing/activityRegionProgress",
              name: "区域对比",
              meta: {
                title: "区域对比",
              },
              component: () =>
                import(
                  "@/pages/marketing/activityRegionProgress/activityRegionProgress.vue"
                ),
            },
            {
              path: "/marketing/taskProgress",
              name: "任务进度",
              meta: {
                title: "任务进度",
              },
              component: () =>
                import("@/pages/marketing/taskProgress/taskProgress.vue"),
            },
            {
              path: "/marketing/activityReport",
              name: "报表中心",
              meta: {
                title: "报表中心",
              },
              component: () =>
                import("@/pages/marketing/activityReport/activityReport.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "*",
      // redirect: "/404",
      name: "404",
      component: () => import("@/pages/exception/404"),
    },
  ],
};

export default options;
