import api from '@/api/storeCompetition'

const {
    getAreaInfo,
    getAllGroup,
    getCompetitionList
} = api;

export default {
    namespaced: true,

    state: {
        areaData: [],
        groupData: [],
        competitionData: [],
        competitionAllData: {},
    },

    actions: {
        // 获取大区 省市
        getAreaInfo({ commit }, payload) {
            return getAreaInfo()
                .then((res) => {
                    console.log('getAreaInfo res', res)
                    let newArr = []
                    if (res.data.type == 10) {
                        newArr.push({
                            id: '',
                            name: '不限大区'
                        })
                    }
                    newArr = newArr.concat(res.data.region_list)
                    console.log(newArr)
                    commit('changeState', { name: 'areaData', data: newArr })
                })
        },
        // 获取全部分组
        getAllGroup({ commit }, payload) {
            return getAllGroup()
                .then((res) => {
                    console.log('getAllGroup res', res)
                    commit('changeState', { name: 'groupData', data: res.data })
                })
        },
        // 获取门店统计列表
        getCompetitionList({ commit }, payload) {
            console.log('p: ', payload)
            let {
                is_dealer,
                word,
                area_id,
                province_city_id,
                group_code,
                verification_type,
                begin_time,
                end_time,
                current,
                page_size,
                platform,
                type,
                aweme_play_volume,
                live_time
            } = payload
            return getCompetitionList({
                is_dealer,
                word,
                area_id,
                province_city_id,
                group_code,
                verification_type,
                begin_time,
                end_time,
                current,
                page_size,
                platform,
                type,
                aweme_play_volume,
                live_time
            })
                .then((res) => {
                    console.log('getCompetitionList res', res)
                    
                    commit('changeState', { name: 'competitionAllData', data: res })
                    commit('changeState', { name: 'competitionData', data: res.data })
                })
        },

    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}
