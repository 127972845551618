<template>
  <img class="aft-loading" src="@/assets/img/aft-loading.png" alt="" />
</template>

<script>
export default {
  name: "AftLoading",
};
</script>

<style scoped lang="less">
.aft-loading {
  width: 46px;
  height: 46px;
  position: relative;
  animation: loading 1.2s linear infinite;
}
@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loading {
  /* Safari and Chrome */
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
