import api from '@/api/matrixDetail'

const {
    getMatrixList,
    getInfoDetail
} = api;

export default {
    namespaced: true,

    state: {
        matrixList: [],
        detailData: {}
    },

    actions: {
        // 获取品牌列表
        getMatrixList({ commit }, payload) {
            let {
                account_type,
                pyramid_position,
                sore_type,
                current,
                page_size,
                platform
            } = payload
            
            return getMatrixList({
                account_type,
                pyramid_position,
                sore_type,
                current,
                page_size,
                platform
            })
                .then((res) => {
                    console.log('getMatrixList res', res)
                    commit('changeState', { name: 'matrixList', data: res.data })
                })
        },
        getInfoDetail({ commit }, payload) {
            let {
                platform
            } = payload
            return getInfoDetail({
                platform
            })
                .then((res) => {
                    console.log('getInfoDetail res', res)
                    commit('changeState', { name: 'detailData', data: res.data })
                })
        },
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}
