import Nr from "@/utils/NetworldRequest";
const request = new Nr().getAxios();
function getBaseUrl(serviceName) {
  return new Nr().getBaseUrl(serviceName);
}
export default {
  // 获取大区 省市
  getAreaInfo() {
    return request({
      url: `/jjt/dealer/region-list`,
      method: "get",
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
    }).then((res) => {
      res = res.data;
      let list = [];
      if (res.data.type == 10) {
        list.push({
          id: "",
          name: "不限大区",
        });
      }
      list = list.concat(res.data.region_list);
      console.log(list);
      return {
        code: res.code,
        data: list,
        msg: res.msg,
      };
    });
  },
  // 获取全部分组
  getAllGroup() {
    return request({
      url: `/jjt/group/selectAllGroup`,
      method: "get",
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
    }).then((res) => {
      res = res.data;
      return res;
    });
  },
  // 获取全部违规标签
  getMonitorTag(params) {
    return request({
      url: `/jjt/principal/illegal/tag`,
      method: "get",
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      params,
    }).then((res) => {
      res = res.data;
      return res;
    });
  },
  // 获取直播排行列表
  getMonitorList({
    region_id,
    group_code,
    start_time,
    end_time,
    suspicion_type,
    field,
    page_num,
    page_size,
    platform,
  }) {
    return request({
      url: `/jjt/live-monitor/detail`,
      method: "get",
      baseURL: getBaseUrl(platform),
      params: {
        region_id,
        group_code,
        start_time,
        end_time,
        suspicion_type,
        field,
        page_num,
        page_size,
      },
    }).then((res) => {
      res = res.data;
      let newRes = {
        code: res.code,
        data: {
          ...res.data,
          xData: res.data.week_comparative_monitors
            .map((item) => {
              return `近${item.week_num}周(${item.time})`;
            })
            .reverse(),
          ratioData: res.data.week_comparative_monitors
            .map((item) => {
              return item.ratio;
            })
            .reverse(),
          countData: res.data.week_comparative_monitors
            .map((item) => {
              return item.count;
            })
            .reverse(),
        },
        msg: res.msg,
      };
      return newRes;
    });
  },
  // 获取直播排行列表
  getLiveList({
    region_id,
    group_code,
    start_time,
    end_time,
    current,
    size,
    platform,
  }) {
    return request({
      url: `/jjt/live/top`,
      method: "get",
      baseURL: getBaseUrl(platform),
      params: {
        region_id,
        group_code,
        start_time,
        end_time,
        current,
        size,
      },
    }).then((res) => {
      res = res.data;
      return res;
    });
  },
  // 获取视频排行列表
  getVideoList({
    region_id,
    group_code,
    start_time,
    end_time,
    current,
    size,
    platform,
  }) {
    return request({
      url: `/jjt/aweme/top`,
      method: "get",
      baseURL: getBaseUrl(platform),
      params: {
        region_id,
        group_code,
        start_time,
        end_time,
        current,
        size,
      },
    }).then((res) => {
      res = res.data;
      return res;
    });
  },
};
