import api from '@/api/storeCollection'

const {
  addGroup,
  getGroup,
  editGroupName,
  deleteGroup,
  addBatchDealer
} = api;

export default {
  namespaced: true,

  state: {
    addGroupRes: null,
    groupData: {},
    editGroupRes: null,
    deleteGroupRes: null,
    addBatchRes: null,
  },

  actions: {
    // 新建分组/复制分组
    async addGroup({ commit }, payload) {
      let {
        group_code,
        name,
        new_or_copy
      } = payload

      return addGroup({
        group_code,
        name,
        new_or_copy
      })
        .then((res) => {
          console.log('addGroup res', res)
          commit('changeState', { name: 'addGroupRes', data: res.msg })
        })
    },
    // 查询分组
    getGroup({ commit }, payload) {
      let {
        current,
        page_size
      } = payload

      return getGroup({
        current,
        page_size
      })
        .then((res) => {
          console.log('getGroup res', res)
          commit('changeState', { name: 'groupData', data: res.data })
        })
    },
    // 修改分组名字
    async editGroupName({ commit }, payload) {
      let {
        id,
        name
      } = payload

      return editGroupName({
        id,
        name
      })
        .then((res) => {
          console.log('editGroupName res', res)
          commit('changeState', { name: 'editGroupRes', data: res.msg })
        })
    },
    // 删除分组
    async deleteGroup({ commit }, payload) {
      let {
        group_id
      } = payload

      return deleteGroup({
        group_id
      })
        .then((res) => {
          console.log('deleteGroup res', res)
          commit('changeState', { name: 'deleteGroupRes', data: res.msg })
        })
    },
    async addBatchDealer({ commit }, payload) {
      let {
        group_code,
        dealers
      } = payload

      return addBatchDealer({
        group_code,
        dealers
      })
        .then((res) => {
          console.log('addBatchRes res', res)
          commit('changeState', { name: 'addBatchRes', data: res.msg })
        })
    },
    
  },

  mutations: {
    changeState(state, payload) {
      let {
        data,
        name
      } = payload;
      state[name] = data;
    }
  }
}
