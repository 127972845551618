import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
  // 车系表现列表
  getSeriesList({
    statistics_begin_date,
    statistics_end_date,
    platform
  }) {
    return request({
      url: `/jjt/aweme-series/manufactor/series-interaction-detail`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        statistics_begin_date,
        statistics_end_date
      }
    }).then(res => {
      res = res.data
      return ({
        code: res.code,
        data: (res.data || []).map(item => {
          return {
            avatar: process.env.VUE_APP_LIVE_OSS_HOST + item.series.image,
            seriesName: item.series.name,
            interaction: item.gap_total,
            id: item.id,
            hotTopic: item.hot_search_suggest,
            xData: (item.interactive_score || []).map(i => { return i.crawl_time.slice(5, 10) }),
            yData: (item.interactive_score || []).map(i => { return i.gap_total }),
          }
        }),
        msg: res.msg
      })
    })
  },
  // 查询SUV/轿车互动排行榜
  getVolumeRanking({
    statistics_begin_date,
    statistics_end_date,
    series_id,
    platform
  }) {
    return request({
      url: `/jjt/aweme-series/series-ranking`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        statistics_begin_date,
        statistics_end_date,
        series_id
      }
    }).then(res => {
      res = res.data
      return ({
        code: res.code,
        data: {
          xData: res.data.map(item => {return item.series.name}),
          yData: res.data.map(item => {return item.gap_total})
        },
        msg: res.msg
      })
    })
  },
}
