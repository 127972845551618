import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
    getBrandRanking({
        platform,
        principal_id
    }) {
        return request({
            url: `/jjt/overview/all/brank/ranking`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params: {
                principal_id
            }
        }).then(res => {
            res = res.data
            return res
        })
    },
    getBrandOfficialRanks({
        platform,
        author_id,
        principal_id
    }) {
        return request({
            url: `/jjt/overview/brandOfficialRanks`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params: {
                author_id,
                principal_id
            }
        }).then(res => {
            res = res.data
            return res
        })
    },
    getLiveTopTen({
        platform,
        author_id
    }) {
        return request({
            url: `/jjt/overview/live/broadcast`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params: {
                author_id
            }
        }).then(res => {
            res = res.data
            let newRes = {
                code: res.code,
                data: {
                    ...res.data,
                    xData: res.data.map(item => {
                        return item.publish_time.slice(5, 11)
                    }).reverse(),
                    watch_count: res.data.map(item => {
                        return item.statistics_total_watch_user_time
                    }).reverse(),
                    interaction_count: res.data.map(item => {
                        return item.interaction_total || 0
                    }).reverse(),
                    title: res.data.map(item => {
                        return item.title || '-'
                    }).reverse()
                },
                msg: res.msg
            }
            return newRes
        })
    },
    // 区域列表
    getAreaList() {
        return request({
            url: `/jjt/dealer/dealers/region-list`,
            method: 'get',
            baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
        }).then(res => {
            let list = res.data.data.region_list || []
            if (res.data.data.type == 10) {
                list.unshift({
                    id: '',
                    name: '不限大区'
                })
            }
            list.map(item => {
                item.value = item.id
                item.label = item.name
            })
            return list
        })
    },
    getRegionPerspective({
        platform,
        region_id,
        principal_id
    }) {
        return request({
            url: `/jjt/overview/dealer/region-perspective`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params: {
                region_id,
                principal_id
            }
        }).then(res => {
            res = res.data
            return res
        })
    },
    getRegionRank({
        platform,
        region_id,
        principal_id
    }) {
        return request({
            url: `/jjt/overview/dealer/author-rank`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params: {
                region_id,
                principal_id
            }
        }).then(res => {
            res = res.data
            return res
        })
    },
    getAwemeRank({
        platform
    }) {
        return request({
            url: `/jjt/overview/awemeWeekRank`,
            method: 'get',
            baseURL: getBaseUrl(platform),
        }).then(res => {
            res = res.data
            return res
        })
    },
    getLiveRank({
        platform
    }) {
        return request({
            url: `/jjt/overview/liveWeekRank`,
            method: 'get',
            baseURL: getBaseUrl(platform),
        }).then(res => {
            res = res.data
            return res
        })
    },
    getBestAuthor({
        platform
    }) {
        return request({
            url: `/jjt/overview/bestAuthor`,
            method: 'get',
            baseURL: getBaseUrl(platform),
        }).then(res => {
            res = res.data
            return res
        })
    },
    // 获取地图数据-todoD
    getMapData({
      principal_id,
      platform
    }) {
      return request({
        url: '/jjt/live-map/dealer-list',
        method: 'GET',
        baseURL: getBaseUrl(platform),
        params: {
          principal_id
        }
      }).then(res => {
        res = res.data
  
        let newData = res.data
  
        let geoCoordMap = {}
        let data = []
        if (newData && newData.live_online_dealer_info_pos.length > 0) {
            newData.live_online_dealer_info_pos.forEach((item) => {
                geoCoordMap[item.nickname] = [item.lng, item.lat]
                data.push({
                  'name': item.nickname,
                  'value': platform == 'kuaishou' ? item.unify_online_cnt : item.unify_watch_cnt,
                  'cover': item.cover_url
                })
              })
        }
        newData.geoCoordMap = geoCoordMap
        newData.data = data
        return newData
      })
    },
    getChampions({
        platform,
        principal_id
    }) {
        return request({
            url: `/jjt/overview/champions`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params: {
                principal_id
            }
        }).then(res => {
            res = res.data
            return res
        })
    },
    getRankingTrend({
        platform,
        params
    }) {
        return request({
            url: `/jjt/overview/all/brank/ranking/trend`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params
        }).then(res => {
            res = res.data
            return res
        })
    },
    getOfficialTrend({
        platform,
        params
    }) {
        return request({
            url: `/jjt/overview/brandOfficialRanks/trend`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params
        }).then(res => {
            res = res.data
            return res
        })
    },
}
