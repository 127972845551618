import api from "@/api/hangUpMonitoring";
import tagApi from "@/api/illegalTag";

const {
  getAreaInfo,
  getAllGroup,
  getMonitorList,
  getLiveList,
  getVideoList,
} = api;

export default {
  namespaced: true,

  state: {
    areaData: [],
    groupData: [],
    monitorTagList: [],
    monitorData: {
      live_monitor_infos: {
        list: [],
      },
      xData: [],
      ratioData: [],
      countData: [],
    },
    liveData: null,
    videoData: null,
  },

  actions: {
    // 获取大区 省市
    getAreaInfo({ commit }, payload) {
      return getAreaInfo().then((res) => {
        console.log("getAreaInfo res", res);
        commit("changeState", { name: "areaData", data: res.data });
      });
    },
    // 获取全部分组
    getAllGroup({ commit }, payload) {
      return getAllGroup().then((res) => {
        console.log("getAllGroup res", res);
        commit("changeState", { name: "groupData", data: res.data });
      });
    },
    getMonitorTag({ commit }, payload) {
      return tagApi.getIllegalTag(payload).then((res) => {
        commit("changeState", { name: "monitorTagList", data: res.data.data });
      });
    },
    // 获取直播排行列表
    getMonitorList({ commit }, payload) {
      let {
        region_id,
        group_code,
        start_time,
        end_time,
        suspicion_type,
        field,
        page_num,
        page_size,
        platform,
      } = payload;
      return getMonitorList({
        region_id,
        group_code,
        start_time,
        end_time,
        suspicion_type,
        field,
        page_num,
        page_size,
        platform,
      }).then((res) => {
        console.log("getMonitorList res", res);
        commit("changeState", { name: "monitorData", data: res.data });
      });
    },
    // 获取直播排行列表
    getLiveList({ commit }, payload) {
      let {
        region_id,
        group_code,
        start_time,
        end_time,
        current,
        size,
        platform,
      } = payload;
      return getLiveList({
        region_id,
        group_code,
        start_time,
        end_time,
        current,
        size,
        platform,
      }).then((res) => {
        console.log("getLiveList res", res);
        commit("changeState", { name: "liveData", data: res.data });
      });
    },
    // 获取视频排行列表
    getVideoList({ commit }, payload) {
      let {
        region_id,
        group_code,
        start_time,
        end_time,
        current,
        size,
        platform,
      } = payload;
      return getVideoList({
        region_id,
        group_code,
        start_time,
        end_time,
        current,
        size,
        platform,
      }).then((res) => {
        console.log("getVideoList res", res);
        commit("changeState", { name: "videoData", data: res.data });
      });
    },
  },

  mutations: {
    changeState(state, payload) {
      let { data, name } = payload;
      state[name] = data;
    },
  },
};
