import api from "@/api/videoMonitoring";
import tagApi from "@/api/illegalTag";

const { getAreaInfo, getAllGroup, getVideoList, getVideoChart } = api;

export default {
  namespaced: true,

  state: {
    areaData: [],
    groupData: [],
    monitorTagList: [],
    monitorData: {
      live_monitor_infos: {
        list: [],
      },
      xData: [],
      ratioData: [],
      countData: [],
    },
    videoListData: {},
    videoChartData: {},
  },

  actions: {
    // 获取大区 省市
    getAreaInfo({ commit }, payload) {
      return getAreaInfo().then((res) => {
        console.log("getAreaInfo res", res);
        commit("changeState", { name: "areaData", data: res.data });
      });
    },
    // 获取全部分组
    getAllGroup({ commit }, payload) {
      return getAllGroup().then((res) => {
        console.log("getAllGroup res", res);
        commit("changeState", { name: "groupData", data: res.data });
      });
    },
    getMonitorTag({ commit }, payload) {
      return tagApi.getIllegalTag(payload).then((res) => {
        commit("changeState", { name: "monitorTagList", data: res.data.data });
      });
    },
    // 获取列表
    getVideoList({ commit }, payload) {
      let {
        area_id,
        group_code,
        start_time,
        end_time,
        illegal_type,
        field,
        current,
        size,
        platform,
      } = payload;
      return getVideoList({
        area_id,
        group_code,
        start_time,
        end_time,
        illegal_type,
        field,
        current,
        size,
        platform,
      }).then((res) => {
        console.log("getVideoList res", res);
        commit("changeState", { name: "videoListData", data: res.data });
      });
    },
    // 获取列表
    getVideoChart({ commit }, payload) {
      let {
        area_id,
        group_code,
        start_time,
        end_time,
        illegal_type,
        field,
        current,
        size,
        platform,
      } = payload;
      return getVideoChart({
        area_id,
        group_code,
        start_time,
        end_time,
        illegal_type,
        field,
        current,
        size,
        platform,
      }).then((res) => {
        console.log("getVideoChart res", res);
        commit("changeState", { name: "videoChartData", data: res.data });
      });
    },
  },

  mutations: {
    changeState(state, payload) {
      let { data, name } = payload;
      state[name] = data;
    },
  },
};
