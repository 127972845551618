import api from '@/api/storeSort'
const {
    getLiveSort,
    getSortGroup,
} = api;
export default {
    namespaced: true,

    state: {
        tableData:[],
        groupList:[],
        taleLoading:false,
        tablePagination:{
            total:0,
        },
        multipleData:[]
    },
    actions: {
        getLiveSort({
            commit
        }, payload) {
            let {type,params} = payload;
            commit('changeState',{name:'taleLoading',data:true})
            return getLiveSort({params,type}).then(res => {
                if(res.data.code ==0 ){
                    let pagination = {
                        total:res.data.data.total,
                    }
                    commit('changeState',{name:'tableData',data:res.data.data.list})
                    commit('changeState',{name:'tablePagination',data:pagination})
                }
                commit('changeState',{name:'taleLoading',data:false})
            })
        },
        getSortGroup({
            commit
        }) {
            return getSortGroup().then(res => {
                console.log('分组',res)
                commit('changeState',{name:'groupList',data:res.data.data})
            })
        },
        // 相关经销商分组
        getDealerGroup({
            commit
        },payload){
            let {params} = payload;
            return getSortGroup(params).then(res => {
                commit('changeState',{name:'multipleData',data:res.data.data})
            })
        },
        
    },
    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}