import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
    //规模
    // 获取品牌列表
    getBrandsData() {
        return request({
            url: '/jjt/dy/principal/list',
            method: 'get'
        })
    },
    // 营销日历
    getCalendar({
        params
    }) {
        return request({
            url: '/jjt/market-observe/calendar',
            method: 'get',
            params,
        })
    },
    // 品牌直播日历
    getLiveCalendar({
        data,type
    }) {
        return request({
            url: '/jjt/market-observe/live-calendar',
            method: 'post',
            data,
            baseURL: getBaseUrl(type),
        })
    },
    // 营销日历详情
    getBrandLiveDetail({
        params
    }) {
        return request({
            url: '/jjt/market-observe/calendar-detail',
            method: 'get',
            params,
        })
    },
    // 根据品牌选择话题
    getTopics({
        params
    }) {
        return request({
            url: '/jjt/market-observe/topics-list',
            method: 'get',
            params,
        })
    },
    // 话题观察
    getTopicObserve({
        data
    }) {
        return request({
            url: '/jjt/market-observe/topic-observe-v2',
            method: "post",
            data,
        })
    },
    // 话题观察每日数据
    getObserveTopic({
        params
    }) {
        return request({
            url: '/jjt/market-observe/topic-observe-day-v2',
            method: "get",
            params,
        })
    },
    // 话题详情
    getTopicMessage({
        id
    }) {
        return request({
            url: '/jjt/market-observe/topic-basic-detail',
            method: 'get',
            params: {
                topic_id: id
            }
        })
    },
    // 话题详情图表
    getTopicDetailChart({
        id
    }) {
        return request({
            url: '/jjt/market-observe/topic-details',
            method: 'get',
            params: {
                topic_id: id
            }
        })
    }

}