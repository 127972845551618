<template>
  <div class="aft-tooltip-container">
    <a-tooltip placement="top" @click.stop="openTooltip">
      <template slot="title">
        <span>{{ text }}</span>
      </template>
      <svg-icon :icon-class="'tooltip-icon2'" class="tooltip-icon" />
    </a-tooltip>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import tooltipList from "@/utils/tooltipList";

export default {
  name: "AftTooltip",
  props: {
    code: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      text: this.getNameByCode(),
    };
  },
  computed: {
    ...mapState("setting", ["tooltipModalShow"]),
  },
  methods: {
    ...mapMutations("setting", ["setTooltipModal"]),
    openTooltip() {
      console.log("openTooltip");
      this.setTooltipModal(this.code);
    },
    getNameByCode() {
      if (this.code) {
        const obj = tooltipList.filter((item) => item.code == this.code);
        return obj[0].name + "说明";
      } else {
        return "指标说明";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.tooltip-icon {
  @include font_color("font_color50");
  @include background_color("background_color51");
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
}
.tooltip-icon:hover {
  @include font_color("font_color20");
  @include background_color("background_color83");
}
.tooltip-icon:active {
  @include font_color("font_color26");
  @include background_color("background_color84");
}
.aft-tooltip-container {
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  line-height: 1;
}
</style>
