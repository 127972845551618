import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
  // 直播详情
  getLiveDetail({
    room_id,
    platform
  }) {
    return request({
      url: `/jjt/live/detail`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        room_id
      }
    }).then(res => {
      res = res.data
      let data = {
        code: res.code,
        data: {
          open_user_info: res.data?.open_user_info || undefined,
          title: res.data?.title || '--',
          avatar: res.data?.avatar,
          nickname: res.data?.nickname,
          cover: res.data?.cover || "https://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images/defultCover.jpg",
          play_url: res.data?.play_url,
          statistics_comment_classify_analysis: res.data?.statistics_comment_classify_analysis || [],
          is_restore_completed: res.data?.is_restore_completed,
          url_state: res.data?.url_state,
          plat_form: res.data?.plat_form || '--',
          publish_time: res.data?.publish_time || '--',
          finish_time: res.data?.finish_time || '--',
          statistics_live_time: res.data?.statistics_live_time,
          statistics_total_comment_count: res.data?.statistics_total_comment_count,
          statistics_total_watch_user_count: res.data?.statistics_total_watch_user_count,
          statistics_total_watch_user_time: res.data?.statistics_total_watch_user_time,
          count_data: [
            {
              number: res.data?.statistics_live_time,
              title: "直播时长",
            },
            // {
            //   number: res.data.avg_watch_time,
            //   title: "人均观看时长",
            // },
            {
              number: res.data?.statistics_total_watch_user_count,
              title: "观看人数",
            },
            {
              number: res.data?.statistics_total_watch_user_time,
              title: "观看人次",
            },
            {
              number: res.data?.statistics_avg_online_user,
              title: "平均在线",
            },
            {
              number: res.data?.statistics_max_online_user,
              title: "在线峰值",
            },
            {
              number: res.data?.statistics_tota_like_count,
              title: "集赞总数",
            },
            {
              number: res.data?.statistics_total_comment_count,
              title: "评论总数",
            }
          ],
          statistics_even_life_time: res.data?.statistics_even_life_time || {
            comment_count: [],
            like_count: [],
            follow_count: [],
            time: []
          },
        },
        msg: res.msg
      }
      // todoD
      if (res.data?.plat_form == '抖音') {
        data.data.count_data.push({
          number: res.data?.statistics_fans_add,
          title: "单场增粉",
        })
      }
      return data
    })
  },
  // 视频详情
  getVideoDetail({
    aweme_id,
    platform
  }) {
    return request({
      url: `/jjt/aweme/${aweme_id}`,
      method: 'get',
      baseURL: getBaseUrl(platform),
    }).then(res => {
      res = res.data
      return ({
        code: res.code,
        data: {
          avatar: res.data.avatar || 'https://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images/defultImg.jpg',
          nickname: res.data.nickname || '--',
          aweme_status: res.data.aweme_status,
          platform_name: res.data.platform_name || '--',
          aweme_title: res.data.aweme_title || '--',
          aweme_url: res.data.aweme_url,
          share_url: res.data.share_url,
          aweme_id: res.data.aweme_id,
          aweme_type: res.data.aweme_type,
          is_aweme: res.data.is_aweme,
          aweme_cover: res.data.aweme_cover || "https://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images/defultCover.jpg",
          brand_names: res.data.brand_names || '--',
          serise_names: res.data.serise_names || '--',
          publish_time: res.data.publish_time || '--',
          count_data: [
            {
              number: res.data.digg_count,
              title: "点赞",
            },
            {
              number: res.data.comment_count,
              title: "评论",
            },
            {
              number: res.data.share_count,
              title: "分享",
            },
            {
              number: res.data.play_volume,
              title: "播放",
            },
          ],
          digg_tendency: {
            time: (res.data.digg_tendency || []).map(i => { return i.date }),
            total: (res.data.digg_tendency || []).map(i => { return i.count }),
            increment: (res.data.digg_tendency || []).map(i => { return i.countDi })
          },
          comment_tendency: {
            time: (res.data.comment_tendency || []).map(i => { return i.date }),
            total: (res.data.comment_tendency || []).map(i => { return i.count }),
            increment: (res.data.comment_tendency || []).map(i => { return i.countDi })
          },
          share_tendency: {
            time: (res.data.share_tendency || []).map(i => { return i.date }),
            total: (res.data.share_tendency || []).map(i => { return i.count }),
            increment: (res.data.share_tendency || []).map(i => { return i.countDi })
          },
          play_tendency: {
            time: (res.data.play_tendency || []).map(i => { return i.date }),
            total: (res.data.play_tendency || []).map(i => { return i.count }),
            increment: (res.data.play_tendency || []).map(i => { return i.countDi })
          },
        },
        msg: res.msg
      })

    })
  },

  //评论分析 - 直播间评论
  getCommentsDetail({
    room_id,
    platform
  }) {
    return request({
      url: `/jjt/live/comment/analysis`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        room_id
      }
    }).then(res => {
      return res.data
    })
  },

  //评论分析 - 直播间评论列表
  getCommentsList({
    room_id,
    platform,
    type,
    word,
    current,
    page_size
  }) {
    return request({
      url: `/jjt/live/${room_id}/comment`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        type,
        word,
        current,
        page_size
      }
    }).then(res => {
      //  res = res.data
      return res.data
    })
  },

  //评论分析 - 词云分析
  getHotwordsList({
    room_id,
    platform
  }) {
    return request({
      url: `/jjt/live/comment/hotwords`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        room_id
      }
    }).then(res => {
      return res.data
    })
  },

  //评论分析 - 地区分布
  getAreaList({
    room_id,
    platform
  }) {
    return request({
      url: `/jjt/live/audience/analysis/area`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        room_id
      }
    }).then(res => {
      return res.data
    })
  },

  //评论分析 - 性别分布
  getGenderList({
    room_id,
    platform
  }) {
    return request({
      url: `/jjt/live/audience/analysis/gender`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        room_id
      }
    }).then(res => {
      return res.data
    })
  },

  //车系热度
  getSeriesList({
    room_id,
    platform
  }) {
    return request({
      url: `/jjt/live/series/hot`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        room_id
      }
    }).then(res => {
      return res.data
    })
  },

  //触发解冻接口
  updeteRetore(data) {
    return request({
      url: `/jjt/brand/live-video/restore-asyn-and-update-retore-list`,
      method: 'post',
      baseURL: getBaseUrl('agg'),
      data
    }).then(res => {
      return res.data
    })
  },
  // 智能分析评论
  getIaCommentsList(data) {
    return request({
      url: `/jjt/live/intelligence-comments`,
      method: 'get',
      baseURL: getBaseUrl(data.platform),
      params: data.params
    }).then(res => {
      return res.data
    })
  },
  //直播复盘助手
  getReviewAssistant({
    room_id,
    platform
  }) {
    return request({
      url: `/jjt/live/review-assistant`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        room_id
      }
    }).then(res => {
      return res.data
    })
  },
  //直播复盘助手-巨量数据
  getReviewAssistantOcean({
    room_id,
    platform
  }) {
    return request({
      url: `/jjt/live/review-assistant/ocean`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        room_id
      }
    }).then(res => {
      return res.data
    })
  },


}
