import api from '@/api/dealerDetail'

const {
    getDealerDetail,
    getAccounts,
    getPlatformInfo,
    getDealerInfo,
    getDealerStatistics
} = api;

export default {
    namespaced: true,

    state: {
        dealerDetail: null,
        accountsData: null,
        platformInfo: null,
        dealerInfo: null,
        dealerStatisticsInfo: null
    },

    actions: {
        getDealerDetail({ commit }, payload) {
            let { dealer_id } = payload
            return getDealerDetail({
                dealer_id
            })
                .then((res) => {
                    console.log('getDealerDetail res', res)
                    commit('changeState', { name: 'dealerDetail', data: res.data })
                })
        },
        getAccounts({ commit }, payload) {
            let { dealer_id } = payload
            return getAccounts({
                dealer_id
            })
                .then((res) => {
                    console.log('getAccounts res', res)
                    commit('changeState', { name: 'accountsData', data: res.data })
                })
        },
        getPlatformInfo({ commit }, payload) {
            let { dealer_id } = payload
            return getPlatformInfo({
                dealer_id
            })
                .then((res) => {
                    console.log('getPlatformInfo res', res)
                    commit('changeState', { name: 'platformInfo', data: res.data })
                })
        },
        getDealerInfo({ commit }, payload) {
            let {
                platform,
                dealerId
            } = payload
            return getDealerInfo({
                platform,
                dealerId
            })
                .then((res) => {
                    console.log('getDealerInfo res', res)
                    commit('changeState', { name: 'dealerInfo', data: res.data })
                })
        },
        getDealerStatistics({ commit }, payload) {
            let {
                platform,
                dealerId
            } = payload
            return getDealerStatistics({
                platform,
                dealerId
            })
                .then((res) => {
                    console.log('getDealerStatistics res', res)
                    commit('changeState', { name: 'dealerStatisticsInfo', data: res.data })
                })
        },
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}
