import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}

function sum(arr) {
  var s = 0;
  for (var i = arr.length - 1; i >= 0; i--) {
      s += arr[i];
  }
  return s;
}

export default {
  // 矩阵价值信息
  getMatrixData({
    year,
    month,
    platform
  }) {
    return request({
      url: `/jjt/brand-matrix/matrixValue`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        year,
        month
      }
    }).then(res => {
      res = res.data
      return {
        code: res.code,
        data: {
          ...res.data,
          leftData: res.data.level_to_value[0].values.map((item) => {
            return item.value
          }),
          rightData1: res.data.level_to_value[1].values.map((item) => {
            return item.value
          }),
          rightData2: res.data.level_to_value[2].values.map((item) => {
            return item.value
          }),
          rightData3: res.data.level_to_value[3].values.map((item) => {
            return item.value
          }),
          rightData4: res.data.level_to_value[4].values.map((item) => {
            return item.value
          }),
          rightData5: res.data.level_to_value[5].values.map((item) => {
            return item.value
          }),
          rightData6: res.data.level_to_value[6].values.map((item) => {
            return item.value
          }),
        },
        msg: res.msg
      }
    })
  },
  // 营销&销售价值分布
  getDateChart({
    platform
  }) {
    return request({
      url: `/jjt/brand-matrix/distributedInfo`,
      method: 'get',
      baseURL: getBaseUrl(platform),
    }).then(res => {
      res = res.data
      return {
        code: res.code,
        data: {
          marketing_distributed: res.data.marketing_distributed.map(item => {
            return [item.date, item.value]
          }),
          sale_distributed: res.data.sale_distributed.map(item => {
            return [item.date, item.value]
          })
        },
        msg: res.msg
      }
    })
  },
}
