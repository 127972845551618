import Nr from "@/utils/NetworldRequest";
const request = new Nr().getAxios();
function getBaseUrl(serviceName) {
  return new Nr().getBaseUrl(serviceName);
}
export default {
  // 获取矩阵详情主播列表
  getMatrixList({
    account_type,
    pyramid_position,
    sore_type,
    current,
    page_size,
    platform,
  }) {
    return request({
      url: `/jjt/brand-matrix/infoAuthorDetail`,
      method: "get",
      baseURL: getBaseUrl(platform),
      params: {
        account_type,
        pyramid_position,
        sore_type,
        current,
        page_size,
      },
    }).then((res) => {
      res = res.data;
      return {
        code: res.code,
        data: {
          pagination:
            res.data && res.data.pagination
              ? res.data.pagination
              : {
                  current: 1,
                  total: 0,
                  pages: 1,
                  page_size: 20,
                },
          offical_count: res.data ? res.data.offical_count || 0 : 0,
          dealer_count: res.data ? res.data.dealer_count || 0 : 0,
          kol_count: res.data ? res.data.kol_count || 0 : 0,
          list: res.data
            ? res.data.list.map((item) => {
                return {
                  avatar:
                    item.avatar || require("@/assets/img/defult-avatar.png"),
                  nickName: item.nickname || "--",
                  code: item.code || "--",
                  author_id: item.author_id || "--",
                  interaction: item.interaction_total || 0,
                  fansCount: item.fans_count_total || 0,
                };
              })
            : [],
        },
        msg: res.msg,
      };
    });
  },
  // 获取矩阵详情基本信息
  getInfoDetail({ platform }) {
    return request({
      url: `/jjt/brand-matrix/infoDetail`,
      method: "get",
      baseURL: getBaseUrl(platform),
    }).then((res) => {
      res = res.data;
      return {
        code: res.code,
        data: {
          principal_name: res.data.principal_name || "-",
          principal_logo: res.data.principal_logo,
          offical_count: res.data.overview_all_list[0].offical_count || 0,
          dealer_count: res.data.overview_all_list[0].dealer_count || 0,
          kol_count: res.data.overview_all_list[0].kol_count || 0,
          fans_total: res.data.fans_total || 0,
          account_total: res.data.account_total || 0,
          live_total: res.data.live_total || 0,
          aweme_total: res.data.aweme_total || 0,
          interaction_total: res.data.interaction_total || 0,
          like_total: res.data.like_total || 0,
          comment_total: res.data.comment_total || 0,
          share_total: res.data.share_total || 0,
        },
        msg: res.msg,
      };
    });
  },
};
