import Nr from '@/utils/NetworldRequest'

const request = (new Nr).getAxios()

function getBaseUrl(serviceName) {
    return (new Nr).getBaseUrl(serviceName)
}

export default {
    // 获取区域
    getAreaList({
    }) {
        return request({
            url: `/jjt/dealer/dealers/area-list`,
            method: 'get',
            baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
        }).then(res => {
            res = res.data
            return res
        })
    },
    //基础信息
    getBasicInfo({
        params,
        platform
    }) {
        return request({
            url: `/jjt/activity/review/basic-info`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params,
        }).then(res => {
            res = res.data
            return res
        })
    },
    //视频表现- 概览
    getAwemeOverview({
        params,
        platform
    }) {
        return request({
            url: `/jjt/activity/review/awemeOverview`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params,
        }).then(res => {
            res = res.data
            return res
        })
    },
    //视频表现- 排行
    getAwemeRankList({
        params,
        platform
    }) {
        return request({
            url: `/jjt/activity/review/awemeRankList`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params,
        }).then(res => {
            res = res.data
            return res
        })
    },
    //视频表现- 爆款视频
    getHotAweme({
        params,
        platform
    }) {
        return request({
            url: `/jjt/activity/review/hotAweme`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params,
        }).then(res => {
            res = res.data
            return res
        })
    },
    //直播表现 - 概览
    getLiveOverview({
        params,
        platform
    }) {
        return request({
            url: `/jjt/activity/review/liveOverview`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params,
        }).then(res => {
            res = res.data
            return res
        })
    },
    //直播表现 - 排行
    getLiveRankList({
        params,
        platform
    }) {
        return request({
            url: `/jjt/activity/review/liveRankList`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params,
        }).then(res => {
            res = res.data
            return res
        })
    },
    //直播表现 - 爆款
    getHotLive({
        params,
        platform
    }) {
        return request({
            url: `/jjt/activity/review/hotLive`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params,
        }).then(res => {
            res = res.data
            return res
        })
    },
    //经销商表现 - 贡献
    getDealerContribution({
        params,
        platform
    }) {
        return request({
            url: `/jjt/activity/review/dealer-contribution`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params,
        }).then(res => {
            res = res.data
            return res
        })
    },
    //经销商表现 - 最佳门店
    getDealerBest({
        params,
        platform
    }) {
        return request({
            url: `/jjt/activity/review/best-stores`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params,
        }).then(res => {
            res = res.data
            return res
        })
    },
    //经销商表现 - 最佳
    getDealerTop({
        params,
        platform
    }) {
        return request({
            url: `/jjt/activity/review/best-live-aweme`,
            method: 'get',
            baseURL: getBaseUrl(platform),
            params,
        }).then(res => {
            res = res.data
            return res
        })
    },
}
