import api from '@/api/globalDetail'

const {
    getLiveDetail,
    getVideoDetail,
    getCommentsDetail,
    getCommentsList,
    getIaCommentsList,
    getHotwordsList,
    getAreaList,
    getGenderList,
    getSeriesList,
    getReviewAssistant,
    getReviewAssistantOcean
} = api;

export default {
    namespaced: true,

    state: {
        liveDetail: {
            statistics_even_life_time: {},
            url_state: ''
        },
        videoDetail: null,
        commentsData: null,
        commentsList: null,
        commentsLoading: false,
        iaCommentsList: null,
        iaCommentsLoading: false,
        // current: 1,
        // page_size: 25,
        // noMore: false,
        hotwordsList: null,
        areaList: {
            province: [],
            city: []
        },
        genderList: null,
        seriesList: null,
        reviewAssistantData: {},
        reviewAssistantOceanData: {}

    },

    actions: {
        // 获取直播详情
        getLiveDetail({ commit }, payload) {
            let {
                author_id,
                room_id,
                platform
            } = payload

            return getLiveDetail({
                author_id,
                room_id,
                platform
            }).then((res) => {
                commit('CHANGE_LIVE_DETAIL', { name: 'liveDetail', data: res.data })
            })
        },
        // 获取视频详情
        getVideoDetail({ commit }, payload) {
            let {
                aweme_id,
                platform
            } = payload

            return getVideoDetail({
                aweme_id,
                platform
            }).then((res) => {
                commit('changeState', { name: 'videoDetail', data: res.data })
            })
        },

        //评论分析 - 直播间评论
        getCommentsDetail({ commit }, payload) {
            let {
                room_id,
                platform
            } = payload


            return getCommentsDetail({
                room_id,
                platform
            }).then((res) => {
                commit('CHANGE_COMMENTS_DETAIL', { name: 'commentsData', data: res.data })
            })
        },
        //评论分析 - 直播间评论列表
        getCommentsList({ commit, state }, payload) {
            let {
                room_id,
                platform,
                type,
                word,
                current,
                page_size
            } = payload
            commit('changeState', { name: 'commentsLoading', data: true })

            return getCommentsList({
                room_id,
                platform,
                type,
                word,
                current,
                page_size
            }).then((res) => {
                commit('changeState', { name: 'commentsLoading', data: false })
                commit('changeState', { name: 'commentsList', data: res.data || {} })
            })
        },
        //智能分析评论
        getIaCommentsList({ commit, state }, payload) {
            let body = payload
            commit('changeState', { name: 'iaCommentsLoading', data: true })

            return getIaCommentsList(body).then((res) => {
                commit('changeState', { name: 'iaCommentsLoading', data: false })
                commit('changeState', { name: 'iaCommentsList', data: res.data || {} })
            })
        },
        //评论分析 - 词云分析
        getHotwordsList({ commit }, payload) {
            let {
                room_id,
                platform
            } = payload

            return getHotwordsList({
                room_id,
                platform
            }).then((res) => {
                if (res.code === 0) {
                    commit('changeState', { name: 'hotwordsList', data: res.data })
                } else {
                    commit('changeState', { name: 'hotwordsList', data: [] })
                }
            })
        },
        //评论分析 - 地区分布
        getAreaList({ commit }, payload) {
            let {
                room_id,
                platform
            } = payload

            return getAreaList({
                room_id,
                platform
            }).then((res) => {
                if (res.code === 0) {
                    commit('changeState', { name: 'areaList', data: res.data })
                } else {
                    commit('changeState', {
                        name: 'areaList', data: {
                            province: [],
                            city: []
                        }
                    })
                }
            })
        },
        //评论分析 - 性别分布
        getGenderList({ commit }, payload) {
            let {
                room_id,
                platform
            } = payload

            return getGenderList({
                room_id,
                platform
            }).then((res) => {
                if (res.code === 0) {
                    commit('changeState', { name: 'genderList', data: res.data })
                } else {
                    commit('changeState', { name: 'genderList', data: [] })
                }
            })
        },
        //车型top5
        getSeriesList({ commit }, payload) {
            let {
                room_id,
                platform
            } = payload

            return getSeriesList({
                room_id,
                platform
            }).then((res) => {
                commit('CHANGE_SERIES_DETAIL', { name: 'seriesList', data: res.data })
            })
        },
        //直播复盘助手
        getReviewAssistant({ commit }, payload) {
            let {
                room_id,
                platform
            } = payload

            return getReviewAssistant({
                room_id,
                platform
            }).then((res) => {
                if (res.code === 0) {
                    let newData = {
                        ...res.data,
                        review: res.data.live_video_record.map(item => {
                            if (item) {
                                return 0
                            } else {
                                return null
                            }
                        })
                    }
                    commit('changeState', { name: 'reviewAssistantData', data: newData })
                } else {
                    commit('changeState', { name: 'reviewAssistantData', data: {} })
                }
            })
        },
        //直播复盘助手-巨量数据
        getReviewAssistantOcean({ commit }, payload) {
            let {
                room_id,
                platform
            } = payload

            return getReviewAssistantOcean({
                room_id,
                platform
            }).then((res) => {
                if (res.code === 0) {
                    let newData = {
                        ...res.data,
                        review: res.data && res.data.live_video_record.map(item => {
                            if (item) {
                                return 0
                            } else {
                                return null
                            }
                        })
                    }
                    commit('changeState', { name: 'reviewAssistantOceanData', data: newData })
                } else {
                    commit('changeState', { name: 'reviewAssistantOceanData', data: {} })
                }
            })
        },
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        },

        CHANGE_LIVE_DETAIL(state, data) {
            let _data = data.data
            if (_data.finish_time) {
                let date = _data.finish_time.split(' ')
                _data.full_date = date[0]
                _data.new_finish_time = date[1]
            }
            if (_data.publish_time) {
                let date = _data.publish_time.split(' ')
                _data.new_publish_time = date[1]
            }
            state.liveDetail = _data
        },

        CHANGE_COMMENTS_DETAIL(state, data) {
            let _data = data.data
            if (_data && _data.types && _data.types.length > 0) {
                _data.types.map(item => {
                    if (item.type === 'CLUE') {
                        _data.clues_count = item.count
                    }
                })
            }

            state.commentsData = _data
        },

        CHANGE_SERIES_DETAIL(state, data) {
            let _data = data.data
            if (_data) {
                let count = _data.map(item => item.count);
                count.sort(function (a, b) {
                    return b - a;
                })
                _data.map(item => {
                    item.image = process.env.VUE_APP_LIVE_OSS_HOST + '/' + item.image;
                    item.rate = item.count / count[0] * 80
                })
            }

            state.seriesList = _data || []
        },
    },
}
