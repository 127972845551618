import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
export default {
    // 获取区域
    getDealerArea({
    }) {
        return request({
            url: `/jjt/dealer/dealers/region-list`,
            method: 'get',
            baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
        }).then(res => {
            let list = []
            list = res.data.data.region_list || []
            if (res.data.data.type == 10) {
                list.unshift({
                    id: '',
                    name: '不限大区'
                })
            }
            list.map(item => {
                item.value = item.id
                item.label = item.name
            })
            return list
        })
    },

    //经销商列表
    getDealerList({
        region_id,
        group_code,
        word,
        size,
        current
    }) {
        return request({
            url: `/jjt/dealer/dealers/dealer-list`,
            method: 'get',
            baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
            params: {
                region_id,
                group_code,
                word,
                size,
                current
            }
        }).then(res => {
            return res.data.data
        })
    },

    //为匹配账号门店列表
    getUnmatchDealerList({
        region_id,
        word,
        group_code,
        size,
        current
    }) {
        return request({
            url: `/jjt/dealer/dealers/unmatch-dealer-list`,
            method: 'get',
            baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
            params: {
                region_id,
                word,
                group_code,
                size,
                current
            }
        }).then(res => {
            return res.data.data
        })
    },

    //门店详情
    getDealerDetail({
        dealer_id
    }) {
        return request({
            url: `/jjt/dealer/dealers/dealer-detail/${dealer_id}`,
            method: 'get',
            baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
        }).then(res => {
            return res.data.data
        })
    },

    // 门店授权
    getDealerAuthorize({
        dealer_id
    }) {
        return request({
            url: `/jjt/dealer/dealers/dealer-platform/${dealer_id}`,
            method: 'get',
            baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
        }).then(res => {
            if(res.data.code == 0) {
                let data = res.data.data
                let list = []
                
                data.douyinlist.map(item => {
                    item.platform = '抖音'
                    list.push(item)
                })
                data.kuaishoulist.map(item => {
                    item.platform = '快手'
                    list.push(item)
                })
                data.dongchediList.map(item => {
                    item.platform = '懂车帝'
                    list.push(item)
                })

                return list
            } else {
                return []
            }
        })
    },
}