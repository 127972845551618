import api from '@/api/storeTrend'
const {
    getSortGroup,
    getAreaList,
    getTrendList,
    getTrendTotal
} = api;
export default {
    namespaced: true,

    state: {
        groupList: [],
        areaList: [],
        trendList: {
            list: [],
            pagination: {}
        },
        trendTotalData: {},
        trendTotalCode: 0
    },
    actions: {
        getSortGroup({
            commit
        }) {
            return getSortGroup().then(res => {
                commit('changeState', { name: 'groupList', data: res.data.data })
            })
        },
        getAreaList({ commit }, payload) {
            commit('changeState', { name: 'areaList', data: [] })
            return getAreaList()
                .then((res) => {
                    commit('changeState', { name: 'areaList', data: res })
                })
        },
        getTrendList({ commit }, payload) {
            commit('changeState', {
                name: 'trendList', data: {
                    list: [],
                    pagination: {}
                }
            })
            return getTrendList(payload)
                .then((res) => {
                    commit('changeState', { name: 'trendList', data: res })
                })
        },
        getTrendTotal({ commit }, payload) {
            commit('changeState', { name: 'trendTotalData', data: {} })
            return getTrendTotal(payload)
                .then((res) => {
                    commit('changeState', { name: 'trendTotalData', data: res.data })
                    commit('changeState', { name: 'trendTotalCode', data: res.code })
                })
        },

    },
    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}