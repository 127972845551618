import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
  getMonitList({
    current,
    size
  }) {
    return request({
      url: `/jjt/brand-matrix/exception-monit`,
      method: 'get',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      params: {
        current,
        size
      }
    }).then(res => {
      res = res.data
      let newRes = {
        code: res.code,
        data: res.data,
        msg: res.msg
      }
      return newRes
    })
  },
  getNewMonitList({
    start_time,
    end_time,
    current,
    size,
    platform
  }) {
    return request({
      url: `/jjt/matrix/monitor/notice/list`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        start_time,
        end_time,
        current,
        size,
        platform
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 最新异动列表
  getMonitNewList({
    platform,
    params
  }) {
    return request({
      url: `/jjt/matrix/monitor/notice/new/list`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 历史异动列表
  getMonitHistoryList({
    platform,
    current,
    size,
    tag_code,
    exception_type
  }) {
    return request({
      url: `/jjt/matrix/monitor/notice/history/list`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        current,
        size,
        tag_code,
        exception_type
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 异动详情
  getMonitDetail({
    platform,
    indicator_type,
    abnormal_type,
    end_time_str,
    author_id_array
  }) {
    return request({
      url: `/jjt/matrix/monitor/notice/anomaly-detail`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        indicator_type,
        abnormal_type,
        end_time_str,
        author_id_array
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
  getMonitorTag() {
    return request({
      url: `/jjt/matrix/monitor/notice/tag`,
      method: 'get',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API
    }).then(res => {
      return res.data
    })
  },
}