import api from '@/api/modelPerformanceN'

const {
    getSeriesList,
    getVolumeRanking
} = api;

export default {
    namespaced: true,

    state: {
        tableLoading: false,
        seriesList: [],
        rankingData: {}
    },

    actions: {
        // 车系表现列表
        getSeriesList({ commit }, payload) {
            let {
                type,
                start_time,
                end_time,
                platform
            } = payload

            commit('changeState', { name: 'tableLoading', data: true })

            return getSeriesList({
                type,
                start_time,
                end_time,
                platform
            })
                .then((res) => {
                    commit('changeState', { name: 'tableLoading', data: false })
                    console.log('getSeriesList res', res)
                    commit('changeState', { name: 'seriesList', data: res.data })
                })
        },
        // 声量排行
        getVolumeRanking({ commit }, payload) {
            let {
                type,
                start_time,
                end_time,
                series_id,
                platform
            } = payload
            return getVolumeRanking({
                type,
                start_time,
                end_time,
                series_id,
                platform
            })
                .then((res) => {
                    console.log('getVolumeRanking res', res)
                    commit('changeState', { name: 'rankingData', data: res.data })
                })
        },
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}
