import Nr from "@/utils/NetworldRequest";
const request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_BFF_API,
}).getAxios();

export const getRegionVillageLisNoPermissionApi = (params) => {
  return request.get("/regionVillageRole/manufactorPermissionList", {
    params,
  });
};

// 有权限控制
export const getRegionVillagePermissionListApi = (params) => {
  return request.get("/regionVillageRole/regionVillagePermissionList", {
    params,
  });
};
