import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
  // 获取大区 省市
  getAreaInfo() {
    return request({
      url: `/jjt/dealer/dealers/region-info`,
      method: 'get',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 获取全部分组
  getAllGroup() {
    return request({
      url: `/jjt/group/selectAllGroup`,
      method: 'get',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 获取门店统计列表
  getCompetitionList({
    is_dealer,
    word,
    area_id,
    province_city_id,
    group_code,
    verification_type,
    begin_time,
    end_time,
    current,
    page_size,
    platform,
    type,
    aweme_play_volume,
    live_time
  }) {
    return request({
      url: `/jjt/dealers/dealer-competition`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: {
        is_dealer,
        word,
        area_id,
        province_city_id,
        group_code,
        verification_type,
        begin_time,
        end_time,
        current,
        page_size,
        type,
        aweme_play_volume,
        live_time
      }
    }).then(res => {
      res = res.data
      return res
    })
  }
}
