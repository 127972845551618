// import request from '@/utils/NetworldRequest'
import { getPrincipalId } from "@/utils/auth";
import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}

export default {
  // 获取mqtt配置
  getMqttConfig({
    platform_type,
    author_id,
    device_id
  }) {
    return request({
      url: '/jjt/mqtt/live/client-setting',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_MQTT_API,
      params: {
        platform_type,
        author_id,
        device_id
      }
    }).then(res => {
      res = res.data
      return res
    })
  },
  // 添加直播监听
  addLiveRoom({
    platform_type,
    room_id
  }) {
    return request({
      url: '/jjt/mqtt/live/add-live-room',
      method: 'POST',
      baseURL: process.env.VUE_APP_OPENDATA_MQTT_API,
      data: {
        platform_type,
        room_id
      }
    });
  },
  // 获取在线数
  getOnlineCount({
    roomId,
    platform
  }) {
    return request({
      url: '/mqtt/live/onlineCount',
      method: 'GET',
      baseURL: getBaseUrl(platform),
      params: {
        roomId
      }
    });
  },
  // 获取主播粉丝数
  getFansCount({
    authorId,
    platform
  }) {
    return request({
      url: '/mirror-data/douyin/author/fans-count',
      method: 'GET',
      baseURL: getBaseUrl(platform),
      params: {
        authorId
      }
    });
  },
  // 获取主播粉丝数 和 累计观看人数 new
  getAllCount({
    platformType,
    roomId,
    platform
  }) {
    return request({
      url: `/mqtt/live/increase-fans-watching-count`,
      method: 'GET',
      baseURL: getBaseUrl(platform),
      params: {
        platformType,
        roomId
      }
    });
  },
  // 获取地图数据-todoD
  getMapData(payload) {
    return request({
      url: '/live/online/map',
      method: 'GET',
      baseURL: process.env.VUE_APP_OPENDATA_BFF_API,
      params: {
        principalId: payload.principalId,
        platform: payload.platform === 'douyin' ? 1 : 2,
        regionIds: payload.regionIds,
        villageIds: payload.villageIds,
      }
    }).then(res => {
      res = res.data

      let newData = res.data
      newData.live_online_dealer_info_pos = res.data.live_online_list
      let geoCoordMap = {}
      let data = []
      newData.live_online_dealer_info_pos.forEach((item) => {
        geoCoordMap[item.nickname] = [item.lng, item.lat]
        data.push({
          'name': item.nickname,
          'value': item.unify_watch_cnt,
          'cover': item.cover
        })
      })
      newData.geoCoordMap = geoCoordMap
      newData.data = data
      return newData
    })
  },
  // 获取直播是否下播
  getLiveDetail({
    author_id,
    room_id,
    platform
  }) {
    return request({
      url: `/jjt/live-map/live-detail`,
      method: 'GET',
      baseURL: getBaseUrl(platform),
      params: {
        author_id,
        room_id
      }
    });
  },
  // 区域列表
  getAreaList(){
    return request({
      url:'/jjt/dealer/dealers/region-list',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      method:'get',
    })
  },
}
