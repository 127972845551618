import api from '@/api/regionContrast'

const {
    getAreaList,
    getEnemyPrincipal,
    getInfo,
    getInfoById
} = api;

export default {
    namespaced: true,

    state: {
        regionData: [],
        enemyData: [],
        infoData: {},
        otherInfoData: {},
    },

    actions: {
        // 经销商区域
        getAreaList({ commit }, payload) {
            // let {
            // } = payload
            commit('changeState', { name: 'infoData', data: {} })
            commit('changeState', { name: 'otherInfoData', data: {} })

            return getAreaList({
            })
                .then((res) => {
                    console.log('regionData', res)
                    commit('changeState', { name: 'regionData', data: res.data || {} })
                })
        },
        // 获取本品的竞品列表(品牌矩阵)
        getEnemyPrincipal({ commit }, payload) {
            // let {
            // } = payload

            return getEnemyPrincipal({
            })
                .then((res) => {
                    console.log('enemyData', res)
                    commit('changeState', { name: 'enemyData', data: res.data || [] })
                })
        },
        getInfo({ commit }, payload) {
            let {
                area_id,
                year,
                month,
                platform
            } = payload

            return getInfo({
                area_id,
                year,
                month,
                platform
            })
                .then((res) => {
                    console.log('infoData', res)
                    commit('changeState', { name: 'infoData', data: res.data })
                })
        },
        getInfoById({ commit }, payload) {
            let {
                area_id,
                principal_id,
                year,
                month,
                platform
            } = payload

            return getInfoById({
                area_id,
                principal_id,
                year,
                month,
                platform
            })
                .then((res) => {
                    console.log('otherInfoData', res)
                    commit('changeState', { name: 'otherInfoData', data: res.data })
                })
        },
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}
