import api from '@/api/brandSummary'

const {
    getBrandRanking,
    getBrandOfficialRanks,
    getLiveTopTen,
    getAreaList,
    getRegionPerspective,
    getRegionRank,
    getAwemeRank,
    getLiveRank,
    getBestAuthor,
    getMapData,
    getChampions,
    getRankingTrend,
    getOfficialTrend
} = api;

export default {
    namespaced: true,

    state: {
        rankingList: {
            list: []
        },
        officialList: {
            author: {},
            list: []
        },
        liveChartData: [],
        areaList: [],
        perspectiveData: {},
        rankList: [],
        awemeRankList: [],
        liveRankList: [],
        bestAuthorList: null,
        mapData: {
            data: []
        },
        championsData: {
            aweme: {},
            live: {},
            market_value: {}
        },
        rankingLoading: false,
        areaLoading: false,
        officialLoading: false,
        regionLoading: false,
        weekLoading: false,
        anchorLoading: false,
        rankingTrendData: [],
        officialTrendData: [],
    },

    actions: {
        getBrandRanking({ commit }, payload) {
            let {
                platform,
                principal_id
            } = payload
            // 初始化
            commit('changeState', { name: 'rankingLoading', data: true })
            commit('changeState', {
                name: 'rankingList', data: {
                    list: []
                }
            })
            return getBrandRanking({
                platform,
                principal_id
            })
                .then((res) => {
                    console.log('getBrandRanking res', res)
                    commit('changeState', { name: 'rankingLoading', data: false })
                    commit('changeState', { name: 'rankingList', data: res.data })
                })
        },
        getBrandOfficialRanks({ commit }, payload) {
            let {
                platform,
                author_id,
                principal_id
            } = payload
            commit('changeState', { name: 'officialLoading', data: true })
            commit('changeState', {
                name: 'officialList', data: {
                    author: {},
                    list: []
                }
            })
            return getBrandOfficialRanks({
                platform,
                author_id,
                principal_id
            })
                .then((res) => {
                    console.log('getBrandOfficialRanks res', res)
                    commit('changeState', { name: 'officialLoading', data: false })
                    commit('changeState', { name: 'officialList', data: res.data })
                })
                .catch(err => {
                    commit('changeState', { name: 'officialLoading', data: false })
                })
        },
        getLiveTopTen({ commit }, payload) {
            let {
                platform,
                author_id
            } = payload
            return getLiveTopTen({
                platform,
                author_id
            })
                .then((res) => {
                    console.log('getLiveTopTen res', res)
                    commit('changeState', { name: 'liveChartData', data: res.data })
                })
        },
        getAreaList({ commit }, payload) {
            commit('changeState', { name: 'areaList', data: [] })
            return getAreaList()
                .then((res) => {
                    console.log('getAreaList res', res)
                    commit('changeState', { name: 'areaList', data: res })
                })
        },
        getRegionPerspective({ commit }, payload) {
            let {
                platform,
                region_id,
                principal_id
            } = payload
            return getRegionPerspective({
                platform,
                region_id,
                principal_id
            })
                .then((res) => {
                    console.log('getRegionPerspective res', res)
                    commit('changeState', { name: 'perspectiveData', data: res.data })
                })
        },
        getRegionRank({ commit }, payload) {
            let {
                platform,
                region_id,
                principal_id
            } = payload
            commit('changeState', { name: 'regionLoading', data: true })
            commit('changeState', { name: 'rankList', data: [] })
            return getRegionRank({
                platform,
                region_id,
                principal_id
            })
                .then((res) => {
                    console.log('getRegionRank res', res)
                    commit('changeState', { name: 'regionLoading', data: false })
                    commit('changeState', { name: 'rankList', data: res.data || [] })
                })
        },
        getAwemeRank({ commit }, payload) {
            let {
                platform
            } = payload
            commit('changeState', { name: 'weekLoading', data: true })
            commit('changeState', { name: 'awemeRankList', data: [] })

            return getAwemeRank({
                platform
            })
                .then((res) => {
                    console.log('getAwemeRank res', res)
                    commit('changeState', { name: 'weekLoading', data: false })
                    commit('changeState', { name: 'awemeRankList', data: res.data || [] })
                })
        },
        getLiveRank({ commit }, payload) {
            let {
                platform
            } = payload
            commit('changeState', { name: 'weekLoading', data: true })
            commit('changeState', { name: 'liveRankList', data: [] })
            return getLiveRank({
                platform
            })
                .then((res) => {
                    console.log('getLiveRank res', res)
                    commit('changeState', { name: 'weekLoading', data: false })
                    commit('changeState', { name: 'liveRankList', data: res.data || [] })
                })
        },
        getBestAuthor({ commit }, payload) {
            let {
                platform
            } = payload
            commit('changeState', { name: 'anchorLoading', data: true })
            commit('changeState', { name: 'bestAuthorList', data: null })
            return getBestAuthor({
                platform
            })
                .then((res) => {
                    console.log('getBestAuthor res', res)
                    commit('changeState', { name: 'anchorLoading', data: false })
                    commit('changeState', { name: 'bestAuthorList', data: res.data || null })
                })
        },
        // 获取地图数据
        getMapData({ commit }, payload) {
            let {
                principal_id,
                platform
            } = payload;
            commit('changeState', { name: 'areaLoading', data: true })
            // commit('changeState', {
            //     name: 'mapData', data: {
            //         data: []
            //     }
            // })
            return getMapData({
                principal_id,
                platform
            }).then((res) => {
                console.log('getMapData res', res)
                commit('changeState', { name: 'areaLoading', data: false })
                commit('changeState', { name: 'mapData', data: res })
            })
        },
        getChampions({ commit }, payload) {
            let {
                platform,
                principal_id
            } = payload
            commit('changeState', {
                name: 'championsData', data: {
                    aweme: {},
                    live: {},
                    market_value: {}
                }
            })
            return getChampions({
                platform,
                principal_id
            })
                .then((res) => {
                    console.log('getChampions res', res)
                    commit('changeState', { name: 'championsData', data: res.data })
                })
        },
        getRankingTrend({ commit }, payload) {
            let {
                platform,
                params
            } = payload
            return getRankingTrend({
                platform,
                params
            })
                .then((res) => {
                    console.log('getRankingTrend res', res)
                    commit('changeState', { name: 'rankingTrendData', data: res.data })
                })
        },
        getOfficialTrend({ commit }, payload) {
            let {
                platform,
                params
            } = payload
            return getOfficialTrend({
                platform,
                params
            })
                .then((res) => {
                    console.log('getOfficialTrend res', res)
                    commit('changeState', { name: 'officialTrendData', data: res.data })
                })
        },
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}
