import Vue from "vue";
import { trackRequest, trackEvent } from "@/utils/track.js";

// 大众临时需求,隐藏导出按钮
Vue.directive("hide-by-username", {
  bind(el, binding) {
    const valueToHide = binding.value;
    if ("18721561085" === localStorage.jjt_username) {
      el.style.display = "none";
    }
  },
});

// 按钮埋点
Vue.directive("track", {
  bind(el, binding, vNode) {
    const event = binding.arg || trackEvent.CLICK;
    if (binding.value.disabled) return;
    const bindFunction = () => {
      delete el._bindValue.disabled;
      trackRequest(vNode.context.$route, event, {
        ...el._bindValue,
      });
    };
    el._bindValue = binding.value;
    el._bindFunction = bindFunction;
    el.addEventListener(event, bindFunction);
  },
  update(el, binding) {
    if (binding.value.disabled) return;
    el._bindValue = binding.value;
  },
});
