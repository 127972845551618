import {
  getUserId,
  getPrincipalName,
  getPrincipalId,
  getName,
  getUserName,
} from "@/utils/auth";
import api from "@/api/auth";
import { getRegionTrackData, setRegionTrackData } from "@/utils/auth.js";
import Nr from "@/utils/NetworldRequest";
const request = new Nr().getAxios();

// 发送埋点接口
export const postBuriedPoint = (data) => {
  return request({
    url: "/v1/point-all/add",
    method: "post",
    baseURL: new Nr().getBaseUrl("tracking"),
    data,
  });
};

/**
 * 事件类型
 * @enum {string}
 */
export const trackEvent = {
  LOGIN: "jjt_login",
  PAGE_VIEW: "page_view",
  CLICK: "click",
  DBLCLICK: "dblclick",
};
/**
 * @param {object} route 埋点路由
 * @param {object} options 埋点参数
 * @param {trackEvent} event 事件名称
 * @param {trackEvent} options.event kv事件名称
 * @param {string} [options.action] 操作名称
 * @param {number} [options.default] 默认选中 1是, 0否
 * @param {string} [options.tabsName] 选中标签页名称
 */
export const trackRequest = async (route, event, options = {}) => {
  const principalId = getPrincipalId();
  if (!principalId) return;
  // 如果没有数据,获取
  let regionTrackData = getRegionTrackData();
  const errorInfo = {};
  if (!regionTrackData.account_type) {
    try {
      const res = await api.getRegionUserInfo({ principalId });
      if (res.data.code === 200) {
        const regionUserInfo = res.data.data;
        setRegionTrackData({
          account_type: regionUserInfo.accountType,
          username: regionUserInfo.username,
          mobile: regionUserInfo.mobile,
          region_village:
            regionUserInfo.accountType === 10 ? [] : regionUserInfo.region,
        });
        regionTrackData = getRegionTrackData();
      } else {
        errorInfo.error = "获取区域用户信息失败";
      }
    } catch (error) {
      console.log(error);
      errorInfo.error = "获取区域用户信息失败";
    }
  }

  const data = {
    ap: "new-jjt",
    cm: {
      muid: 6,
      rn: getName(),
      ub: getPrincipalName(),
      ubid: principalId,
      upid: principalId,
      upn: getPrincipalName(),
      uid: getUserId(),
      un: getUserName(),
      t: Date.now(),
    },
    et: {
      en: event,
      kv: {
        source_type: "1", // 1web 2小程序
        ...regionTrackData,
        page_name: route.name,
        page_path: route.path,
        ...options,
        ...errorInfo,
      },
    },
  };
  console.log("埋点数据:", data);
  postBuriedPoint(data);
};
