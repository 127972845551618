import api from '@/api/modelDetail'

const {
    getSeriesDetail,
    getCompetitorChart,
    getVideoList,
    getEmotion,
    getCommentList
} = api;

export default {
    namespaced: true,

    state: {
        detailData: null,
        competitorData: null,
        videoData: null,
        emotionData: null,
        commentList: null,
        current: 1,
        page_size: 10,
        page_size: 10,
        listLoading: false,
        listNoMore: false,
    },

    actions: {
        // 获取车系详情、热门话题
        getSeriesDetail({ commit }, payload) {
            let {
                series_id,
                statistics_begin_date,
                statistics_end_date,
                platform
            } = payload
            return getSeriesDetail({
                series_id,
                statistics_begin_date,
                statistics_end_date,
                platform
            })
                .then((res) => {
                    console.log('getSeriesDetail res', res)
                    commit('changeState', { name: 'detailData', data: res.data })
                })
        },
        // 获取竞品趋势
        getCompetitorChart({ commit }, payload) {
            let {
                series_id,
                statistics_begin_date,
                statistics_end_date,
                platform
            } = payload
          
            return getCompetitorChart({
                series_id,
                statistics_begin_date,
                statistics_end_date,
                platform
            })
                .then((res) => {
                    console.log('getCompetitorChart res', res)
                    commit('changeState', { name: 'competitorData', data: res.data })
                })
        },
        getVideoList({ commit }, payload) {
            let {
                series_id,
                statistics_begin_date,
                statistics_end_date,
                platform
            } = payload
          
            return getVideoList({
                series_id,
                statistics_begin_date,
                statistics_end_date,
                platform
              })
                .then((res) => {
                    console.log('getVideoList res', res)
                    commit('changeState', { name: 'videoData', data: res.data })
                })
        },
        getEmotion({ commit }, payload) {
            let {
                series_id,
                statistics_begin_date,
                statistics_end_date,
                platform
            } = payload

            return getEmotion({
                series_id,
                statistics_begin_date,
                statistics_end_date,
                platform
              })
                .then((res) => {
                    console.log('getEmotion res', res)
                    commit('changeState', { name: 'emotionData', data: res.data })
                })
        },
        getCommentList({ commit }, payload) {
            let {
                current,
                page_size,
                series_id,
                statistics_begin_date,
                statistics_end_date,
                platform
            } = payload
            // 当current传入1时 清空列表数组 重置currentNo为1
            console.log(23432423, payload)
            if (current == 1) {
              commit('changeState', {
                data: [],
                name: 'commentList'
              })
              commit('changeState', {
                data: 1,
                name: 'current'
              })
              commit('changeState', {
                data: false,
                name: 'listNoMore'
              })
            }
            // 加载开启
            commit('changeState', {
              data: true,
              name: 'listLoading'
            })
            // 页码 + 1
            commit('changePageNo', {})

            return getCommentList({
                current,
                page_size,
                series_id,
                statistics_begin_date,
                statistics_end_date,
                platform
              })
                .then((res) => {
                    // 加载关闭
                    commit('changeState', {
                      data: false,
                      name: 'listLoading'
                    })
                    console.log('getCommentList res', res)
                    if (res.code == 0) {
                      // 当list有数据时加入  否则noMore设为true
                      if (res.data.list.length > 0) {
                        commit('changeList', {
                          data: res.data,
                          name: 'commentList',
                          noMore: 'listNoMore'
                        })
                        if (res.data.list.length < page_size) {
                          commit('changeState', {
                            data: true,
                            name: 'listNoMore'
                          })
                        }
                      } else {
                        commit('changeState', {
                          data: true,
                          name: 'listNoMore'
                        })
                      }
                      return;
                    } else {
                      commit('changeState', {
                        data: true,
                        name: 'listNoMore'
                      })
                    }
                    commit('changeState', { name: 'emotionData', data: res.data })
                })
        },
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        },
        changePageNo(state, payload) {
          state.current++
        },
        changeList(state, payload) {
          let {
            data,
            name,
            noMore
          } = payload;
          // console.log(state)
          if (state[name].length == 0) {
            state[name] = data;
          } else {
            state[name].list = state[name].list.concat(data.list);
            if (data.list.length < state.page_size) {
              state[noMore] = true
            }
          }
        },
    }
}
