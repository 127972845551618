<template>
  <a-empty
    :image="image"
    :image-style="{
      height: height,
    }"
  >
    <span slot="description" class="title">{{title}}</span>
  </a-empty>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    image: {
      default: require('@/assets/img/empty.png')
    },
    title: {
      type: String,
      default: '暂无数据'
    },
    height: {
      type: String,
      default: '60px'
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_handle.scss";
.title {
  @include font_color("font_color23");
}
</style>
