<template>
  <div class="input-container">
    <svg-icon :icon-class="'input-search'" class="icon" @click="search" />
    <a-input
      placeholder="请输入..."
      v-model.trim="currentValue"
      class="aft-input opacity"
      size="large"
      style="width: 230px"
      @pressEnter="search"
    ></a-input>
    <div :class="['button', currentValue ? 'active' : '']" @click="search">
      确定
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalInput",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    search() {
      this.$emit("update:value", this.currentValue);
      this.$emit("search", this.currentValue);
    },
    changeValue(val) {
      this.currentValue = val
      this.$emit("update:value", this.currentValue);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.input-container {
  width: 340px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #21242c;
  border-radius: 22px;
  .icon {
    color: #fff;
    font-size: 20px;
    margin-left: 16px;
    cursor: pointer;
  }
  .button {
    width: 59px;
    height: 34px;
    background: #373a41;
    border-radius: 17px;
    color: rgba(255, 255, 255, 0.4);
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 34px;
    margin-right: 5px;
    cursor: pointer;
    &.active {
      background: linear-gradient(270deg, #ffc840 0%, #ffe674 100%);
      color: #000000;
    }
  }
}
</style>