import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
 
    // 选择分组
    getSortGroup(params){
      return request({
          url:'/jjt/group/selectAllGroup',
          method:'get',
          baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
          params,
      })
  },
  // 区域列表
  getAreaList() {
      return request({
          url: `/jjt/dealer/dealers/region-list`,
          method: 'get',
          baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      }).then(res => {
          let list = res.data.data.region_list || []
          if (res.data.data.type == 10) {
              list.unshift({
                  id: '',
                  name: '不限大区'
              })
          }
          list.map(item => {
              item.value = item.id
              item.label = item.name
          })
          return list
      })
  },

  // 门店趋势统计列表
  getTrendList({
    platform,
    params
  }) {
    return request({
      url: `/jjt/dealers/dealer-trend`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params
    }).then(res => {
      res = res.data.data || {}
      return  res
    })
  },

  // 门店趋势统计总数
  getTrendTotal({
    platform,
    params
  }) {
    return request({
      url: `/jjt/dealers/dealer-trend-total`,
      method: 'get',
      baseURL: getBaseUrl(platform),
      params
    }).then(res => {
      return  {
        data: res.data.data || {},
        code: res.data.code
      }
    })
  },
}
