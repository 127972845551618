import api from '@/api/dealerOfOneself'
const {
    getSortGroup,
    getAreaList,
    getBoard,
    getAnalysis,
    getMatrixActivation
} = api;
export default {
    namespaced: true,

    state: {
        groupList: [],
        areaList: [],
        accountData: {},
        boardData: {},
        analysisData: {},
        matrixData: []
    },
    actions: {
        getSortGroup({
            commit
        }) {
            return getSortGroup().then(res => {
                commit('changeState', { name: 'groupList', data: res.data.data })
            })
        },
        getAreaList({ commit }, payload) {
            commit('changeState', { name: 'areaList', data: [] })
            return getAreaList()
                .then((res) => {
                    commit('changeState', { name: 'areaList', data: res })
                })
        },

        // 矩阵活跃
        getMatrixActivation({ commit }, payload) {
            let {
                platform,
                params
            } = payload

            return getMatrixActivation({
                platform,
                params
            })
                .then((res) => {
                    commit('changeState', { name: 'matrixData', data: res })
                })
        },
        // 数据看板
        getBoard({ commit }, payload) {
            let {
                platform,
                params
            } = payload

            return getBoard({
                platform,
                params
            })
                .then((res) => {
                    commit('changeState', { name: 'boardData', data: res })
                })
        },
        // 转化分析
        getAnalysis({ commit }, payload) {
            let {
                platform,
                params
            } = payload

            return getAnalysis({
                platform,
                params
            })
                .then((res) => {
                    commit('changeState', { name: 'analysisData', data: res })
                })
        },
    },
    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}